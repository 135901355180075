import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import LoaderButton from "../loader-button";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
}));

const dateOptions = {
  //timeZone: "UTC",
  month: "numeric",
  day: "numeric",
  year: "numeric",
};
const dateHourOptions = {
  //timeZone: "UTC",
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const ListOfParticipants = ({
  participants,
  eventOwner,
  type,
  maxNumberOfParticipants,
}) => {
  participants = participants
    ? participants.filter(({ personId }) => personId !== eventOwner)
    : [];
  const names = participants.map(({ name }) => name).join(", ");
  if (participants.length === 0 || maxNumberOfParticipants === 1) {
    return null;
  } else if (maxNumberOfParticipants === 2) {
    return <Typography>{`Pacjent: ${names}`}</Typography>;
  } else {
    return <Typography>{`Pacjenci: ${names}`}</Typography>;
  }
};

const CalendarEventCard = ({
  event,
  onBookVisitClick,
  onCancelVisitClick,
  onEditVisitClick,
  onDeleteVisitClick,
  ...props
}) => {
  const cardRef = useRef();
  const [position, setPosition] = useState(
    props.style?.top && props.style?.left
      ? {
          top: props.style.top,
          left: props.style.left,
        }
      : ""
  );
  const globalTheme = useSelector((s) => s.globalTheme);
  const classes = useStyles();

  useEffect(() => {
    const card = cardRef.current;
    const docHeight = window.innerHeight;
    const docWidth = window.innerWidth;
    if (card && props.style?.top && props.style?.left) {
      let top = props.style.top;
      let left = props.style.left;
      if (top >= docHeight - card.offsetHeight) {
        let tempTop = props.style.top - card.offsetHeight;
        if (tempTop <= 0) {
          tempTop = docHeight / 2 - card.offsetHeight / 2;
        }
        top = tempTop;
      }
      if (left >= docWidth - card.offsetWidth) {
        let tempLeft = props.style.left - card.offsetWidth;
        if (tempLeft <= 0) {
          tempLeft = docWidth / 2 - card.offsetWidth / 2;
        }
        left = tempLeft;
      }
      setPosition({ top, left });
    }
  }, []);

  return (
    <Card {...props} ref={cardRef} style={{ ...props.style, ...position }}>
      <CardContent>
        {onDeleteVisitClick && event.end > DateTime.now().toISO() && (
          <IconButton
            onClick={() =>
              onDeleteVisitClick({
                event: event.event,
                startFC: event.startFC,
                endFC: event.endFC,
                info: event.info,
              })
            }
            style={{ float: "right" }}
          >
            <DeleteIcon
              fontSize="medium"
              className={`${globalTheme === "high-contrast" && classes.icon}`}
            />
          </IconButton>
        )}
        <Typography>{event.title}</Typography>
        {event.description && <Typography>{event.description}</Typography>}
        {event.allDay ? (
          <Typography>
            {`${new Date(event.start).toLocaleDateString(
              "pl-PL",
              dateOptions
            )}, Cały dzień`}
          </Typography>
        ) : (
          <Box>
            <Typography>{`Rozpoczęcie: ${new Date(event.startFC).toLocaleString(
              "pl-PL",
              dateHourOptions
            )}`}</Typography>
            <Typography>{`Zakończenie: ${new Date(event.endFC).toLocaleString(
              "pl-PL",
              dateHourOptions
            )}`}</Typography>
          </Box>
        )}
        {event.eventOwner && (
          <Typography>{`Lekarz: ${event.ownerName || ""}`}</Typography>
        )}
        <ListOfParticipants
          participants={event.participants}
          eventOwner={event.eventOwner}
          maxNumberOfParticipants={event.maxNumberOfParticipants}
        />
        {onBookVisitClick && (
          <LoaderButton
            onClick={() => onBookVisitClick(event.idBE)}
            text={"Zarezerwuj wizytę"}
            loadingText="Rezerwuję..."
          />
        )}
        {onCancelVisitClick && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onCancelVisitClick({
                event: event,
                startFC: event.startFC,
                endFC: event.endFC,
              })
            }
          >
            Odwołaj wizytę
          </Button>
        )}
        {onEditVisitClick && event.end > DateTime.now().toISO() && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onEditVisitClick(event.idBE)}
          >
            Edytuj wydarzenie
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CalendarEventCard;
