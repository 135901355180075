import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FullCalendar from "@fullcalendar/react"; //required for fullcalendar plugins
import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import StyledCalendar, { useDatesRange } from "../../components/calendar";
import Loader from "../../components/loader";
import {
  alertAdd,
  cancelCalendarVisit,
  getAvailableEditors,
  getCalendarEvents,
  updateCalendarEvents,
} from "../../redux/actions";
import CalendarEventCard from "../../components/calendar/calendar-event-card";
import { AvailableDoctorsList } from "./book-appointment";
import { highContrastTheme } from "../../theme";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

const useStyles = makeStyles((theme) => ({
  btnColor: {
    backgroundColor:
      highContrastTheme.palette.primary.main === theme.palette.primary.main
        ? "#fff200"
        : "#4caf50",
  },
}));

const CalendarPage = ({ my, history }) => {
  const events = useSelector((s) => s.calendarEvents);
  const [eventClicked, setEventClicked] = useState();
  const [loading, setLoading] = useState(true);
  const [startEnd, setStartEnd] = useState({});
  const [cancelVisitConfirmation, setCancelVisitConfirmation] = useState();
  const datesRange = useDatesRange(startEnd);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const availableDoctors = useSelector((s) => s.availableEditors);
  const isOwner = !!my.user.authorities.includes("ROLE_OWNER");
  const isEditor = my.user.authorities.localeCompare("ROLE_EDITOR") === 0;
  const loadingAvailableEditors = useSelector((s) => s.loadingAvailableEditors);
  const loadingCalendarEvents = useSelector((s) => s.loadingCalendarEvents);
  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  useEffect(() => {
    if (isOwner) {
      dispatch(getAvailableEditors());
    }
  }, []);

  useEffect(() => {
    setLoading(loadingAvailableEditors || loadingCalendarEvents);
  }, [loadingAvailableEditors, loadingCalendarEvents]);

  useEffect(() => {
    getEvents();
  }, [datesRange, selectedDoctor]);

  const getEvents = () => {
    if (datesRange.start || datesRange.end) {
      if (isOwner && selectedDoctor) {
        dispatch(
          getCalendarEvents(datesRange.start, datesRange.end, selectedDoctor.id)
        );
      }

      if (!isOwner) {
        dispatch(getCalendarEvents(datesRange.start, datesRange.end));
      }
    }
  };

  const handleEventClick = (info) => {
    const found = events.find(
      ({ idBE }) => idBE === info.event._def.extendedProps.idBE
    );
    if (found) {
      setEventClicked({
        ...found,
        x: info.jsEvent.x,
        y: info.jsEvent.y,
        startFC: info.event.start,
        endFC: info.event.end,
      });
    }
  };

  const cancelVisit = async (eventId) => {
    await cancelCalendarVisit(eventId)
      .then(() => {
        dispatch(
          alertAdd({
            text: "Anulowano wizytę.",
            isSuccess: true,
          })
        );
        setEventClicked();
        getEvents();
      })
      .catch((data) => {
        dispatch(
          alertAdd({
            text: "Nie udało się anulować wizyty",
            isError: true,
          })
        );
      });
  };

  const checkIfVisitCanBeCancelled = (visit) => {
    if (isEditor) {
      return new Date(visit.start) > new Date() && visit.type === "SELF_VISIT";
    } else {
      return (
        visit.participants &&
        visit.participants.find(({ personId }) => personId === my.id) &&
        new Date(visit.start) > new Date()
      );
    }
  };
  const refreshEvents = () => {
    dispatch(updateCalendarEvents(events.map((e) => e)));
  };

  const page = () => {
    return (
      <Box py={1} px={mediumScreen ? 1 : 4}>
        <Box mb={2}>
          <PageTitle title={isOwner ? "Grafik lekarzy" : "Mój kalendarz"} />
        </Box>
        <Grid container spacing={2}>
          <Can
            permission="calendar:change"
            ok={() => (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/calendar/edit")}
                >
                  Edytuj grafik
                </Button>
              </Grid>
            )}
          />
          <Can
            permission="calendar:book-cancel-appointment"
            ok={() => (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnColor}
                  onClick={() => history.push("/calendar/book-visit")}
                >
                  Zarezerwuj wizytę
                </Button>
              </Grid>
            )}
          />
        </Grid>
        {isOwner && availableDoctors.length > 0 && (
          <AvailableDoctorsList
            doctors={availableDoctors}
            onDoctorSelect={setSelectedDoctor}
            selectedDoctorId={selectedDoctor ? selectedDoctor.id : undefined}
          />
        )}
        {(!isOwner || (isOwner && selectedDoctor)) && (
          <StyledCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventClick={handleEventClick}
            datesSet={(dateInfo) =>
              setStartEnd({ start: dateInfo.start, end: dateInfo.end })
            }
            refreshEvents={refreshEvents}
          />
        )}
        {eventClicked && (
          <ClickAwayListener onClickAway={() => setEventClicked()}>
            <Box>
              <CalendarEventCard
                event={eventClicked}
                onCancelVisitClick={
                  checkIfVisitCanBeCancelled(eventClicked)
                    ? setCancelVisitConfirmation
                    : undefined
                }
                style={{
                  position: "fixed",
                  zIndex: 99,
                  top: eventClicked.y,
                  left: eventClicked.x,
                }}
              />
            </Box>
          </ClickAwayListener>
        )}
        <ConfirmDialogMUI
          handleClose={() => {
            setCancelVisitConfirmation();
          }}
          open={!!cancelVisitConfirmation}
          text={"Czy chcesz odwołać wizytę:"}
          yesAction={() => {
            cancelVisit(cancelVisitConfirmation.event.idBE);
          }}
          noAction={() => {}}
        >
          {cancelVisitConfirmation && (
            <CalendarEventCard
              event={{
                ...cancelVisitConfirmation.event,
                startFC: cancelVisitConfirmation.startFC,
                endFC: cancelVisitConfirmation.endFC,
              }}
            />
          )}
        </ConfirmDialogMUI>
        {loading && (
          <Box
            style={{
              position: "fixed",
              zIndex: 99,
              top: "50%",
              left: "50%",
              transform: " translate(-50%, -50%)",
            }}
          >
            <Loader loading={true} text="Pobieranie wydarzeń z kalendarza..." />
          </Box>
        )}
        {availableDoctors.length === 0 && !loading && isOwner && (
          <Typography
            style={{
              position: "fixed",
              zIndex: 99,
              top: "50%",
              left: "50%",
              transform: " translate(-50%, -50%)",
            }}
          >
            Brak dostępnych lekarzy
          </Typography>
        )}
      </Box>
    );
  };
  const redirect = () => <Redirect to="/" />;

  return <Can permission="calendar:view" ok={page} not={redirect} />;
};

export default connect((state) => ({ my: state.my }), null)(CalendarPage);
