import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { Box, IconButton, useMediaQuery } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";

import TableDisplayFilters from "../../components/table-display-filters";
import UsersTable from "../../components/users-table";
import Filters from "../../components/users-table/filters";
import TableDisplayPopper from "../../components/users-table/table-display-popper";
import {
  fetchAllUsers,
  fetchAllOrganizationNames,
  fetchAllFormsGroups,
  fetchAllForms,
} from "../../redux/admin/actions";

const useStyles = makeStyles((theme) => ({
  filter: {
    width: "350px",
    margin: "4px",
  },
  span: {
    width: "350px",
    display: "inline-block",
    margin: "4px",
  },
  marginRight: {
    marginRight: "16px",
  },
  marginLeft: {
    marginLeft: "16px",
  },
}));

const userDataColumns = [
  { label: "firstName", text: "Imię" },
  { label: "surname", text: "Nazwisko" },
  { label: "mail", text: "Email" },
  { label: "user.phone", text: "Telefon" },
  { label: "user.authorities", text: "Rola" },
  { label: "orgId", text: "Organizacja" },
  { label: "gender", text: "Płeć" },
  { label: "yearOfBirth", text: "Rok urodzenia" },
  { label: "city", text: "Miasto" },
  { label: "street", text: "Ulica" },
  { label: "zipCode", text: "Kod pocztowy" },
  { label: "results", text: "Wyniki" },
  { label: "terms", text: "Zaakceptowany regulamin?" },
];

const initialDataDisplay = userDataColumns.map((col) => {
  return { ...col, selected: true };
});

const setInitialData = () => {
  if (localStorage.getItem("selectedData")) {
    return initialDataDisplay.map((item) => {
      if (localStorage.getItem("selectedData").includes(item.label)) {
        return { ...item, selected: true };
      } else return { ...item, selected: false };
    });
  } else return initialDataDisplay;
};

const UsersTableForAdmin = ({
  fetchAllUsers,
  fetchAllOrganizationNames,
  fetchAllForms,
  fetchAllFormsGroups,
}) => {
  const styles = useStyles();
  const makeMargin = useMediaQuery("(min-width:1390px)");

  const [orgForms, setOrgForms] = useState([]);
  const [orgFormsGroups, setOrgFormsGroups] = useState([]);
  const allUsersFetching = useSelector((s) => s.allUsersFetching);
  const totalCount = useSelector((s) => s.allUsersLength);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [filterModel, setFilterModel] = useState({});
  const [filterFormStatus, setFilterFormStatus] = useState("");
  const [sort, setSort] = useState({
    sortProperties: "createdAt",
    sortDirection: "DESC",
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem("pageSize")) || 10
  );

  const [displayIndividualSchedule, setDisplayIndividualSchedule] = useState(
    false
  );
  const [
    displayOnlyFormsAllowedToUser,
    setDisplayOnlyFormsAllowedToUser,
  ] = useState(false);

  const [userDataColumnOptions, setUserDataColumnOptions] = useState(
    setInitialData()
  );

  const [groupsOptions, setGroupsOptions] = useState([
    { label: "", text: "", selected: false },
  ]);
  const [formsOptions, setFormsOptions] = useState([]);

  const [organizationsOptions, setOrganizationsOptions] = useState([
    { label: "", text: "", selected: false },
  ]);

  const [ready, setReady] = useState(true);
  const [search, setSearch] = useState(false);

  const displayForms = !!formsOptions.find(
    (option) => option.selected === true
  );
  const displayGroups = !!groupsOptions.find(
    (option) => option.selected === true
  );

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizationsTmp = await fetchAllOrganizationNames();
      if (organizationsTmp) {
        setOrganizationsOptions(
          organizationsTmp.map((org) => {
            return { label: org.id, text: org.orgName, selected: false };
          })
        );
      }
    };
    fetchOrganizations();
  }, []);

  useEffect(() => {
    setReady(!allUsersFetching);
  }, [allUsersFetching]);

  useEffect(() => {
    setCurrentOrganization(
      organizationsOptions.find(({ selected }) => selected === true)?.label ||
        null
    );
  }, [organizationsOptions]);

  const fetchUsers = async () => {
    const criteria = JSON.stringify(filterModel);
    setReady(false);
    fetchAllUsers({
      ...(currentOrganization && { orgId: currentOrganization }),
      ...(criteria !== "{}" ? { criteria } : { criteria: "{}" }),
      page,
      pageSize,
      ...sort,
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [currentOrganization, page, pageSize, sort]);

  useEffect(() => {
    setOrgForms([]);
    setOrgFormsGroups([]);
    setPage(0);
    setFilterFormStatus("");
  }, [currentOrganization]);

  const fetchForms = async () => {
    if (currentOrganization) {
      if (filterFormStatus === "") {
        setOrgForms(await fetchAllForms(currentOrganization, filterFormStatus));
      } else if (filterFormStatus !== "none") {
        const statusForms = await fetchAllForms(
          currentOrganization,
          filterFormStatus
        );
        setFormsOptions(
          orgForms.map((form) => {
            if (statusForms.find(({ id }) => id === form.id)) {
              return {
                label: form.id,
                text: `${form.name} (${form.formGroupName})`,
                selected: true,
              };
            } else {
              return {
                label: form.id,
                text: `${form.name} (${form.formGroupName})`,
                selected: false,
              };
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    const fetchFormGroups = async () => {
      if (currentOrganization) {
        setOrgFormsGroups(await fetchAllFormsGroups(currentOrganization));
      }
    };
    fetchFormGroups();
    fetchForms();
  }, [currentOrganization]);

  useEffect(() => {
    fetchForms();
  }, [filterFormStatus]);

  useEffect(() => {
    if (orgFormsGroups) {
      setGroupsOptions(
        orgFormsGroups.map((group) => {
          return { label: group.id, text: group.name, selected: false };
        })
      );
    }
  }, [orgFormsGroups]);

  useEffect(() => {
    if (orgForms) {
      setFormsOptions(
        orgForms.map((form) => {
          return {
            label: form.id,
            text: `${form.name} (${form.formGroupName})`,
            selected: false,
          };
        })
      );
    }
  }, [orgForms]);

  useEffect(() => {
    const selectedItems = userDataColumnOptions
      .filter(({ selected }) => selected === true)
      .map(({ label }) => {
        return label;
      });
    localStorage.setItem("selectedData", selectedItems);
  }, [userDataColumnOptions]);

  useEffect(() => {
    if (search) {
      fetchUsers();
      setSearch(false);
    }
  }, [filterModel, search]);

  const handleChangeSelectedUserDataDisplay = (label, selected) => {
    setUserDataColumnOptions((prevState) =>
      prevState.map((option) => {
        if (option.label === label) {
          return {
            ...option,
            selected: selected,
          };
        } else {
          return { ...option };
        }
      })
    );
  };

  const handleChangeSelectedGroupsDisplay = (label, selected) => {
    setGroupsOptions((prevState) =>
      prevState.map((option) => {
        if (option.label === label) {
          return {
            ...option,
            selected: selected,
          };
        } else {
          return { ...option };
        }
      })
    );
  };

  const handleChangeSelectedFormsDisplay = (label, selected) => {
    setFormsOptions((prevState) =>
      prevState.map((option) => {
        if (option.label === label) {
          return {
            ...option,
            selected: selected,
          };
        } else {
          return { ...option };
        }
      })
    );
  };

  const handleChangeSelectedOrganization = (label, selected) => {
    setOrganizationsOptions((prevState) =>
      prevState.map((option) => {
        if (option.label === label) {
          return {
            ...option,
            selected: selected,
          };
        } else if (option.label === currentOrganization && selected === true) {
          return {
            ...option,
            selected: false,
          };
        } else {
          return { ...option };
        }
      })
    );
  };

  const handleChangeUserFilter = (event, key, operation) => {
    let value = event.target.value;
    value = value.replace(/\s/g, " ");
    setFilterModel({
      ...filterModel,
      [key]: { [operation]: value },
    });
  };

  const handleChangeUserFilterObj = (obj) => {
    let stateCopy = Object.assign({ ...filterModel, ...obj });
    let filterModelTmp = {};
    for (const [key, value] of Object.entries(stateCopy)) {
      if (value !== "") {
        for (const [operator, value2] of Object.entries(value)) {
          if (value2 !== "") {
            if (
              key === "yearOfBirth" &&
              Object.entries(value).filter((v) => v[1] !== "").length > 1
            ) {
              filterModelTmp[key] = {
                between: [value["gte"], value["lte"]],
              };
            } else {
              filterModelTmp[key] = {
                ...filterModelTmp[key],
                [operator]: value2,
              };
            }
          }
        }
      }
    }
    setPage(0);
    setFilterModel({
      ...filterModelTmp,
    });
    setSearch(true);
  };

  return (
    <>
      <Box m={2} display="flex">
        <Filters
          changeFilters={handleChangeUserFilterObj}
          sort={sort}
          changeSort={setSort}
        />
        <Box m="auto" display="inline">
          <TextField
            id="input-user-filter"
            label="Szukaj danych użytkownika"
            variant="outlined"
            size="small"
            className={`${styles.filter} ${styles.marginRight}`}
            inputProps={{ maxLength: 250 }}
            onChange={(event) =>
              handleChangeUserFilter(event, "any", "contains")
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle search users"
                    onClick={() => {
                      setPage(0);
                      fetchUsers();
                    }}
                    edge="end"
                  >
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { zIndex: 0 },
            }}
          />
          <span className={styles.span}>
            <Autocomplete
              size="small"
              fullWidth
              options={organizationsOptions}
              getOptionLabel={(option) => option.text}
              value={
                organizationsOptions.find((opt) => opt.selected === true) ||
                null
              }
              onChange={(event, value, reason) => {
                if (reason === "clear") {
                  organizationsOptions.map((opt) => {
                    if (opt.selected !== false) {
                      handleChangeSelectedOrganization(opt.label, false);
                    }
                  });
                } else {
                  handleChangeSelectedOrganization(value.label, true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filtruj organizację"
                  variant="outlined"
                  placeholder={"Wybrana organizacja"}
                  InputLabelProps={{
                    style: { zIndex: 0 },
                  }}
                />
              )}
            />
          </span>

          <span className={`${styles.span} ${makeMargin && styles.marginLeft}`}>
            {currentOrganization && (
              <TableDisplayFilters
                options={groupsOptions}
                changeSelected={handleChangeSelectedGroupsDisplay}
                title={"Pokazuj projekty"}
                placeholder={"Wybrane projekty"}
              />
            )}
          </span>
          <span className={styles.span}>
            {currentOrganization && (
              <TableDisplayFilters
                options={formsOptions}
                changeSelected={handleChangeSelectedFormsDisplay}
                title={"Pokazuj formularze"}
                placeholder={"Wybrane formularze"}
                formStatus={filterFormStatus}
                changeFormStatusSelected={setFilterFormStatus}
                displayIndividualSchedule={displayIndividualSchedule}
                changeDisplayIndividualSchedule={setDisplayIndividualSchedule}
                displayOnlyFormsAllowedToUser={displayOnlyFormsAllowedToUser}
                changeDisplayOnlyFormsAllowedToUser={
                  setDisplayOnlyFormsAllowedToUser
                }
              />
            )}
          </span>
        </Box>
        <TableDisplayPopper
          changeSelected={handleChangeSelectedUserDataDisplay}
          options={userDataColumnOptions}
        />
      </Box>
      <UsersTable
        forAdmin={true}
        orgForms={orgForms}
        orgFormsGroups={orgFormsGroups}
        displayForms={displayForms}
        displayGroups={displayGroups}
        userDataColumnOptions={userDataColumnOptions}
        formsOptions={formsOptions}
        groupsOptions={groupsOptions}
        ready={ready}
        setReady={setReady}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        displayOnlyFormsAllowedToUser={displayOnlyFormsAllowedToUser}
        displayIndividualSchedule={displayIndividualSchedule}
        totalCount={totalCount}
        organizationsOptions={organizationsOptions}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllUsers: (body) => dispatch(fetchAllUsers(body)),
  fetchAllOrganizationNames: () => dispatch(fetchAllOrganizationNames()),
  fetchAllFormsGroups: (orgId) => dispatch(fetchAllFormsGroups(orgId)),
  fetchAllForms: (orgId, formStatus) =>
    dispatch(fetchAllForms(orgId, formStatus)),
});

export default connect(null, mapDispatchToProps)(UsersTableForAdmin);
