import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

// dragaMode = either 'clone' or 'move'

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "solid lightgrey",
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

const NumberControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledValuesDetails,
  alertDisplaying,
  textColorFilling,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.NUMBER,
      dragMode,
      defaultLabel: "Liczba",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const classes = useStyles();
  const [value, setValue] = useState(filledValue || "");
  const globalTheme = useSelector((s) => s.globalTheme);

  const [filledValueDetails, setFilledValueDetails] = useState(
    (filledValuesDetails && filledValuesDetails.find((v) => v.id === id)) ||
      null
  );
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");

  const setValueWithNumberTypeContext = (stringValue) => {
    const isInteger = configuration.numberType === "integer";

    setValue(stringValue);

    if (onChange) {
      try {
        if (isInteger) {
          onChange({ id, value: parseInt(stringValue, 10) });
        } else {
          onChange({
            id,
            value: stringValue.endsWith(",")
              ? stringValue
              : stringValue.replace(",", "."),
          });
        }
      } catch {
        // console.log("catch...");
      }
    }
  };

  useEffect(() => {
    setValueWithNumberTypeContext(filledValue);
  }, [filledValue]);

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        dataType: ItemSubtypes.NUMBER,
        configuration,
      });
  };

  const updateValue = (tmpValue) => {
    const isInteger = configuration.numberType === "integer";
    const digits = configuration.decimalDigitNumber;

    const correctPattern = isInteger
      ? new RegExp("(^\\d+$)|^$")
      : new RegExp(
          `(^\\d+$)|(^\\d+[,\\.]$)|(^\\d+[,\\.]\\d{0,${digits}}$)|(^[,\\.]\\d{0,${digits}}$)|^$|^[,\\.]$`
        );

    const correct = correctPattern.test(tmpValue);
    if (tmpValue === "," || tmpValue === ".") {
      tmpValue = "0" + tmpValue;
    }

    if (correct) {
      setValueWithNumberTypeContext(tmpValue);
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Liczba"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Liczba"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}{" "}
      {disabled && !isJustUser && !value ? (
        <div
          className={`${s.hintText} ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          Pole niewypełnione przez{" "}
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjenta"
            : "użytkownika"}
        </div>
      ) : (
        <>
          <CssTextField
            fullWidth
            variant="outlined"
            size="small"
            disabled={disabled}
            error={
              !!(
                checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id)
              ) ||
              !!(
                value &&
                ((configuration.maxValue &&
                  configuration.maxValue < value?.replace(/,/g, ".")) ||
                  (configuration.minValue &&
                    configuration.minValue > value?.replace(/,/g, ".")) ||
                  value.endsWith(","))
              )
            }
            placeholder={placeholder}
            id={id}
            onClick={clicked}
            onChange={({ target: { value } }) => {
              updateValue(value.toString().replace(/\./g, ","));
            }}
            value={value || ""}
          />
          {/* <input
            // maxLength={configuration.size}
            disabled={disabled}
            type="text"
            className={
              (checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id)) ||
              (value &&
                ((configuration.maxValue &&
                  configuration.maxValue < value?.replace(/,/g, ".")) ||
                  (configuration.minValue &&
                    configuration.minValue > value?.replace(/,/g, "."))))
                ? "input is-danger"
                : "input"
            }
            name={name}
            placeholder={placeholder}
            id={id}
            value={value || ""}
            onClick={clicked}
            onChange={({ target: { value } }) => {
              updateValue(value.toString().replace(/\./g, ","));
            }}
            style={{
              color:
                disabled && filledValueDetails?.color && textColorFilling
                  ? filledValueDetails.color
                  : globalTheme === "high-contrast"
                  ? "yellow"
                  : "black",
              border: globalTheme === "high-contrast" && "1px solid yellow",
              backgroundColor: globalTheme === "high-contrast" && "black",
            }}
          /> */}
          {value &&
          ((configuration.maxValue &&
            configuration.maxValue < value?.replace(/,/g, ".")) ||
            (configuration.minValue &&
              configuration.minValue > value?.replace(/,/g, ".")) ||
            value.endsWith(",")) ? (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color: globalTheme === "high-contrast" && "#df9700",
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              Nieprawidłowa wartość
            </p>
          ) : (
            ""
          )}
          {checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id) && (
              <p
                class={globalTheme !== "high-contrast" && "help is-danger"}
                style={{
                  color: globalTheme === "high-contrast" && "#df9700",
                }}
                className={`${classes.fontSize} ${
                  globalTheme !== "high-contrast" && "help is-danger"
                }`}
              >
                To pole musi zostać wypełnione
              </p>
            )}
        </>
      )}
      {disabled && filledValueDetails && alertDisplaying && (
        <div>
          {" "}
          <label
            className={s.text}
            style={{ color: filledValueDetails.color || "" }}
          >
            {filledValueDetails.text ? filledValueDetails.text : ""}
          </label>
        </div>
      )}
      {filledValueDetails &&
        alertDisplaying &&
        filledValueDetails.weight &&
        !isJustUser && (
          <div>
            <p>
              Liczba uzyskanych punktów za odpowiedź:
              <span style={{ color: filledValueDetails.color || "" }}>
                {" "}
                {filledValueDetails.weight}
              </span>{" "}
              pkt
            </p>
          </div>
        )}
      {!(disabled && !isJustUser && !value) && (
        <>
          <label
            style={{
              color: globalTheme === "high-contrast" && "yellow",
            }}
            className={`${classes.fontSizeHintText} ${s.hintText}`}
          >
            {(configuration.minValue || configuration.minValue === 0) &&
              `Minimalna wartość: ${configuration.minValue
                .toString()
                .replace(/\./g, ",")}`}
          </label>
          &nbsp;&nbsp;&nbsp;
          <label
            style={{
              color: globalTheme === "high-contrast" && "yellow",
            }}
            className={`${classes.fontSizeHintText} ${s.hintText}`}
          >
            {(configuration.maxValue || configuration.maxValue === 0) &&
              `Maksymalna wartość: ${configuration.maxValue
                .toString()
                .replace(/\./g, ",")} `}
          </label>
        </>
      )}
    </div>
  );
};

export default NumberControl;
