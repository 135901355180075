export const permissions = {
  ROLE_USER_NO_ACCEPTED_TERMS: {
    static: ["main:view"],
    dynamic: {},
  },
  ROLE_ADMIN: {
    static: [
      "adminMain:view",
      "main:view",
      // 'activated-kits:view',
      "adminSettings:view",
      "profilePage:view",
      "passwordChangePage:view",
      "orgDetails:change",
      "users:view",
      "terms-page:view",
      "orgDetails:view",
      "globalOrgDetails:view",
      "globalOrgDetails:change",
      "admin:setAdminRole",
      "apikeys",
      "calendar:change",
      "calendar:view",
      "calendar:book-cancel-appointment",
    ],
    dynamic: {},
  },
  ROLE_SUPER_OPERATOR: {
    static: [
      "filled-forms-page:view",
      "results-page:view",
      "user-filled-all-forms-page:view",
      "form-fill-details:page",
    ],
    dynamic: {},
  },
  // ROLE_SUPEROWNER: {
  //   static: [
  //     "orgDetails:view",
  //     "globalOrgDetails:view",
  //     "globalOrgDetails:change",
  //   ],
  //   dynamic: [],
  // },
  ROLE_OWNER: {
    static: [
      "form-fill:page",
      "form-add-page:view",
      "admin:fetchOrgForms",
      "admin:fetchOrgUsers",
      "admin:editPerson",
      "main:view",
      "forms-page:view",
      "filled-forms-page:view",
      "groups-page:view",
      // "activated-kits-page:view",
      "admin:fetchOrgReports",
      "profilePage:view",
      "passwordChangePage:view",
      "add-persons-page:view",
      "orgDetails:change",
      "users:view",
      "users-filled-forms:view",
      "user-filled-all-forms-page:view",
      "new-test-kit:page",
      "orgDetails:view",
      "test-kit:page",
      "edit-test-kit:page",
      "admin:addGroup",
      "admin:fetchGroups",
      "form-group-add-page:view",
      "form-editor-page:view",
      "admin:setAdminRole",
      "form-fill-details:page",
      "activeforms-page:view",
      "form:deactivate",
      "form:duplicate",
      "form:edit",
      "form:remove",
      "form:add-to-project",
      "project:duplicate",
      "project:edit",
      "project:remove",
      "password-reset-email-link-expired:view",
      "results-page:view",
      "apikeys",
      "webhooks",
      "terms-page:view",
      "calendar:change",
      "calendar:view",
      "calendar:book-cancel-appointment",
    ],
    dynamic: {},
  },
  ROLE_USER: {
    static: [
      "main:view",
      "profilePage:view",
      "passwordChangePage:view",
      "form-fill:page",
      "form-fill-details:page",
      "activeforms-page:view",
      "filled-kits:page",
      "test-kit:page",
      "edit-test-kit:page",
      "shopping-cart:page",
      "orders:page",
      "order-in-progress:page",
      "activated-kits-page:view",
      "sat-form:page",
      "password-reset-email-link-expired:view",
      "calendar:view",
      "calendar:book-cancel-appointment",
    ],
    dynamic: {},
  },
  ROLE_EDITOR: {
    static: [
      "form-fill:page",
      "profilePage:view",
      "main:view",
      "forms-page:view",
      "activated-kits-page:view",
      "add-persons-page:view",
      "form-add-page:view",
      "form-editor-page:view",
      "form:activate",
      // "form:duplicate",
      // "form:deactivate",
      "form:create",
      "users-filled-forms:view",
      "user-filled-all-forms-page:view",
      "forms:fetch",
      "groups-page:view",
      "groups:fetch",
      "form-fill-details:page",
      "activeforms-page:view",
      "password-reset-email-link-expired:view",
      "editor:editPerson",
      "filled-forms-page:view",
      "results-page:view",
      "apikeys",
      "calendar:change",
      "calendar:view",
      "calendar:book-cancel-appointment",
    ],
    dynamic: {
      "form:deactivate": (store) => store?.organization?.editorsCanManageForms,
      "form:duplicate": (store) => store?.organization?.editorsCanManageForms,
      "form:edit": (store) => store?.organization?.editorsCanManageForms,
      "form:remove": (store) => store?.organization?.editorsCanManageForms,
      "form-add-page:view": (store) =>
        store?.organization?.editorsCanManageForms,
      "admin:fetchOrgUsers": (store) =>
        store?.organization?.editorsCanManageUsers,
    },
  },
  GUEST: {
    static: [
      "login:view",
      "help:view",
      "about:view",
      "password-reset-email:view",
      "password-reset-email-link-expired:view",
    ],
    dynamic: {},
  },
};

export const checkPermission = (permission, roles, store) => {
  let allPermissions = [];
  if (!roles) {
    allPermissions = [...permissions.guest.static];
  } else {
    roles.forEach((role) => {
      if (permissions[role]) {
        allPermissions = [...allPermissions, ...permissions[role].static];

        if (store && permissions[role].dynamic[permission]) {
          if (permissions[role].dynamic[permission](store)) {
            allPermissions.push(permission);
          }
        }
      }
    });
  }

  const result = allPermissions.indexOf(permission) !== -1;

  if (!result) {
    console.debug("no permission for", permission);
  }

  return result;
};
