import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TextField } from "@material-ui/core";

// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    "&$disabled": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
      border: `1px solid ${
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main
      }`,
    },
  },
  disabled: {},
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:
          theme.palette.primary.main === "#fff200" &&
          theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor:
          theme.palette.primary.main === "#fff200" &&
          theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor:
          theme.palette.primary.main === "#fff200" &&
          theme.palette.primary.main,
      },
    },
  },
}))(TextField);

const ContrastCheckbox = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
}))(Checkbox);

const ContrastRadio = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
  checked: {},
}))(Radio);

const DictionaryControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledValuesDetails,
  actualValues,
  alertDisplaying,
  textColorFilling,
  filledFormMode,
  blankedRequiredFields,
  blankedRequiredArrayValueFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.DICTIONARY,
      dragMode,
      defaultLabel: "Słownik",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const [filledValueDetails, setFilledValueDetails] = useState(
    filledValuesDetails?.find((v) => v.id === id) || null
  );
  const [alternativeAnswerEnable, setAlternativeAnswerEnable] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(true);

  const [arrayValue, setArrayValue] = useState([]);
  const [alternativeValue, setAlternativeValue] = useState(filledValue);
  const [alternativeValueUpdated, setAlternativeValueUpdated] = useState(false);

  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");

  const updateValue = ({ id, value, isText }) => {
    setAlternativeValue(value);
    if (onChange) {
      onChange({
        id,
        value,
        isText,
      });
    }
  };

  const isAlternativeAnswer = () => {
    if (filledValuesDetails.find((v) => v.id === id).alternativeAnswer) {
      return true;
    } else return false;
  };

  const renderValue = () => {
    const filledValueDetails = filledValuesDetails?.find((v) => v.id === id);
    return alternativeValueUpdated
      ? alternativeValue || ""
      : filledValueDetails &&
        filledValueDetails.alternativeAnswer === filledValue
      ? filledValue
      : "";
  };

  const isTextInputDisabled = () => {
    return defaultChecked &&
      filledValuesDetails &&
      filledValuesDetails.find((v) => v.id === id) &&
      isAlternativeAnswer() &&
      filledValuesDetails.find((v) => v.id === id).value
      ? false
      : !alternativeAnswerEnable;
  };

  useEffect(() => {
    const filledDetails = filledValuesDetails?.find((v) => v.id === id);
    if (filledDetails) {
      setArrayValue(filledDetails.arrayValue);
      if (filledDetails.alternativeAnswer) {
        setAlternativeAnswerEnable(filledDetails?.value ? false : true);
        setAlternativeValue(
          filledDetails?.value ? "" : filledDetails.alternativeAnswer
        );
      }
    }
  }, [filledValuesDetails]);

  const renderFilledMultiplayAnswer = () => {
    return dictionaryValues.map(({ stringValue, intValue }, index) =>
      !isJustUser ? (
        filledValue &&
        (filledValue.indexOf(intValue) !== -1 ||
          filledValue.indexOf(stringValue) !== -1) && (
          <div className="control" key={index}>
            <label>
              <input
                key={index}
                type="checkbox"
                name={id}
                onChange={() => {
                  onChange({
                    id,
                    value: stringValue || intValue,
                  });
                }}
                checked={
                  filledValue &&
                  (filledValue.indexOf(intValue) !== -1 ||
                    filledValue.indexOf(stringValue) !== -1)
                }
              />
              {
                <span
                  style={{
                    color:
                      filledValueDetails &&
                      textColorFilling &&
                      ((filledValue && filledValue.indexOf(intValue) !== -1) ||
                        (filledValue &&
                          filledValue.indexOf(stringValue) !== -1))
                        ? filledValueDetails.color
                        : "",
                  }}
                >
                  {stringValue || intValue}
                </span>
              }
            </label>
          </div>
        )
      ) : (
        // <>
        <FormControlLabel
          key={index}
          control={
            <ContrastCheckbox
              color="primary"
              checked={
                filledValue &&
                (filledValue.indexOf(intValue) !== -1 ||
                  filledValue.indexOf(stringValue) !== -1)
              }
              disabled={true}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <span
              style={{
                color:
                  globalTheme === "high-contrast"
                    ? "yellow"
                    : filledValueDetails &&
                      textColorFilling &&
                      ((filledValue && filledValue.indexOf(intValue) !== -1) ||
                        (filledValue &&
                          filledValue.indexOf(stringValue) !== -1))
                    ? filledValueDetails.color
                    : "",
              }}
            >
              {stringValue || intValue}
            </span>
          }
        />
        /* <div className="control" key={index}>
            <label>
              <input
                key={index}
                type="checkbox"
                name={id}
                onChange={() => {
                  onChange({
                    id,
                    value: stringValue || intValue,
                  });
                }}
                checked={
                  filledValue &&
                  (filledValue.indexOf(intValue) !== -1 ||
                    filledValue.indexOf(stringValue) !== -1)
                }
              />
              {
                <span
                  style={{
                    color:
                      filledValueDetails &&
                      textColorFilling &&
                      ((filledValue && filledValue.indexOf(intValue) !== -1) ||
                        (filledValue &&
                          filledValue.indexOf(stringValue) !== -1))
                        ? filledValueDetails.color
                        : "",
                  }}
                >
                  {stringValue || intValue}
                </span>
              }
            </label>
          </div>
        </> */
      )
    );
  };

  const renderAlternativeAnswer = () => {
    const details = filledValuesDetails.find((v) => v.id === id);
    const alternativeAnswer = details.alternativeAnswer;
    const value = details.value;
    return !isJustUser ? (
      alternativeAnswer && !value && (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="dict-answ"
            name="controlled-radio-buttons-group"
          >
            <span>
              <FormControlLabel
                control={
                  <>
                    <ContrastRadio
                      name={id}
                      disabled={true}
                      checked={
                        (!dictionaryValues.find(
                          ({ stringValue, intValue }) =>
                            (intValue || stringValue) === filledValue
                        ) ||
                          (isAlternativeAnswer() &&
                            dictionaryValues.find(
                              ({ stringValue, intValue }) =>
                                (intValue || stringValue) === filledValue
                            ))) &&
                        (filledValuesDetails.find((v) => v.id === id).value ===
                          null ||
                          filledValuesDetails.find((v) => v.id === id).value ===
                            "") &&
                        filledValuesDetails.find((v) => v.id === id)
                          .alternativeAnswer
                      }
                    />
                  </>
                }
                label={<span>{alternativeAnswerLabel || ""}</span>}
              />
              {alternativeAnswerEnable && (
                <CssTextField
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      disabled: classes.disabled,
                    },
                  }}
                  disabled={true}
                  value={
                    (!dictionaryValues.find(
                      ({ stringValue, intValue }) =>
                        (intValue || stringValue) === filledValue
                    ) ||
                      (isAlternativeAnswer() &&
                        dictionaryValues.find(
                          ({ stringValue, intValue }) =>
                            (intValue || stringValue) === filledValue
                        ))) &&
                    filledValuesDetails.find((v) => v.id === id).value === null
                      ? filledValue
                      : ""
                  }
                  variant="outlined"
                  size="small"
                />
              )}{" "}
            </span>
          </RadioGroup>
        </FormControl>
        // <div className="form-group">
        //   <div className="radio">
        //     <label>
        //       <input
        //         disabled={true}
        //         type="radio"
        //         name={id}
        //         checked={
        //           (!dictionaryValues.find(
        //             ({ stringValue, intValue }) =>
        //               (intValue || stringValue) === filledValue
        //           ) ||
        //             (isAlternativeAnswer() &&
        //               dictionaryValues.find(
        //                 ({ stringValue, intValue }) =>
        //                   (intValue || stringValue) === filledValue
        //               ))) &&
        //           (filledValuesDetails.find((v) => v.id === id).value ===
        //             null ||
        //             filledValuesDetails.find((v) => v.id === id).value ===
        //               "") &&
        //           filledValuesDetails.find((v) => v.id === id).alternativeAnswer
        //         }
        //       />{" "}
        //       {`${alternativeAnswerLabel || ""} `}
        //     </label>
        //     <input
        //       type="text"
        //       class="form-inline"
        //       disabled={true}
        //       value={
        //         (!dictionaryValues.find(
        //           ({ stringValue, intValue }) =>
        //             (intValue || stringValue) === filledValue
        //         ) ||
        //           (isAlternativeAnswer() &&
        //             dictionaryValues.find(
        //               ({ stringValue, intValue }) =>
        //                 (intValue || stringValue) === filledValue
        //             ))) &&
        //         filledValuesDetails.find((v) => v.id === id).value === null
        //           ? filledValue
        //           : ""
        //       }
        //     />
        //   </div>
        // </div>
      )
    ) : (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="dict-answ"
          name="controlled-radio-buttons-group"
        >
          <span>
            <FormControlLabel
              control={
                <>
                  <ContrastRadio
                    name={id}
                    disabled={true}
                    checked={
                      (!dictionaryValues.find(
                        ({ stringValue, intValue }) =>
                          (intValue || stringValue) === filledValue
                      ) ||
                        (isAlternativeAnswer() &&
                          dictionaryValues.find(
                            ({ stringValue, intValue }) =>
                              (intValue || stringValue) === filledValue
                          ))) &&
                      (filledValuesDetails.find((v) => v.id === id).value ===
                        null ||
                        filledValuesDetails.find((v) => v.id === id).value ===
                          "") &&
                      filledValuesDetails.find((v) => v.id === id)
                        .alternativeAnswer
                    }
                  />
                </>
              }
              label={
                <span
                  style={{
                    color: globalTheme === "high-contrast" && "yellow",
                  }}
                >
                  {alternativeAnswerLabel || ""}
                </span>
              }
            />{" "}
            <CssTextField
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              disabled={true}
              value={
                (!dictionaryValues.find(
                  ({ stringValue, intValue }) =>
                    (intValue || stringValue) === filledValue
                ) ||
                  (isAlternativeAnswer() &&
                    dictionaryValues.find(
                      ({ stringValue, intValue }) =>
                        (intValue || stringValue) === filledValue
                    ))) &&
                filledValuesDetails.find((v) => v.id === id).value === null
                  ? filledValue
                  : ""
              }
              variant="outlined"
              size="small"
            />
          </span>
        </RadioGroup>
      </FormControl>
    );
  };

  const renderDictionaryAnswers = () => {
    return dictionaryValues.map(({ stringValue, intValue }, index) =>
      !isJustUser ? (
        ((intValue || stringValue) == filledValue ||
          (filledValuesDetails.find((v) => v.id === id).value !== null &&
            filledValuesDetails.find((v) => v.id === id).value ===
              (intValue || stringValue))) && (
          <FormControlLabel
            key={index}
            value={stringValue || intValue}
            control={
              <ContrastRadio
                value={stringValue || intValue}
                disabled={true}
                name={id}
                checked={
                  (intValue || stringValue) == filledValue ||
                  (filledValuesDetails.find((v) => v.id === id).value !==
                    null &&
                    filledValuesDetails.find((v) => v.id === id).value ===
                      (intValue || stringValue))
                }
              />
            }
            label={
              <span
                style={{
                  color: globalTheme === "high-contrast" && "yellow",
                }}
              >
                {stringValue || intValue}
              </span>
            }
          />
          // <div className="form-group" key={index}>
          //   <div className="radio">
          //     <label>
          //       <input
          //         disabled={true}
          //         type="radio"
          //         name={id}
          //         checked={
          //           (intValue || stringValue) == filledValue ||
          //           (filledValuesDetails.find((v) => v.id === id).value !==
          //             null &&
          //             filledValuesDetails.find((v) => v.id === id).value ===
          //               (intValue || stringValue))
          //         }
          //       />{" "}
          //       {stringValue || intValue}
          //     </label>
          //   </div>
          // </div>
        )
      ) : (
        <FormControlLabel
          key={index}
          value={stringValue || intValue}
          control={
            <ContrastRadio
              value={stringValue || intValue}
              disabled={true}
              name={id}
              checked={
                (intValue || stringValue) == filledValue ||
                (filledValuesDetails.find((v) => v.id === id).value !== null &&
                  filledValuesDetails.find((v) => v.id === id).value ===
                    (intValue || stringValue))
              }
            />
          }
          label={
            <span
              style={{
                color: globalTheme === "high-contrast" && "yellow",
              }}
            >
              {stringValue || intValue}
            </span>
          }
        />
        /* <div className="form-group" key={index}>
            <div className="radio">
              <label>
                <input
                  disabled={true}
                  type="radio"
                  name={id}
                  checked={
                    (intValue || stringValue) == filledValue ||
                    (filledValuesDetails.find((v) => v.id === id).value !==
                      null &&
                      filledValuesDetails.find((v) => v.id === id).value ===
                        (intValue || stringValue))
                  }
                />{" "}
                {stringValue || intValue}
              </label>
            </div>
          </div> */
      )
    );
  };

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.DICTIONARY,
      configuration,
    });

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Słownik"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Słownik"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  const dictionaryValues =
    (configuration.dictionary && configuration.dictionary.dictionaryValues) ||
    [];

  const allowMultipleAnswers = configuration.allowMultipleAnswers || false;
  const allowAlternativeAnswer = configuration.allowAlternativeAnswer || false;
  const alternativeAnswerLabel = configuration.alternativeAnswerLabel || "";

  if (filledFormMode) {
    return (
      <div>
        {label && (
          <label
            className={`label ${classes.fontSize}`}
            style={{
              marginBottom: "0px",
              color: globalTheme === "high-contrast" && "#fff200",
            }}
          >
            {label}
          </label>
        )}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {allowMultipleAnswers ? (
          !isJustUser ? (
            !filledValue || filledValue.length === 0 ? (
              <div
                className={`${s.hintText} ${classes.fontSize}`}
                style={{ color: globalTheme === "high-contrast" && "yellow" }}
              >
                Pole niewypełnione przez{" "}
                {process.env.REACT_APP_USE_PATIENT === "true"
                  ? "pacjenta"
                  : "użytkownika"}
              </div>
            ) : (
              renderFilledMultiplayAnswer()
            )
          ) : (
            <FormGroup>{renderFilledMultiplayAnswer()} </FormGroup>
          )
        ) : (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="dict-answ"
              name="controlled-radio-buttons-group"
            >
              {" "}
              {!isJustUser ? (
                (!filledValue ||
                  filledValuesDetails.find((v) => v.id === id).value ===
                    null) &&
                !filledValuesDetails.find((v) => v.id === id)
                  .alternativeAnswer ? (
                  <div
                    className={`${s.hintText} ${classes.fontSize}`}
                    style={{
                      color: globalTheme === "high-contrast" && "yellow",
                    }}
                  >
                    Pole niewypełnione przez{" "}
                    {process.env.REACT_APP_USE_PATIENT === "true"
                      ? "pacjenta"
                      : "użytkownika"}
                  </div>
                ) : (
                  renderDictionaryAnswers()
                )
              ) : (
                renderDictionaryAnswers()
              )}
              {!isJustUser ? (
                (filledValuesDetails.find((v) => v.id === id)
                  .alternativeAnswer ||
                  filledValue ||
                  filledValuesDetails.find((v) => v.id === id).value !==
                    null) &&
                allowAlternativeAnswer ? (
                  renderAlternativeAnswer()
                ) : (
                  <></>
                )
              ) : (
                allowAlternativeAnswer && renderAlternativeAnswer()
              )}
            </RadioGroup>
          </FormControl>
        )}

        {filledValueDetails && alertDisplaying && filledValueDetails.text && (
          <div>
            {" "}
            <label
              className={s.text}
              style={{ color: filledValueDetails.color || "" }}
            >
              {`Treść komunikatu: ${filledValueDetails.text || ""}`}
            </label>
            <br />
          </div>
        )}
        {filledValueDetails &&
          alertDisplaying &&
          filledValueDetails.weight &&
          !isJustUser && (
            <div>
              <p>
                Liczba uzyskanych punktów za odpowiedź:
                <span style={{ color: filledValueDetails.color || "" }}>
                  {" "}
                  {filledValueDetails.weight}
                </span>{" "}
                pkt
              </p>
            </div>
          )}
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{
            marginBottom: "0px",
            color: globalTheme === "high-contrast" && "#fff200",
          }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={`label ${classes.fontSize}`}
        ></label>
      )}
      <label
        style={{ color: globalTheme === "high-contrast" && "yellow" }}
        className={s.hintText}
      >
        {configuration.text}
      </label>
      {allowMultipleAnswers &&
        checkBlankedFields &&
        blankedRequiredArrayValueFields.find((fieldId) => fieldId === id) && (
          <p
            class={globalTheme !== "high-contrast" && "help is-danger"}
            style={{
              color: globalTheme === "high-contrast" && "#df9700",
            }}
            className={`${classes.fontSize} ${
              globalTheme !== "high-contrast" && "help is-danger"
            }`}
          >
            To pole musi zostać wypełnione
          </p>
        )}
      <div
        style={{
          border:
            globalTheme === "high-contrast"
              ? "1px solid #fff200"
              : "1px solid lightgray",
          borderRadius: "4px",
          padding: "2px",
          backgroundColor: globalTheme === "high-contrast" ? "black" : "white",
        }}
      >
        {allowMultipleAnswers ? (
          <FormGroup>
            {dictionaryValues.map(({ stringValue, intValue }, index) => (
              <FormControlLabel
                key={index}
                control={
                  <ContrastCheckbox
                    color="primary"
                    checked={
                      arrayValue?.find(
                        (filledValue) =>
                          filledValue === (intValue || stringValue)
                      ) || false
                    }
                    onChange={(e) => {
                      setDefaultChecked(false);
                      if (e.target.checked) {
                        setArrayValue([...arrayValue, stringValue || intValue]);
                      } else if (!e.target.checked) {
                        setArrayValue(
                          arrayValue.filter(
                            (value) => value !== stringValue || intValue
                          )
                        );
                      }
                      onChange({
                        id,
                        value: stringValue || intValue,
                        selected: e.target.checked,
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={stringValue || intValue}
              />
            ))}
          </FormGroup>
        ) : (
          <form>
            <div id="dictionary">
              {" "}
              {checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id) && (
                  <p
                    class={globalTheme !== "high-contrast" && "help is-danger"}
                    style={{
                      color: globalTheme === "high-contrast" && "#df9700",
                    }}
                    className={`${classes.fontSize} ${
                      globalTheme !== "high-contrast" && "help is-danger"
                    }`}
                  >
                    To pole musi zostać wypełnione
                  </p>
                )}
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="dict"
                  name="controlled-radio-buttons-group"
                  value={actualValues[id] || ""}
                >
                  {dictionaryValues.map(({ stringValue, intValue }, index) => (
                    <FormControlLabel
                      key={index}
                      value={stringValue || intValue}
                      control={
                        <ContrastRadio
                          checked={
                            defaultChecked
                              ? (intValue || stringValue) ==
                                filledValuesDetails?.find((v) => v.id === id)
                                  ?.value
                              : undefined
                          }
                          onClick={() => {
                            setAlternativeValue("");
                            setAlternativeAnswerEnable(false);
                            setDefaultChecked(false);
                            onChange({
                              id,
                              value: stringValue || intValue,
                              isText: false,
                            });
                          }}
                        />
                      }
                      label={stringValue || intValue}
                    />
                  ))}
                  {allowAlternativeAnswer && (
                    <span>
                      <FormControlLabel
                        // value={alternativeAnswerLabel || ""}
                        control={
                          <>
                            <ContrastRadio
                              checked={alternativeAnswerEnable}
                              // checked={
                              //   defaultChecked
                              //     ? alternativeAnswerEnable
                              //     : // &&
                              //       // !filledValuesDetails.find((v) => v.id === id).value
                              //       undefined
                              // }
                              onClick={({ target: { checked: enable } }) => {
                                if (!alternativeAnswerEnable) {
                                  setDefaultChecked(false);
                                  setAlternativeAnswerEnable(enable);
                                  onChange({
                                    id,
                                    value: null,
                                    selected: enable,
                                  });
                                } else {
                                  setAlternativeValue(null);
                                  setAlternativeAnswerEnable(false);
                                  updateValue({
                                    id,
                                    value: "",
                                    isText: true,
                                  });
                                }
                              }}
                            />
                          </>
                        }
                        label={alternativeAnswerLabel || ""}
                      />
                      <CssTextField
                        InputProps={{
                          classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                          },
                        }}
                        // placeholder={alternativeAnswerLabel || ""}
                        disabled={!alternativeAnswerEnable}
                        variant="outlined"
                        size="small"
                        value={alternativeAnswerEnable ? alternativeValue : ""}
                        onChange={({ target: { value } }) => {
                          setAlternativeValueUpdated(true);
                          updateValue({ id, value, isText: true });
                        }}
                      />
                    </span>
                  )}
                </RadioGroup>
              </FormControl>
              {/* {allowAlternativeAnswer && (
                <div className="form-group">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        name={id}
                        checked={
                          defaultChecked
                            ? alternativeAnswerEnable
                            : // &&
                              // !filledValuesDetails.find((v) => v.id === id).value
                              undefined
                        }
                        onChange={({ target: { checked: enable } }) => {
                          setDefaultChecked(false);
                          setAlternativeAnswerEnable(enable);
                          onChange({
                            id,
                            // value: null,
                            selected: enable,
                          });
                        }}
                      />{" "}
                      {`${alternativeAnswerLabel || ""} `}
                    </label>
                    <input
                      type="text"
                      className={classnames("form-inline", s.alternativeInput)}
                      disabled={!alternativeAnswerEnable}
                      // ref={(input) => {
                      //   // this.textInput = input;
                      // }}
                      // value={alternativeAnswerEnable ? renderValue() : ""}
                      value={alternativeValue}
                      onChange={({ target: { value } }) => {
                        setAlternativeValueUpdated(true);
                        updateValue({ id, value, isText: true });
                      }}
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default DictionaryControl;
