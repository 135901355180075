import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, Prompt } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  ClickAwayListener,
  Typography,
  Tooltip,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import FullCalendar from "@fullcalendar/react"; //required for fullcalendar plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { startOfMonth } from "date-fns";
import { DateTime } from "luxon";
import { calendarEventTypes } from "../../components/schedule-picker";
import Can from "../../components/can";
import SchedulePicker from "../../components/schedule-picker";
import "./index.css";
import StyledCalendar from "../../components/calendar";
import Loader from "../../components/loader";
import {
  addCalendarEvents,
  alertAdd,
  deleteCalendarEvent,
  editCalendarEvent,
  generateCalendarEvents,
  getCalendarEventsToEdit,
} from "../../redux/actions";
import {
  mapBEEventsToFEEvents,
  mapFEEventsToBEEvents,
  mapBEEventToFEEvent,
  mapFEEventToBEEvent,
  mapBECalendarEventsDTOsToFEEvents,
  fromOldEventToNewEvent,
} from "./events-functions";
import CalendarEventCard from "../../components/calendar/calendar-event-card";
import PageTitle from "../../components/page-title";
import { AvailableDoctorsList } from "./book-appointment";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import EventEdit from "../../components/calendar/event-edit";
import LoaderButton from "../../components/loader-button";
import { getAvailableEditors } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  containedButtonContrast: {
    backgroundColor: "#000",
    color: "#fff200",
    border: "1px solid #fff200",
  },
}));

const canEventsOverlap = (stillEvent, movingEvent) => {
  return (
    canEventOverlap(
      stillEvent.extendedProps.type,
      stillEvent.extendedProps.possibleTypes
    ) ||
    canEventOverlap(
      movingEvent.extendedProps.type,
      movingEvent.extendedProps.possibleTypes
    )
  );
};

const canEventOverlap = (type, possibleTypes) => {
  if (type) {
    return calendarEventTypes.find(({ key }) => key === type).canOverlap;
  } else {
    return canEventWithPossibleTypesOverlap(possibleTypes);
  }
};

const canEventWithPossibleTypesOverlap = (possibleTypes) => {
  return possibleTypes
    .map(
      (type) => calendarEventTypes.find(({ key }) => key === type).canOverlap
    )
    .reduce((res, cur) => res && cur);
};

const compareTwoDates = (day1, day2) => {
  return JSON.stringify(day1) === JSON.stringify(day2);
};

const CalendarEdit = ({ my, history }) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const styles = useStyles();
  const [events, setEvents] = useState([]);
  const [schedulesId, setSchedulesId] = useState([1]);
  const [eventClicked, setEventClicked] = useState();
  const [loading, setLoading] = useState(true);
  const [datesRange, setDatesRange] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [deleteConfirmation, setDeleteConfirmation] = useState();
  const [editedEvent, setEditedEvent] = useState();
  const [dataUnsaved, setDataUnsaved] = useState(false);
  const isOwner = !!my.user.authorities.includes("ROLE_OWNER");
  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const loadingAvailableEditors = useSelector((s) => s.loadingAvailableEditors);
  const availableDoctors = useSelector((s) => s.availableEditors);

  useEffect(() => {
    setLoading(true);
    isOwner && dispatch(getAvailableEditors());
  }, []);

  useEffect(() => {
    setLoading(loadingAvailableEditors);
  }, [loadingAvailableEditors]);

  useEffect(() => {
    getEvents();
  }, [datesRange, selectedDoctor]);

  const getEvents = () => {
    if (datesRange.start || datesRange.end) {
      if (isOwner && selectedDoctor) {
        setLoading(true);
        getCalendarEventsToEdit(
          datesRange.start,
          datesRange.end,
          selectedDoctor.id
        )
          .then((data) => {
            setEvents((prevState) => [
              ...prevState.filter(({ idBE }) => !idBE),
              ...mapBECalendarEventsDTOsToFEEvents(data.data, true),
            ]);
            setLoading(false);
          })
          .catch(() => {
            //todo
          });
      }

      if (!isOwner) {
        setLoading(true);
        getCalendarEventsToEdit(datesRange.start, datesRange.end)
          .then((data) => {
            setEvents((prevState) => [
              ...prevState.filter(({ idBE }) => !idBE),
              ...mapBECalendarEventsDTOsToFEEvents(data.data, true),
            ]);
            setLoading(false);
          })
          .catch(() => {
            //todo
          });
      }
    }
  };

  const handleScheduleSave = (schedule) => {
    schedule = {
      ...schedule,
      selectedDays: schedule.selectedDays.filter((d) => d !== "EVERYDAY"),
    };
    generateCalendarEvents(schedule)
      .then((resp) => {
        if (Array.isArray(resp.data) && resp.data.length === 0) {
          dispatch(
            alertAdd({
              text:
                "Wydarzenia nie zostały wygenerowane. Sprawdź czy harmonogram jest poprawny.",
              isError: false,
            })
          );
        } else {
          setDataUnsaved(true);
        }

        setEvents((prevState) => [
          ...prevState.filter(({ scheduleId }) => scheduleId !== schedule.id),
          ...mapBEEventsToFEEvents(
            resp.data,
            calendarEventTypes.find(({ key }) => key === schedule.type)
              .isAppointment
          ),
        ]);
      })
      .catch((e) => {
        let errTxt = "Nie udało się wygenerować wydarzeń.";
        if (
          e.response.data.message?.includes("end-date-greater-then-maximum")
        ) {
          errTxt =
            errTxt +
            " Data zakończenia harmonogramu musi być wcześniejsza niż rok od dzisiaj.";
        } else if (e.response.status === 409) {
          errTxt = errTxt + " Harmonogram jest nieprawidłowy.";
        }
        dispatch(
          alertAdd({
            text: errTxt,
            isError: true,
          })
        );
      });
  };

  const addEmptySchedule = () => {
    setSchedulesId((prevState) => [
      ...prevState,
      Math.max(...prevState.map((id) => id)) + 1,
    ]);
  };

  const handleDeleteSchedule = (id) => {
    setSchedulesId((prevState) => prevState.filter((sch) => sch !== id));

    setEvents((prevState) =>
      prevState.filter(({ scheduleId }) => scheduleId !== id)
    );
  };

  const findEventFromFCCallbackWhenEdit = (info) => {
    return events.find((ev) => {
      return (
        (ev.scheduleId === info.event._def.extendedProps.scheduleId ||
          (ev.idBE && ev.idBE === info.event._def.extendedProps.idBE)) &&
        compareTwoDates(
          ev.start,
          DateTime.fromISO(info.oldEvent.start.toISOString(), { zone: ev.zone })
        ) &&
        (compareTwoDates(
          ev.end,
          DateTime.fromISO(info.oldEvent.end.toISOString(), { zone: ev.zone })
        ) ||
          (ev.allDay === true && info.oldEvent.allDay === true))
      );
    });
  };

  const findEventFromFCCallbackWhenDragStop = (info) => {
    return events.find((ev) => {
      return (
        (ev.scheduleId === info.event._def.extendedProps.scheduleId ||
          (ev.idBE && ev.idBE === info.event._def.extendedProps.idBE)) &&
        compareTwoDates(
          ev.start,
          DateTime.fromISO(info.event.start.toISOString(), { zone: ev.zone })
        ) &&
        (compareTwoDates(
          ev.end,
          DateTime.fromISO(info.event.end.toISOString(), { zone: ev.zone })
        ) ||
          (ev.allDay === true && info.event.allDay === true))
      );
    });
  };

  const handleChangeEventByDropOrResize = (info) => {
    const found = findEventFromFCCallbackWhenEdit(info);

    if (found) {
      const newEvent = fromOldEventToNewEvent(found, info);
      if (found.idBE) {
        const isAvailabilityEvent = found.isAvailabilityEvent;
        editCalendarEvent(
          found.idBE,
          mapFEEventToBEEvent(newEvent),
          isAvailabilityEvent
        )
          .then((resp) => {
            dispatch(
              alertAdd({
                text: "Edytowano wydarzenie.",
                isSuccess: true,
              })
            );
            setEvents((prevState) =>
              prevState.map((ev) => {
                if (ev == found) {
                  return mapBEEventToFEEvent(
                    resp.data,
                    isAvailabilityEvent,
                    true
                  );
                } else {
                  return { ...ev };
                }
              })
            );
            info.event.setDates(newEvent.start, newEvent.end);
          })
          .catch((error) => {
            info.revert();
            //todo wyswietlic jakie wydarzenia nachodza na siebie
            let errTxt = "Nie udało się zapisać wydarzenia.";
            const msg = error.response.data.message;
            if (msg === "end-date-cannot-be-before-start-date") {
              errTxt =
                "Nie można zapisać wydarzenia z datą zakończenia wcześniejszą niż data rozpoczęcia.";
            } else if (msg === "cannot-edit-past-event") {
              errTxt = "Nie można edytować przeszłej wizyty.";
            } else if (msg === "event-overlaps-existing-event") {
              errTxt = errTxt + " Wydarzenie nachodzi na inne wydarzenie.";
            }

            dispatch(
              alertAdd({
                text: errTxt,
                isError: true,
              })
            );
          });
      } else {
        setEvents((prevState) =>
          prevState.map((ev) => {
            if (ev == found) {
              return newEvent;
            } else {
              return { ...ev };
            }
          })
        );
        info.event.setDates(newEvent.start, newEvent.end);
      }
    } else {
      console.log("not found event");
      info.revert();
    }
  };

  const saveNewEvents = async () => {
    const newEvents = events.filter(({ idBE }) => !idBE);
    await addCalendarEvents(
      {
        calendarEvents: mapFEEventsToBEEvents(
          newEvents.filter(
            ({ isAvailabilityEvent }) => isAvailabilityEvent === false
          )
        ),
        availabilityEvents: mapFEEventsToBEEvents(
          newEvents.filter(
            ({ isAvailabilityEvent }) => isAvailabilityEvent === true
          )
        ),
      },
      isOwner ? selectedDoctor.id : undefined
    )
      .then(() => {
        setDataUnsaved(false);
        dispatch(
          alertAdd({
            text: "Zapisano wydarzenia.",
            isSuccess: true,
          })
        );
        history.push("/calendar");
      })
      .catch((error) => {
        let errTxt = "Nie udało się zapisać wydarzeń.";
        const msg = error.response.data.message;
        if (msg === "end-date-cannot-be-before-start-date") {
          errTxt =
            "Nie można zapisać wydarzenia z datą zakończenia wcześniejszą niż data rozpoczęcia.";
        } else if (msg === "cannot-create-past-event") {
          errTxt = "Nie można stworzyć przeszłej wizyty.";
        } else if (
          Array.isArray(error.response.data) &&
          error.response.data.length > 0
        ) {
          errTxt =
            errTxt + " Wydarzenia typu wizyta nachodzą na inne wydarzenia.";
        }
        dispatch(
          alertAdd({
            text: errTxt,
            isError: true,
          })
        );
      });
  };

  const handleEventClick = (info) => {
    const found = findEventFromFCCallbackWhenDragStop(info);
    if (found) {
      setEventClicked({
        ...found,
        x: info.jsEvent.x,
        y: info.jsEvent.y,
        startFC: info.event.start,
        endFC: info.event.end,
        event: found,
        info,
      });
    }
  };

  const deleteEvent = (event, info) => {
    const isAvailabilityEvent = event.isAvailabilityEvent;
    if (event.idBE) {
      deleteCalendarEvent(event.idBE, isAvailabilityEvent)
        .then(() => {
          dispatch(
            alertAdd({
              text: "Usunięto wydarzenie.",
              isSuccess: true,
            })
          );
          info.event.remove();
          setEvents((prevState) => prevState.filter((ev) => ev != event));
        })
        .catch((error) => {
          let errTxt = "Nie udało się usunąć wydarzenia.";
          const msg = error.response.data.message;
          if (msg === "cannot-delete-past-event") {
            errTxt = "Nie można usunąć przeszłego wydarzenia.";
          } else if (msg === "delete-booked-appointment") {
            errTxt = "Nie można usunąć zarezerwowanej wizyty.";
          }
          dispatch(
            alertAdd({
              text: errTxt,
              isError: true,
            })
          );
        });
    } else {
      info.event.remove();
      setEvents((prevState) => prevState.filter((ev) => ev != event));
    }
  };

  const handleChangeDatesRange = (startEnd) => {
    if (datesRange.start || datesRange.end) {
      if (
        JSON.stringify(datesRange.start) != JSON.stringify(startEnd.start) ||
        JSON.stringify(datesRange.end) != JSON.stringify(startEnd.end)
      ) {
        if (datesRange.start > startEnd.start || datesRange.end < startEnd.end)
          setDatesRange(startEnd);
      }
    } else setDatesRange(startEnd);
  };

  const handleCloseEditPanel = () => {
    setEditedEvent();
  };

  const handleOpenEditPanel = (id) => {
    setEditedEvent(events.find(({ idBE }) => idBE && idBE === id));
  };

  const updateEventAfterEdit = (savedEvent) => {
    setEvents((prevState) =>
      prevState.map((ev) => {
        if (ev.idBE === savedEvent.idBE) {
          return savedEvent;
        } else {
          return { ...ev };
        }
      })
    );
    handleCloseEditPanel();
  };

  const refreshEvents = () => {
    setEvents((prevState) => prevState.map((e) => e));
  };

  const page = () => (
    <Box
      py={1}
      px={mediumScreen ? 1 : 4}
      style={{
        boxSizing: "border-box",
        //margin: "24px auto",
        //maxWidth: 1100
      }}
    >
      <Box pb={1}>
        <PageTitle title="Edycja grafiku" />
      </Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {isOwner && availableDoctors.length > 0 && (
            <AvailableDoctorsList
              doctors={availableDoctors}
              onDoctorSelect={setSelectedDoctor}
              selectedDoctorId={selectedDoctor ? selectedDoctor.id : undefined}
            />
          )}
        </Grid>
        {(!isOwner || (isOwner && selectedDoctor)) && (
          <Grid item>
            {schedulesId.map((schedule) => (
              <SchedulePicker
                key={schedule}
                id={schedule}
                onScheduleSave={handleScheduleSave}
                onScheduleDelete={handleDeleteSchedule}
              />
            ))}

            <Grid item container justify="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  className={
                    globalTheme === "high-contrast"
                      ? styles.containedButtonContrast
                      : ""
                  }
                  onClick={addEmptySchedule}
                  endIcon={<AddIcon />}
                >
                  Dodaj harmonogram
                </Button>
              </Grid>
              <Grid item>
                {dataUnsaved ? (
                  <LoaderButton
                    onClick={saveNewEvents}
                    text={"Zapisz nowe wydarzenia"}
                    endIcon={<SaveIcon />}
                    loadingText="Zapisuję..."
                  />
                ) : (
                  <Tooltip title="Wygeneruj wydarzenia, aby móc zapisać zmiany.">
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={true}
                        onClick={saveNewEvents}
                        endIcon={<SaveIcon />}
                      >
                        Zapisz nowe wydarzenia
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <StyledCalendar
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                events={events}
                editable={eventClicked ? false : true}
                eventDrop={handleChangeEventByDropOrResize}
                eventResize={handleChangeEventByDropOrResize}
                eventOverlap={canEventsOverlap}
                eventClick={handleEventClick}
                validRange={(now) => ({ start: startOfMonth(now) })}
                editMode={true}
                datesSet={(dateInfo) => handleChangeDatesRange(dateInfo)}
                refreshEvents={refreshEvents}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {eventClicked && (
        <ClickAwayListener
          onClickAway={() => setEventClicked()}
          mouseEvent={"onMouseDown"}
          touchEvent={"onTouchStart"}
        >
          <Box>
            <CalendarEventCard
              event={eventClicked}
              style={{
                position: "fixed",
                zIndex: 99,
                top: eventClicked.y,
                left: eventClicked.x,
              }}
              onEditVisitClick={eventClicked.idBE && handleOpenEditPanel}
              onDeleteVisitClick={setDeleteConfirmation}
            />
          </Box>
        </ClickAwayListener>
      )}
      {editedEvent && (
        <EventEdit
          open={true}
          editedEvent={editedEvent}
          handleClosePanel={handleCloseEditPanel}
          passSavedEvent={updateEventAfterEdit}
        />
      )}
      {loading && (
        <Box
          style={{
            position: "fixed",
            zIndex: 99,
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
          }}
        >
          <Loader loading={true} text="Pobieranie wydarzeń z kalendarza..." />
        </Box>
      )}
      <ConfirmDialogMUI
        handleClose={() => {
          setDeleteConfirmation();
        }}
        open={!!deleteConfirmation}
        text={"Czy chcesz usunąć wydarzenie:"}
        yesAction={() => {
          deleteEvent(deleteConfirmation.event, deleteConfirmation.info);
        }}
        noAction={() => {}}
      >
        {deleteConfirmation && (
          <CalendarEventCard
            event={{
              ...deleteConfirmation.event,
              startFC: deleteConfirmation.startFC,
              endFC: deleteConfirmation.endFC,
            }}
          />
        )}
      </ConfirmDialogMUI>
      {availableDoctors.length === 0 && !loading && isOwner && (
        <Typography
          style={{
            position: "fixed",
            zIndex: 99,
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
          }}
        >
          Brak dostępnych lekarzy
        </Typography>
      )}
      <Prompt
        when={dataUnsaved}
        message="Nowe wydarzenia nie zostały zapisane, jeśli nie chcesz utracić wygenerowanych wydarzeń musisz je zapisać. Czy mimo to chcesz opuścić stronę?"
      />
    </Box>
  );

  const redirect = () => <Redirect to="/calendar" />;
  return <Can permission="calendar:change" ok={page} not={redirect} />;
};

export default connect((state) => ({ my: state.my }), null)(CalendarEdit);
