import { DONE, FAILED, START } from "../../constants";
import { getAxiosInstance } from "../common";
import { LOGOUT_DONE } from "../../constants";

export const UPDATE_CONFIG_GLOBAL = "UPDATE_CONFIG_GLOBAL";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_ORGANIZATIONS = "FETCH_ALL_ORGANIZATIONS";
export const FETCH_USERS_FOR_ORGANIZATION = "FETCH_USERS_FOR_ORGANIZATION";
export const FETCH_ALL_FORM_GROUPS = "FETCH_ALL_FORM_GROUPS";
export const FETCH_ALL_FORMS = "FETCH_ALL_FORMS";
export const FETCH_IDENTITY_DONE = "FETCH_IDENTITY_DONE";

export const logoutAdmin = (callback) => async (dispatch) => {
  try {
    await getAxiosInstance().post("/api/auth/logout-owner", {});
    callback && callback();
    dispatch({ type: LOGOUT_DONE });
  } catch (e) {}
};

export const checkIdentityAdmin = () => async (dispatch) => {
  try {
    const result = await getAxiosInstance().get("/api/auth/identity", {});
    dispatch({ type: FETCH_IDENTITY_DONE, payload: result.data });
  } catch (e) {}
};

export const updateConfigGlobal = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_CONFIG_GLOBAL + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/config_global/1`,
      payload
    );
    dispatch({ type: UPDATE_CONFIG_GLOBAL + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: UPDATE_CONFIG_GLOBAL + FAILED, payload });
    return false;
  }
};

export const fetchAllUsers = ({
  page,
  pageSize,
  sortProperties,
  sortDirection,
  ...body
}) => async (dispatch) => {
  const sortDirectionBE = sortDirection === "ASC" ? "" : "-";
  dispatch({ type: FETCH_ALL_USERS + START });
  try {
    const result = await getAxiosInstance().post("/api/persons/search", body, {
      params: {
        page,
        pageSize,
        sort: `${sortDirectionBE}${sortProperties}`,
      },
    });
    dispatch({ type: FETCH_ALL_USERS + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ALL_USERS + FAILED, payload });
    return false;
  }
};

export const fetchAllOrganizationNames = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_ORGANIZATIONS + START });
  try {
    const result = await getAxiosInstance().get("/api/organizations/all_names");
    dispatch({ type: FETCH_ALL_ORGANIZATIONS + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ALL_ORGANIZATIONS + FAILED, payload });
    return false;
  }
};

export const fetchAllFormsGroups = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_ALL_FORM_GROUPS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/form_group/organization/${orgId}/all`
    );
    dispatch({
      type: FETCH_ALL_FORM_GROUPS + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ALL_FORM_GROUPS + FAILED, payload });
    return false;
  }
};

export const fetchAllForms = (orgId, formStatus) => async (dispatch) => {
  dispatch({ type: FETCH_ALL_FORMS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/forms/organization/${orgId}/all`,
      {
        params: {
          formStatus,
        },
      }
    );
    dispatch({
      type: FETCH_ALL_FORMS + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ALL_FORMS + FAILED, payload });
    return false;
  }
};
