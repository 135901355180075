import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import Button from "@material-ui/core/Button";
import s from "./index.module.css";
import * as classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { fetchUploadableFileTypes } from "../../../redux/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

const FileControl = ({
  name,
  id,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  disabled,
  showSelfDescription,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  sidebarMode,
  onRemove,
  formCreatorMode,
  filledFormMode,
  incorrectFileTypeIDs,
  setIncorrectFileTypeIDs,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.FILE,
      dragMode,
      defaultLabel: "Plik",
      id,
      rowIndex,
      index,
    },
    collect: (monitor) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const isAdmin = useSelector((s) =>
    s.my.user.authorities.includes("ROLE_SUPER_OPERATOR")
  );
  const location = useLocation();
  const [value, setValue] = useState(filledValue || []);
  const [fieldIDWithMovie, setFieldIDWithMovie] = useState("");
  const uploadableFileTypes = useSelector((s) => s.uploadableFileTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadableFileTypes.length === 0) {
      dispatch(fetchUploadableFileTypes());
    }
  }, [uploadableFileTypes]);

  useEffect(() => {
    if (filledValue?.length > 0) {
      setValue(filledValue);
    }
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.FILE,
      configuration,
    });

  const updateValue = (v) => {
    let tmpValueArray = [...value];
    tmpValueArray = tmpValueArray.concat(v);
    setValue(tmpValueArray);

    if (onChange) {
      onChange({
        id,
        value: tmpValueArray,
        isFiles: true,
      });
    }
  };

  const removeValue = (v, index) => {
    setValue(value.filter((value, idx) => index !== idx));
    if (Object.keys(incorrectFileTypeIDs).length !== 0) {
      if (value.filter((value, idx) => index !== idx).length === 0) {
        let tmpIncorrectFiles = { ...incorrectFileTypeIDs };
        delete tmpIncorrectFiles[id];
        setIncorrectFileTypeIDs(tmpIncorrectFiles);
      } else {
        setIncorrectFileTypeIDs({
          ...incorrectFileTypeIDs,
          [id]: value.filter((value, idx) => index !== idx),
        });
      }
    }

    if (onChange) {
      onChange({
        id,
        value: value.filter((value, idx) => index !== idx),
        isFiles: true,
      });
    }
  };

  const handleUploadedFiles = async (files) => {
    const ff = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      ff.push(file);
    }

    updateValue(ff);
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Plik"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Plik"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";
  const fileType = configuration.fileType;

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  const extensions = (
    uploadableFileTypes.find((x) => x.name === fileType)?.details || []
  )
    .map((x) => x.extension)
    .filter((v, i, a) => a.indexOf(v) == i);

  return (
    <div ref={drag}>
      {configuration && configuration.label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {configuration.label}
          {configuration.required && " *"}
        </label>
      )}
      {configuration?.text && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          className={s.hintText}
        >
          {configuration.text}
        </label>
      )}
      {!filledFormMode && (
        <>
          <input
            style={{ display: "none" }}
            id={`contained-button-file-${id}`}
            type="file"
            multiple
            disabled={configuration.maxNumberOfFiles === value.length}
            onChange={(event) => {
              handleUploadedFiles(event.target.files);
              event.target.value = null;
            }}
          />
          <label htmlFor={`contained-button-file-${id}`}>
            <Button
              disabled={configuration.maxNumberOfFiles === value.length}
              variant="contained"
              color="primary"
              component="span"
            >
              Wgraj
            </Button>
          </label>

          {extensions.length > 0 ? (
            <div
              className={`${classes.fontSize}`}
              style={{ color: globalTheme === "high-contrast" && "yellow" }}
            >
              Wspierane rozszerzenia: {extensions.join(", ")}; maksymalna ilość
              plików: {configuration.maxNumberOfFiles}
            </div>
          ) : (
            <div
              className={`${classes.fontSize}`}
              style={{ color: globalTheme === "high-contrast" && "yellow" }}
            >
              Wspierane rozszerzenia: dowolne; maksymalna ilość plików:{" "}
              {configuration.maxNumberOfFiles}
            </div>
          )}
        </>
      )}
      <ul>
        {(value &&
          value.length > 0 &&
          value.map((f, index) => (
            <React.Fragment key={index}>
              <li key={index}>
                <span
                  className={`${classes.fontSize}`}
                  style={{ color: globalTheme === "high-contrast" && "yellow" }}
                >
                  {f.name}
                </span>
                {filledFormMode &&
                  (!f.mimeType.includes("video") ? (
                    f.mimeType.includes("image") && (
                      <a
                        href={
                          f.id
                            ? `/api/form_data/serve_file${
                                isAdmin && location.state?.orgId
                                  ? `/${location.state.orgId}`
                                  : ""
                              }/${f.id}`
                            : "#"
                        }
                        target="_blank"
                        className={`${classes.fontSize}`}
                        style={{
                          color: globalTheme === "high-contrast" && "yellow",
                        }}
                      >
                        Podgląd
                      </a>
                    )
                  ) : !(fieldIDWithMovie === f.id) ? (
                    <a onClick={() => setFieldIDWithMovie(f.id)}>Podgląd</a>
                  ) : (
                    <a onClick={() => setFieldIDWithMovie("")}>Zamknij</a>
                  ))}{" "}
                {filledFormMode && (
                  <a
                    href={
                      f.id
                        ? `/api/form_data/serve_file${
                            isAdmin && location.state?.orgId
                              ? `/${location.state.orgId}`
                              : ""
                          }/${f.id}?download=true`
                        : "#"
                    }
                    className={`${classes.fontSize}`}
                    style={{
                      color: globalTheme === "high-contrast" && "yellow",
                    }}
                    target="_black"
                  >
                    Pobierz
                  </a>
                )}{" "}
                {!disabled && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      removeValue(f, index);
                    }}
                    className={`${classes.fontSize}`}
                    style={{
                      color: globalTheme === "high-contrast" && "yellow",
                    }}
                  >
                    Usuń
                  </a>
                )}
                {fieldIDWithMovie === f.id && (
                  <div>
                    <video width="1024" height="800" controls>
                      <source
                        src={
                          f.id
                            ? `/api/form_data/serve_file${
                                isAdmin && location.state?.orgId
                                  ? `/${location.state.orgId}`
                                  : ""
                              }/${f.id}`
                            : "#"
                        }
                        type="video/mp4"
                      />
                    </video>{" "}
                  </div>
                )}
              </li>
            </React.Fragment>
          ))) || (
          <span
            className={`${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            Brak
          </span>
        )}
        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color: globalTheme === "high-contrast" && "#df9700",
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              To pole musi zostać wypełnione
            </p>
          )}
      </ul>
      {incorrectFileTypeIDs?.hasOwnProperty(id) &&
        incorrectFileTypeIDs[id].length > 0 && (
          <p class="help is-danger">Niepoprawny typ pliku</p>
        )}
    </div>
  );
};

export default FileControl;
