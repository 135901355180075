import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  Popper,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloseIcon from "@material-ui/icons/Close";
import { normalizeZIP } from "../address-form/shipping-info";

const MAX_FILTER_VALUE_LENGTH = 150;

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "4px 0px",
    "&:last-child": {
      paddingRight: "0px",
    },
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "354px",
    backgroundColor: "whitesmoke",
  },
  yearWidth: {
    width: "100px",
  },
  paper: {
    backgroundColor: "whitesmoke",
    padding: "24px",
  },
  btnLeft: {
    float: "left",
    marginLeft: "8px",
    marginTop: "4px",
    fontSize: "0.7rem",
  },
  nonePadding: {
    padding: "0px",
  },
  marginTop: {
    marginTop: "4px",
  },
  btn: {
    margin: "4px",
  },
  closeBtn: {
    float: "right",
  },
  width: {
    width: "200px",
  },
  buttonsAlign: {
    textAlign: "right",
  },
}));

const fields = [
  { label: "firstName", text: "Imię", sortable: "true" },
  { label: "surname", text: "Nazwisko", sortable: "true" },
  { label: "mail", text: "Email", sortable: "true" },
  { label: "user.phone", text: "Telefon", sortable: "false" },
  { label: "user.authorities", text: "Rola", sortable: "false" },
  { label: "gender", text: "Płeć", sortable: "false" },
  { label: "yearOfBirth", text: "Rok urodzenia", sortable: "true" },
  { label: "address.city", text: "Miasto", sortable: "false" },
  { label: "address.street", text: "Ulica", sortable: "false" },
  { label: "address.zipCode", text: "Kod pocztowy", sortable: "false" },
  { label: "createdAt", text: "Data utworzenia", sortable: "true" },
];

const Filters = ({ changeFilters, sort, changeSort }) => {
  const styles = useStyles();
  const [state, setState] = useState({});
  const [roleFilterOpen, setRoleFilterOpen] = useState(false);
  const [genderFilterOpen, setGenderFilterOpen] = useState(false);
  const [errorSince, setErrorSince] = useState(false);
  const [errorUntil, setErrorUntil] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const btn = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const initialState = fields.reduce((acc, cur) => {
    return { ...acc, [cur.label]: "" };
  }, {});

  useEffect(() => {
    setState(initialState);
  }, []);

  const changeState = (label, operation, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [label]: {
          ...prevState[label],
          [operation]: value,
        },
      };
    });
  };

  const clearFilters = () => {
    setState(initialState);
    changeFilters(initialState);
  };

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : btn.current);
  };

  const SortingArrows = ({ label }) => {
    let dir = "";
    if (label === sort.sortProperties) dir = sort.sortDirection;
    return (
      <span>
        <IconButton
          size="small"
          className={styles.nonePadding}
          onClick={() =>
            changeSort({ sortProperties: label, sortDirection: "DESC" })
          }
        >
          <ArrowDownwardIcon color={dir === "DESC" ? "inherit" : "disabled"} />
        </IconButton>
        <IconButton
          size="small"
          className={styles.nonePadding}
          onClick={() =>
            changeSort({ sortProperties: label, sortDirection: "ASC" })
          }
        >
          <ArrowUpwardIcon color={dir === "ASC" ? "inherit" : "disabled"} />
        </IconButton>
      </span>
    );
  };

  return (
    <Box display="block">
      <Button
        aria-describedby={id}
        className={styles.btnLeft}
        ref={btn}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Pokaż więcej opcji
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <IconButton
          className={styles.closeBtn}
          size="small"
          onClick={handleClick}
        >
          <CloseIcon />
        </IconButton>
        <Paper className={styles.paper}>
          <Typography variant="h6" gutterBottom component="span">
            Filtry
          </Typography>
          <TableContainer>
            <Table
              className={styles.table}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {fields.map((opt) => {
                  let label = opt.label;
                  let operation = "contains";
                  let dataToRender;
                  switch (opt.label) {
                    case "yearOfBirth":
                      dataToRender = (
                        <span>
                          <TextField
                            id={`input-user-filter-${opt.label}-since`}
                            className={styles.yearWidth}
                            label="od"
                            variant="outlined"
                            size="small"
                            value={state.yearOfBirth?.gte || ""}
                            error={errorSince}
                            onChange={(event) => {
                              changeState(label, "gte", event.target.value);
                              setErrorSince(
                                isNaN(event.target.value) ? true : false
                              );
                            }}
                          />
                          <TextField
                            id={`input-user-filter-${opt.label}-until`}
                            className={styles.yearWidth}
                            label="do"
                            variant="outlined"
                            size="small"
                            value={state.yearOfBirth?.lte || ""}
                            error={errorUntil}
                            onChange={(event) => {
                              changeState(label, "lte", event.target.value);
                              setErrorUntil(
                                isNaN(event.target.value) ? true : false
                              );
                            }}
                          />
                        </span>
                      );
                      break;
                    case "user.authorities":
                      dataToRender = (
                        <FormControl
                          key={opt.label}
                          className={styles.width}
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel id="open-role-filter-label">
                            Rola
                          </InputLabel>
                          <Select
                            labelId="open-role-filter-label"
                            id="open-role-filter"
                            open={roleFilterOpen}
                            label={"Filtruj po roli"}
                            value={state["user.authorities"]?.contains || ""}
                            onClose={() => setRoleFilterOpen(false)}
                            onOpen={() => setRoleFilterOpen(true)}
                            onChange={(event) =>
                              changeState(label, operation, event.target.value)
                            }
                          >
                            <MenuItem value="">Dowolna</MenuItem>
                            <MenuItem value="ROLE_OWNER">
                              Właściciel organizacji
                            </MenuItem>
                            <MenuItem value="ROLE_EDITOR">
                              {process.env.REACT_APP_USE_PATIENT === "true"
                                ? "Lekarz"
                                : "Redaktor"}
                            </MenuItem>
                            <MenuItem value="ROLE_USER">
                              {process.env.REACT_APP_USE_PATIENT === "true"
                                ? "Pacjent"
                                : "Użytkownik"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      );
                      break;
                    case "gender":
                      operation = "is";

                      dataToRender = (
                        <FormControl
                          key={opt.label}
                          className={styles.width}
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel id="open-gender-filter-label">
                            Płeć
                          </InputLabel>
                          <Select
                            labelId="open-gender-filter-label"
                            id="open-gender-filter"
                            open={genderFilterOpen}
                            label={"Filtruj po płci"}
                            value={state.gender?.is || ""}
                            onClose={() => setGenderFilterOpen(false)}
                            onOpen={() => setGenderFilterOpen(true)}
                            onChange={(event) =>
                              changeState(label, operation, event.target.value)
                            }
                          >
                            <MenuItem value="">Dowolna</MenuItem>
                            <MenuItem value="Female">Kobieta</MenuItem>
                            <MenuItem value="Male">Mężczyzna</MenuItem>
                          </Select>
                        </FormControl>
                      );
                      break;
                    case "createdAt":
                      dataToRender = "";
                      break;

                    case "address.zipCode":
                      dataToRender = (
                        <TextField
                          key={opt.label}
                          id={`input-user-filter-${opt.label}`}
                          className={styles.width}
                          label={opt.text}
                          variant="outlined"
                          size="small"
                          value={state[opt.label]?.contains || ""}
                          inputProps={{ maxLength: 6 }}
                          onChange={(event) => {
                            const value = event.target.value;
                            changeState(label, operation, normalizeZIP(value));
                          }}
                        />
                      );
                      break;

                    default:
                      dataToRender = (
                        <TextField
                          key={opt.label}
                          id={`input-user-filter-${opt.label}`}
                          className={styles.width}
                          label={opt.text}
                          variant="outlined"
                          size="small"
                          value={state[opt.label]?.contains || ""}
                          inputProps={{ maxLength: MAX_FILTER_VALUE_LENGTH }}
                          onChange={(event) =>
                            changeState(label, operation, event.target.value)
                          }
                        />
                      );
                      break;
                  }
                  return (
                    <TableRow key={opt.label}>
                      <TableCell>
                        {opt.sortable === "true" && (
                          <SortingArrows label={opt.label} />
                        )}
                      </TableCell>
                      <TableCell>{opt.text}</TableCell>
                      <TableCell>{dataToRender}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.buttonsAlign}>
            <Button
              className={styles.btn}
              variant="contained"
              disabled={errorSince || errorUntil}
              onClick={() => clearFilters()}
            >
              Wyczyść
            </Button>

            <Button
              className={styles.btn}
              variant="contained"
              color="primary"
              disabled={errorSince || errorUntil}
              onClick={() => changeFilters(state)}
            >
              Zastosuj
            </Button>
          </div>
        </Paper>
      </Popper>
    </Box>
  );
};

export default Filters;
