import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Box,
  Button,
  ClickAwayListener,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FullCalendar from "@fullcalendar/react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DateTime } from "luxon";
import Can from "../../components/can";
import Loader from "../../components/loader";
import StyledCalendar, { useDatesRange } from "../../components/calendar";
import {
  alertAdd,
  bookCalendarVisit,
  getAvailableEditorsWithVisit,
  getAvailableVisits,
  getFirstVisit,
  updateAvailableVisits,
} from "../../redux/actions";
import { mapBEEventToFEEvent } from "./events-functions";
import CalendarEventCard from "../../components/calendar/calendar-event-card";
import { highContrastTheme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  border: {
    border: `1px solid ${
      highContrastTheme.palette.primary.main === theme.palette.primary.main
        ? "yellow"
        : "lightgrey"
    }`,
    "& .MuiTableCell-root": {
      borderBottom: `1px solid ${
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "lightgrey"
      }`,
    },
  },
  selectedDoctor: {
    borderBottom:
      highContrastTheme.palette.primary.main === theme.palette.primary.main
        ? "3px solid yellow"
        : "",
    backgroundColor:
      highContrastTheme.palette.primary.main === theme.palette.primary.main
        ? ""
        : "lightgrey",
  },
}));

export const AvailableDoctorsList = ({
  doctors,
  onDoctorSelect,
  selectedDoctorId,
  forVisits = false,
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  if (open) {
    return (
      <Box my={1}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpen(false)}
          endIcon={<ExpandLessIcon fontSize="large" />}
          style={{ marginBottom: 8 }}
        >
          Zwiń lekarzy
        </Button>
        <TableContainer
          className={classes.border}
          style={{
            maxWidth: 600,
            borderRadius: 3,
          }}
        >
          <Table
            size="small"
            style={{
              maxWidth: 600,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Imię i nazwisko</TableCell>
                {forVisits && (
                  <TableCell style={{ textAlign: "center" }}>
                    Pierwszy dostępny termin
                  </TableCell>
                )}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doctors.map((doctor) => (
                <TableRow key={doctor.id}>
                  <TableCell>
                    <span
                      className={
                        selectedDoctorId === doctor.id
                          ? classes.selectedDoctor
                          : ""
                      }
                    >
                      {doctor.firstName} {doctor.surname}
                    </span>
                  </TableCell>
                  {forVisits && (
                    <TableCell style={{ textAlign: "center" }}>
                      {doctor.simpleCalendarEventDTO
                        ? DateTime.fromISO(
                            doctor.simpleCalendarEventDTO.startDateTime,
                            {
                              zone: doctor.simpleCalendarEventDTO.zone,
                            }
                          )
                            .setZone(
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            )
                            .toLocaleString(DateTime.DATETIME_SHORT)
                        : "<brak>"}
                    </TableCell>
                  )}
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => onDoctorSelect(doctor)}
                      style={{ width: 200 }}
                    >
                      {forVisits ? "Zobacz dostępne wizyty" : "Zobacz grafik"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
        endIcon={<ExpandMoreIcon fontSize="large" />}
        style={{ marginBottom: 8, marginTop: 8 }}
      >
        Rozwiń lekarzy
      </Button>
    );
  }
};

const BookAppointment = ({ my, history }) => {
  const availableDoctors = useSelector((s) => s.availableEditorsWithVisit);
  const availableVisits = useSelector((s) => s.availableVisits);
  const [firstVisit, setFirstVisit] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [visitClicked, setVisitClicked] = useState();
  const [loading, setLoading] = useState(true);
  const [startEnd, setStartEnd] = useState({});
  const datesRange = useDatesRange(startEnd);
  const isEditor = my.user.authorities.localeCompare("ROLE_EDITOR") === 0;
  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const loadingAvailableEditorsWithVisit = useSelector(
    (s) => s.loadingAvailableEditorsWithVisit
  );
  const loadingAvailableVisits = useSelector((s) => s.loadingAvailableVisits);

  useEffect(() => {
    !isEditor
      ? dispatch(getAvailableEditorsWithVisit())
      : getFirstVisit(my.id).then((data) => {
          setFirstVisit(
            data.data ? mapBEEventToFEEvent(data.data, true) : null
          ); //todo: po typie eventu sprawdzic czy to availability event
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    setLoading(loadingAvailableEditorsWithVisit || loadingAvailableVisits);
  }, [loadingAvailableEditorsWithVisit, loadingAvailableVisits]);

  useEffect(() => {
    selectedDoctor &&
      setFirstVisit(
        selectedDoctor.simpleCalendarEventDTO
          ? mapBEEventToFEEvent(selectedDoctor.simpleCalendarEventDTO, true) //todo: po typie eventu sprawdzic czy to availability event
          : null
      );
  }, [selectedDoctor]);

  useEffect(() => {
    getEvents();
  }, [datesRange, selectedDoctor]);

  const getEvents = () => {
    if (datesRange.start || datesRange.end) {
      dispatch(
        getAvailableVisits(
          isEditor ? my.id : selectedDoctor.id,
          datesRange.start,
          datesRange.end
        )
      );
    }
  };

  const handleEventClick = (info) => {
    const found = availableVisits.find(
      ({ idBE }) => idBE === info.event._def.extendedProps.idBE
    );
    if (found) {
      setVisitClicked({
        ...found,
        x: info.jsEvent.x,
        y: info.jsEvent.y,
        startFC: info.event.start,
        endFC: info.event.end,
      });
    }
  };

  const bookVisit = async (eventId) => {
    const isAvailabilityEvent = availableVisits.find(
      ({ idBE }) => idBE === eventId
    ).isAvailabilityEvent;
    await bookCalendarVisit(eventId, isAvailabilityEvent)
      .then((data) => {
        dispatch(
          alertAdd({
            text: "Zarezerwowano wizytę.",
            isSuccess: true,
          })
        );
        history.push("/calendar");
      })
      .catch((e) => {
        dispatch(
          alertAdd({
            text: "Nie udało się zarezerwować wizyty",
            isError: true,
          })
        );
        const msg = e.response.data.message;
        if (
          msg &&
          (msg.includes("availability-event-id-") ||
            msg.includes("calendar-event-id-") ||
            msg.includes("book-visit-to-no-appointment-type-event") ||
            msg.includes("book-past-event") ||
            msg.includes("book-visit-with-other-doctor") ||
            msg.includes("book-self-visit-when-someone-already-booked") ||
            msg.includes("visit-already-booked") ||
            msg.includes("this-person-has-already-booked-this-visit"))
        ) {
          setVisitClicked();
          dispatch(
            updateAvailableVisits(
              availableVisits.filter(({ idBE }) => idBE !== eventId)
            )
          );
        }
      });
  };

  const refreshEvents = () => {
    dispatch(updateAvailableVisits(availableVisits.map((e) => e)));
  };

  const page = () => {
    return (
      <Box py={1} px={mediumScreen ? 1 : 4}>
        {!isEditor && availableDoctors.length > 0 && (
          <AvailableDoctorsList
            doctors={availableDoctors}
            onDoctorSelect={setSelectedDoctor}
            selectedDoctorId={selectedDoctor ? selectedDoctor.id : undefined}
            forVisits={true}
          />
        )}
        {!loading && !firstVisit && (isEditor || selectedDoctor) && (
          <Typography>Brak wolnych wizyt dla wybranego lekarza</Typography>
        )}
        {visitClicked && (
          <ClickAwayListener onClickAway={() => setVisitClicked()}>
            <Box>
              <CalendarEventCard
                event={visitClicked}
                onBookVisitClick={bookVisit}
                style={{
                  position: "fixed",
                  zIndex: 99,
                  top: visitClicked.y,
                  left: visitClicked.x,
                }}
              />
            </Box>
          </ClickAwayListener>
        )}
        {(isEditor || (!isEditor && selectedDoctor)) && firstVisit && (
          <StyledCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            events={availableVisits}
            eventClick={handleEventClick}
            initialDate={firstVisit ? firstVisit.start : undefined}
            datesSet={(dateInfo) =>
              setStartEnd({ start: dateInfo.start, end: dateInfo.end })
            }
            refreshEvents={refreshEvents}
          />
        )}
        {loading && (
          <Box
            style={{
              position: "fixed",
              zIndex: 99,
              top: "50%",
              left: "50%",
              transform: " translate(-50%, -50%)",
            }}
          >
            <Loader loading={true} text="Pobieranie dostępnych wizyt..." />
          </Box>
        )}
        {availableDoctors.length === 0 && !loading && !isEditor && (
          <Typography
            style={{
              position: "fixed",
              zIndex: 99,
              top: "50%",
              left: "50%",
              transform: " translate(-50%, -50%)",
            }}
          >
            Brak dostępnych lekarzy
          </Typography>
        )}
      </Box>
    );
  };
  const redirect = () => <Redirect to="/" />;

  return (
    <Can
      permission="calendar:book-cancel-appointment"
      ok={page}
      not={redirect}
    />
  );
};

export default connect((state) => ({ my: state.my }), null)(BookAppointment);
