import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Popper,
  Grid,
  Button,
  ButtonGroup,
  Paper,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  btnRight: {
    float: "right",
    marginRight: "8px",
    marginTop: "4px",
    fontSize: "0.7rem",
  },
  popper: {
    marginTop: "4px",
    marginRight: `${theme.spacing(2)}px`,
  },
  paper: {
    width: "400px",
    backgroundColor: "whitesmoke",
    padding: "32px",
  },
}));

const TableDisplayPopper = ({ changeSelected, options }) => {
  const styles = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

  const [anchorEl, setAnchorEl] = useState(null);
  const btn = useRef(null);
  const [openAC, setOpenAC] = useState(false);
  const timer = useRef(-1);

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : btn.current);
  };

  const setOpenByTimer = (isOpen) => {
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setOpenAC(isOpen);
    }, 200);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const MyPopper = function (props) {
    const addAllClick = (e) => {
      clearTimeout(timer.current);
      options.map(({ label }) => changeSelected(label, true));
    };
    const clearClick = (e) => {
      clearTimeout(timer.current);
      options.map(({ label }) => changeSelected(label, false));
    };
    return (
      <Popper {...props} style={{ backgroundColor: "whitesmoke" }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <ButtonGroup
            color="primary"
            size="small"
            aria-label="outlined primary button group"
          >
            <Button color="primary" onClick={addAllClick}>
              Zaznacz wszystkie
            </Button>
            <Button color="primary" onClick={clearClick}>
              Odznacz wszystkie
            </Button>
          </ButtonGroup>
          {props.children}
        </Grid>
      </Popper>
    );
  };

  return (
    <span>
      <Button
        aria-describedby={id}
        className={styles.btnRight}
        ref={btn}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Edytuj widok tabeli
      </Button>
      <Popper id={id} className={styles.popper} open={open} anchorEl={anchorEl}>
        <Paper className={styles.paper}>
          <Autocomplete
            PopperComponent={MyPopper}
            size="small"
            multiple
            disableCloseOnSelect
            disableClearable
            fullWidth
            value={options.filter((opt) => opt.selected === true)}
            options={options}
            getOptionLabel={(option) => option.text}
            getOptionSelected={(option, value) => option.label === value.label}
            renderOption={(option) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  value={option.selected ? option.text : ""}
                  checked={option.selected}
                  onChange={(e) => {
                    changeSelected(option.label, e.target.checked);
                  }}
                />
                {option.text}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label={"Pokazuj dane użytkownika"}
                placeholder={"Wybrane dane"}
              />
            )}
            onChange={(event, value, reason) => {
              if (reason === "remove-option") {
                options.map((opt) => {
                  if (
                    opt.selected !== false &&
                    !value.find(({ label }) => label === opt.label)
                  ) {
                    changeSelected(opt.label, false);
                  }
                });
              }
            }}
            onOpen={(e) => {
              setOpenByTimer(true);
            }}
            onClose={(obj, reason) => {
              setOpenByTimer(false);
            }}
            open={openAC}
          />
        </Paper>
      </Popper>
    </span>
  );
};

export default TableDisplayPopper;
