import React, { useState, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { ButtonGroup } from "@material-ui/core";
import { Popper } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    marginLeft: theme.spacing(2),
    display: "block",
  },
  popper: {
    backgroundColor: "whitesmoke",
  },
  formControl: {
    margin: "4px auto",
    width: "260px",
    display: "block",
  },
}));

const TableDisplayFilters = ({
  options,
  changeSelected,
  title,
  placeholder,
  multiple = true,
  changeFormStatusSelected,
  formStatus,
  displayIndividualSchedule,
  changeDisplayIndividualSchedule,
  displayOnlyFormsAllowedToUser,
  changeDisplayOnlyFormsAllowedToUser,
}) => {
  const styles = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

  const isForms = title.includes("formularz");

  const [open, setOpen] = useState(false);
  const timer = useRef(-1);

  const setOpenByTimer = (isOpen) => {
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setOpen(isOpen);
    }, 200);
  };

  const MyPopper = function (props) {
    const addAllClick = (e) => {
      clearTimeout(timer.current);
      options.map(({ label }) => changeSelected(label, true));
    };
    const clearClick = (e) => {
      clearTimeout(timer.current);
      isForms && changeFormStatusSelected("none");
      options.map(({ label }) => changeSelected(label, false));
    };
    return (
      <Popper {...props} className={styles.popper}>
        <IconButton
          aria-label="close"
          size="small"
          onClick={() => setOpenByTimer(false)}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container direction="column" justify="center" alignItems="center">
          {isForms && (
            <div>
              <FormControl
                className={styles.formControl}
                variant="outlined"
                size="small"
              >
                <InputLabel
                  id="status-select-label"
                  className={styles.formControl}
                >
                  Zaznacz wszystkie o statusie
                </InputLabel>
                <Select
                  labelId="open-status-select-label"
                  id="open-status-select"
                  label="Zaznacz wszystkie o statusie"
                  className={styles.formControl}
                  value={formStatus}
                  onChange={({ target: { value } }) => {
                    changeFormStatusSelected(value);
                  }}
                  onOpen={(e) => {
                    setOpenByTimer(true);
                  }}
                  onClose={(obj, reason) => {
                    setOpenByTimer(false);
                  }}
                >
                  <MenuItem value="none" style={{ color: "grey" }}>
                    Bez filtrów
                  </MenuItem>
                  <MenuItem value="ACTIVE">Aktywny</MenuItem>
                  <MenuItem value="PAUSED">Wstrzymany</MenuItem>
                  <MenuItem value="DRAFT">Szkic</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <ButtonGroup
            color="primary"
            size="small"
            aria-label="outlined primary button group"
          >
            <Button color="primary" onClick={addAllClick}>
              Zaznacz wszystkie
            </Button>
            <Button color="primary" onClick={clearClick}>
              Odznacz wszystkie
            </Button>
          </ButtonGroup>
          {props.children}
          {isForms && (
            <div>
              <FormControlLabel
                className={styles.formLabel}
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={displayIndividualSchedule}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      changeDisplayIndividualSchedule(
                        !displayIndividualSchedule
                      );
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Pokaż indywidualne harmonogramy"
              />
              <FormControlLabel
                className={styles.formLabel}
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={displayOnlyFormsAllowedToUser}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      changeDisplayOnlyFormsAllowedToUser(
                        !displayOnlyFormsAllowedToUser
                      );
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Pokaż tylko formularze do których użytkownik należy"
              />
            </div>
          )}
        </Grid>
      </Popper>
    );
  };

  return (
    <span>
      <Autocomplete
        PopperComponent={multiple ? MyPopper : Popper}
        size="small"
        multiple={multiple}
        disableCloseOnSelect
        disableClearable
        fullWidth
        value={
          multiple
            ? options.filter((opt) => opt.selected === true)
            : options.find((opt) => opt.selected === true) || null
        }
        options={options}
        getOptionLabel={(option) => option.text}
        getOptionSelected={(option, value) => option.label === value.label}
        renderOption={(option) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              value={option.selected ? option.text : ""}
              checked={option.selected}
              onChange={(e) => {
                isForms && changeFormStatusSelected("none");
                changeSelected(option.label, e.target.checked);
              }}
            />
            {option.text}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={title}
            placeholder={placeholder}
            InputLabelProps={{
              style: { zIndex: 0 },
            }}
          />
        )}
        onChange={(event, value, reason) => {
          if (reason === "remove-option") {
            options.map((opt) => {
              if (
                opt.selected !== false &&
                !value.find(({ label }) => label === opt.label)
              ) {
                changeSelected(opt.label, false);
              }
            });
          }
        }}
        onOpen={(e) => {
          setOpenByTimer(true);
        }}
        onClose={(obj, reason) => {
          setOpenByTimer(false);
        }}
        open={open}
      />
    </span>
  );
};

export default TableDisplayFilters;
