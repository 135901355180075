// noinspection ES6UnusedImports

import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../redux/common";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import {
  CssBaseline,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Paper,
  Tabs,
  Box,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./login";
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../components/loader";
import UsersTableForAdmin from "./persons-organizations/index";
import Register from "./register-organization";
import UserAllFilledForms from "../pages/user-all-filled-forms";
import { UserFilledFormData } from "../components/filled-form-data/user-filled-form-data";
import FilledFormDetails from "../pages/filled-form-details";
import { checkIdentityAdmin, logoutAdmin } from "../redux/admin/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/*
 function CenteredTabs(props) {
 const classes = useStyles();

 const [value, setValue] = React.useState(props.value);

 if (value != props.value) {
 setValue(props.value);
 }

 return (
 <Paper className={classes.root}>
 <Tabs
 value={value}
 indicatorColor="primary"
 textColor="primary"
 centered
 >
 <Tab component={RouterLink} label="Użytkownicy" to="/owner-panel/tenants"  />
 <Tab component={RouterLink} label="Zamówienia" to="/owner-panel/orders"  />
 </Tabs>
 </Paper>
 );
 }
 */

function CenteredTabs() {
  const classes = useStyles();

  const location = useLocation();

  return (
    <Paper className={classes.root}>
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          component={RouterLink}
          label="Rejestracja"
          value="/admin-panel/register"
          to="/admin-panel/register"
        />
        <Tab
          component={RouterLink}
          label="Użytkownicy"
          value="/admin-panel/tenants"
          to="/admin-panel/tenants"
        />
      </Tabs>
    </Paper>
  );
}

function Main(props) {
  const { path } = useRouteMatch();
  const { userState } = props;
  const [user, setUser] = userState;
  const classes = useStyles();
  const dispatch = useDispatch();

  async function logout() {
    try {
      await dispatch(logoutAdmin(() => setUser(null)));
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Panel administracyjny
            </Typography>
            <Button color="inherit" onClick={logout}>
              Wyloguj się
            </Button>
          </Toolbar>
        </AppBar>
      </div>
      <CenteredTabs />
      <Box pt={0.1}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/register`} />
          </Route>
          <Route path={`${path}/register`}>
            <Register />
          </Route>
          <Route path={`${path}/tenants`}>
            <UsersTableForAdmin />
          </Route>
          <Route
            path={`${path}/filled-forms/all/user/:personId`}
            component={UserAllFilledForms}
          />
          <Route
            path={`${path}/formdata/all/:formId/:personId`}
            component={UserFilledFormData}
          />
          <Route
            path={`${path}/formdata/:formDataId`}
            component={FilledFormDetails}
          />
        </Switch>
      </Box>
    </>
  );
}

function Protected(props) {
  const { userState } = props;
  const [user, setUser] = userState;
  const dispatch = useDispatch();

  async function checkLogin() {
    if (user === undefined) {
      try {
        const authResult = await getAxiosInstance().get(
          "/api/auth/check-owner"
        );
        await dispatch(checkIdentityAdmin());
        setUser(authResult.data);
      } catch (e) {
        setUser(null);
      }
    }
  }

  useEffect(() => {
    checkLogin();
  });

  if (user === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (user === null) {
    return <Redirect to="/admin-panel/login" />;
  } else {
    return <Main userState={userState} {...props} />;
  }
}

function SuperAdminApp() {
  const userState = useState();
  const [user, setUser] = userState;
  return (
    <>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        <Router>
          <Switch>
            <Route exact path="/admin-panel/login">
              <LoginForm onUserChange={setUser} />
            </Route>
            <Route path="/admin-panel">
              <Protected userState={userState} />
            </Route>
          </Switch>
        </Router>
      </DndProvider>
    </>
  );
}

export default SuperAdminApp;
