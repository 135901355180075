export const ACTION_1 = "ACTION_1";

export const START = "_START";
export const DONE = "_DONE";
export const FAILED = "_FAILED";

export const REGISTER_ORGANIZATION_START = "REGISTER_ORGANIZATION_START";
export const REGISTER_ORGANIZATION_DONE = "REGISTER_ORGANIZATION_DONE";
export const REGISTER_ORGANIZATION_FAILED = "REGISTER_ORGANIZATION_FAILED";

export const JOIN_ORGANIZATION_START = "JOIN_ORGANIZATION_START";
export const JOIN_ORGANIZATION_DONE = "JOIN_ORGANIZATION_DONE";
export const JOIN_ORGANIZATION_FAILED = "JOIN_ORGANIZATION_FAILED";

export const FETCH_ORGANIZATION_START = "FETCH_ORGANIZATION_START";
export const FETCH_ORGANIZATION_DONE = "FETCH_ORGANIZATION_DONE";
export const FETCH_ORGANIZATION_FAILED = "FETCH_ORGANIZATION_FAILED";

export const FETCH_MY_ORGANIZATIONS_START = "FETCH_MY_ORGANIZATIONS_START";
export const FETCH_MY_ORGANIZATIONS_DONE = "FETCH_MY_ORGANIZATIONS_DONE";
export const FETCH_MY_ORGANIZATIONS_FAILED = "FETCH_MY_ORGANIZATIONS_FAILED";

export const FETCH_CONFIG_GLOBAL_START = "FETCH_CONFIG_GLOBAL_START";
export const FETCH_CONFIG_GLOBAL_DONE = "FETCH_CONFIG_GLOBAL_DONE";
export const FETCH_CONFIG_GLOBAL_FAILED = "FETCH_CONFIG_GLOBAL_FAILED";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_DONE = "LOGIN_DONE";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_DONE = "LOGOUT_DONE";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const CHECK_AUTH_START = "CHECK_AUTH_START";
export const CHECK_AUTH_DONE = "CHECK_AUTH_DONE";
export const CHECK_AUTH_FAILED = "CHECK_AUTH_FAILED";

export const FETCH_ORG_USERS_START = "FETCH_ORG_USERS_START";
export const FETCH_ORG_USERS_DONE = "FETCH_ORG_USERS_DONE";
export const FETCH_ORG_USERS_FAILED = "FETCH_ORG_USERS_FAILED";

export const FETCH_ALL_ORG_USERS_START = "FETCH_ALL_ORG_USERS_START";
export const FETCH_ALL_ORG_USERS_DONE = "FETCH_ALL_ORG_USERS_DONE";
export const FETCH_ALL_ORG_USERS_FAILED = "FETCH_ALL_ORG_USERS_FAILED";

export const FETCH_ORG_NAMES_START = "FETCH_ORG_NAMES_START";
export const FETCH_ORG_NAMES_DONE = "FETCH_ORG_NAMES_DONE";
export const FETCH_ORG_NAMES_FAILED = "FETCH_ORG_NAMES_FAILED";

export const ADD_PERSON = "ADD_PERSON";

export const ADD_EXISTING_PERSON = "ADD_EXISTING_PERSON";

export const INVITE_MULTIPLE_PEOPLE_START = "INVITE_MULTIPLE_PEOPLE_START";
export const INVITE_MULTIPLE_PEOPLE_DONE = "INVITE_MULTIPLE_PEOPLE_DONE";
export const INVITE_MULTIPLE_PEOPLE_FAILED = "INVITE_MULTIPLE_PEOPLE_FAILED";

export const SAVE_FORM_START = "SAVE_FORM_START";
export const SAVE_FORM_DONE = "SAVE_FORM_DONE";
export const SAVE_FORM_FAILED = "SAVE_FORM_FAILED";

export const UPDATE_FORM_START = "UPDATE_FORM_START";
export const UPDATE_FORM_DONE = "UPDATE_FORM_DONE";
export const UPDATE_FORM_FAILED = "UPDATE_FORM_FAILED";

export const SAVE_FORM_GROUP_START = "SAVE_FORM_GROUP_START";
export const SAVE_FORM_GROUP_DONE = "SAVE_FORM_GROUP_DONE";
export const SAVE_FORM_GROUP_FAILED = "SAVE_FORM_GROUP_FAILED";

export const REMOVE_FORM_GROUP = "REMOVE_FORM_GROUP";
export const DUPLICATE_FORM_GROUP = "DUPLICATE_FORM_GROUP";

export const UPDATE_FORM_GROUP_START = "UPDATE_FORM_GROUP_START";
export const UPDATE_FORM_GROUP_DONE = "UPDATE_FORM_GROUP_DONE";
export const UPDATE_FORM_GROUP_FAILED = "UPDATE_FORM_GROUP_FAILED";

export const FETCH_FORMS_START = "FETCH_FORMS_START";
export const FETCH_FORMS_DONE = "FETCH_FORMS_DONE";
export const FETCH_FORMS_FAILED = "FETCH_FORMS_FAILED";

export const FETCH_FORMS_GROUPS_START = "FETCH_FORMS_GROUPS_START";
export const FETCH_FORMS_GROUPS_DONE = "FETCH_FORMS_GROUPS_DONE";
export const FETCH_FORMS_GROUPS_FAILED = "FETCH_FORMS_GROUPS_FAILED";

export const FETCH_ALL_FORMS_GROUPS_START = "FETCH_ALL_FORMS_GROUPS_START";
export const FETCH_ALL_FORMS_GROUPS_DONE = "FETCH_ALL_FORMS_GROUPS_DONE";
export const FETCH_ALL_FORMS_GROUPS_FAILED = "FETCH_ALL_FORMS_GROUPS_FAILED";

export const FETCH_ALLOWED_USERS_TO_GROUP_START =
  "FETCH_ALLOWED_USERS_TO_GROUP_START";
export const FETCH_ALLOWED_USERS_TO_GROUP_DONE =
  "FETCH_ALLOWED_USERS_TO_GROUP_DONE";
export const FETCH_ALLOWED_USERS_TO_GROUP_FAILED =
  "FETCH_ALLOWED_USERS_TO_GROUP_FAILED";

export const FETCH_ALLOWED_USERS_TO_FORM_START =
  "FETCH_ALLOWED_USERS_TO_FORM_START";
export const FETCH_ALLOWED_USERS_TO_FORM_DONE =
  "FETCH_ALLOWED_USERS_TO_FORM_DONE";
export const FETCH_ALLOWED_USERS_TO_FORM_FAILED =
  "FETCH_ALLOWED_USERS_TO_FORM_FAILED";

export const FETCH_SCHEDULED_FORMS = "FETCH_SCHEDULED_FORMS";

export const REMOVE_FORM_START = "REMOVE_FORM_START";
export const REMOVE_FORM_DONE = "REMOVE_FORM_DONE";
export const REMOVE_FORM_FAILED = "REMOVE_FORM_FAILED";

export const FETCH_FORM_START = "FETCH_FORM_START";
export const FETCH_FORM_DONE = "FETCH_FORM_DONE";
export const FETCH_FORM_FAILED = "FETCH_FORM_FAILED";

export const FETCH_FORM_GROUP_START = "FETCH_FORM_GROUP";
export const FETCH_FORM_GROUP_DONE = "FETCH_FORM_DONE";
export const FETCH_FORM_GROUP_FAILED = "FETCH_FORM_FAILED";

export const FETCH_GLOBAL_DICTIONARIES_START =
  "FETCH_GLOBAL_DICTIONARIES_GROUP";
export const FETCH_GLOBAL_DICTIONARIES_DONE = "FETCH_GLOBAL_DICTIONARIES_DONE";
export const FETCH_GLOBAL_DICTIONARIES_FAILED =
  "FETCH_GLOBAL_DICTIONARIES_FAILED";

export const COPY_FORM_TO_FORM_GROUP_START = "COPY_FORM_TO_FORM_GROUP_START";
export const COPY_FORM_TO_FORM_GROUP_DONE = "COPY_FORM_TO_FORM_GROUP__DONE";
export const COPY_FORM_TO_FORM_GROUP_FAILED = "COPY_FORM_TO_FORM_GROUP_FAILED";

export const REMOVE_FORM_FROM_FORM_GROUP_START =
  "REMOVE_FORM_FROM_FORM_GROUP_START";
export const REMOVE_FORM_FROM_FORM_GROUP_DONE =
  "REMOVE_FORM_FROM_FORM_GROUP__DONE";
export const REMOVE_FORM_FROM_FORM_GROUP_FAILED =
  "REMOVE_FORM_FROM_FORM_GROUP_FAILED";

export const MOVE_FORM_TO_FORM_GROUP_START = "MOVE_FORM_TO_FORM_GROUP_START";
export const MOVE_FORM_TO_FORM_GROUP_DONE = "MOVE_FORM_TO_FORM_GROUP__DONE";
export const MOVE_FORM_TO_FORM_GROUP_FAILED = "MOVE_FORM_TO_FORM_GROUP_FAILED";

export const FETCH_FORM_TEMPLATE_START = "FETCH_FORM_TEMPLATE_START";
export const FETCH_FORM_TEMPLATE_DONE = "FETCH_FORM_TEMPLATE_DONE";
export const FETCH_FORM_TEMPLATE_FAILED = "FETCH_FORM_TEMPLATE_FAILED";

export const CREATE_FILLED_FORM_DATA_START = "CREATE_FILLED_FORM_DATA_START";
export const CREATE_FILLED_FORM_DATA_DONE = "CREATE_FILLED_FORM_DATA_DONE";
export const CREATE_FILLED_FORM_DATA_FAILED = "CREATE_FILLED_FORM_DATA_FAILED";

export const SAVE_FILLED_FORM_DATA_START = "SAVE_FILLED_FORM_DATA_START";
export const SAVE_FILLED_FORM_DATA_DONE = "SAVE_FILLED_FORM_DATA_DONE";
export const SAVE_FILLED_FORM_DATA_FAILED = "SAVE_FILLED_FORM_DATA_FAILED";

export const EDIT_FILLED_FORM_DATA_START = "EDIT_FILLED_FORM_DATA_START";
export const EDIT_FILLED_FORM_DATA_DONE = "EDIT_FILLED_FORM_DATA_DONE";
export const EDIT_FILLED_FORM_DATA_FAILED = "EDIT_FILLED_FORM_DATA_FAILED";

export const FETCH_FILLED_FORMS_START = "FETCH_FILLED_FORMS_START";
export const FETCH_FILLED_FORMS_DONE = "FETCH_FILLED_FORMS_DONE";
export const FETCH_FILLED_FORMS_FAILED = "FETCH_FILLED_FORMS_FAILED";

export const FETCH_FILLED_FORMS_EXTENDED = "FETCH_FILLED_FORMS_EXTENDED";

export const RECOVER_PASSWORD_START = "RECOVER_PASSWORD_START";
export const RECOVER_PASSWORD_DONE = "RECOVER_PASSWORD_DONE";
export const RECOVER_PASSWORD_FAILED = "RECOVER_PASSWORD_FAILED";

export const RESET_BY_MAIL_PASSWORD_START = "RESET_BY_MAIL_PASSWORD_START";
export const RESET_BY_MAIL_PASSWORD_DONE = "RESET_BY_MAIL_PASSWORD_DONE";
export const RESET_BY_MAIL_PASSWORD_FAILED = "RESET_BY_MAIL_PASSWORD_FAILED";

export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_HIDE = "ALERT_HIDE";
export const ALERT_TIMEOUT = "ALERT_TIMEOUT";
export const ALERT_HIDE_CUSTOM_ID = "ALERT_HIDE_CUSTOM_ID";

export const SEND_COMMENT_START = "SEND_COMMENT_START";
export const SEND_COMMENT_DONE = "SEND_COMMENT_DONE";
export const SEND_COMMENT_FAILED = "SEND_COMMENT_FAILED";

export const FETCH_ACTIVE_FORMS = "FETCH_ACTIVE_FORMS";
export const FETCH_ACTIVE_FORMS_ALL = "FETCH_ACTIVE_FORMS_ALL";
export const FETCH_USER_ALL_FORMS = "FETCH_USER_ALL_FORMS";
export const FETCH_USER_ALL_AVAILABLE_FORMS = "FETCH_USER_ALL_AVAILABLE_FORMS";
export const FETCH_PLANNED_FORMS = "FETCH_PLANNED_FORMS";
export const FETCH_PLANNED_FORMS_ALL = "FETCH_PLANNED_FORMS_ALL";
export const FETCH_ARCHIVED_FORMS = "FETCH_ARCHIVED_FORMS";
export const FETCH_ARCHIVED_FORMS_BAG = "FETCH_ARCHIVED_FORMS_BAG";
export const FETCH_SAVED_FORM_DATA = "FETCH_SAVED_FORM_DATA";
export const FETCH_FILLED_FORM_DATA = "FETCH_FILLED_FORM_DATA";
export const REMOVE_FORM_DATA_DRAFT = "REMOVE_FORM_DATA_DRAFT";
export const ACTIVATE_FORM = "ACTIVATE_FORM";

export const REMOVE_USER = "REMOVE_USER";
export const EDIT_USER = "EDIT_USER";
export const FETCH_MATCHING_USERS = "FETCH_MATCHING_USERS";
export const SEARCH_USER_GLOBAL = "SEARCH_USER_GLOBAL";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const UPDATE_ENTITLED_USERS = "UPDATE_ENTITLED_USERS";
export const UPDATE_USERS_ACCESS_TO_GROUP = "UPDATE_USERS_ACCESS_TO_GROUP";
export const UPDATE_USERS_ACCESS_TO_FORM = "UPDATE_USERS_ACCESS_TO_FORM";
export const DUPLICATE_FORM = "DUPLICATE_FORM";
export const DEACTIVATE_FORM = "DEACTIVATE_FORM";
export const FETCH_FORM_EDITIONS = "FETCH_FORM_EDITIONS";
export const FETCH_OLD_FORM_EDITIONS = "FETCH_OLD_FORM_EDITIONS";

export const REMOVE_CART_ITEM_START = "REMOVE_CART_ITEM_START";
export const REMOVE_CART_ITEM_DONE = "REMOVE_CART_ITEM_DONE";
export const REMOVE_CART_ITEM_FAILED = "REMOVE_CART_ITEM_FAILED";

export const BUY_CART_ITEM = "BUY_CART_ITEM";

export const REMOVE_TERM_START = "REMOVE_TERM_START";
export const REMOVE_TERM_DONE = "REMOVE_TERM_DONE";
export const REMOVE_TERM_FAILED = "REMOVE_TERM_FAILED";

export const ACCEPT_TERMS_START = "ACCEPT_TERMS_START";
export const ACCEPT_TERMS_DONE = "ACCEPT_TERMS_DONE";
export const ACCEPT_TERMS_FAILED = "ACCEPT_TERMS_FAILED";
export const ACCEPT_FUTURE_TERMS = "ACCEPT_FUTURE_TERMS";
export const REVOKE_TERMS = "REVOKE_TERMS";
export const REVOKE_FUTURE_TERMS = "REVOKE_FUTURE_TERMS";

export const FETCH_TERMS_ACCEPTED_BY_USER = "FETCH_TERMS_ACCEPTED_BY_USER";
export const FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID =
  "FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID";

export const FETCH_DATA_AS_CSV = "FETCH_DATA_AS_CSV";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const EDIT_ITEM_CART = "EDIT_ITEM_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const FETCH_PRICE_LIST = "FETCH_PRICE_LIST";

export const X_AUTH_TOKEN_KEY = "x-auth-token";

export const FETCH_ALL_MY_TESTS = "FETCH_ALL_MY_TESTS";
export const FETCH_FILLED_TESTS = "FETCH_FILLED_TESTS";
export const FETCH_ACTIVATED_KITS = "FETCH_ACTIVATED_KITS";
export const UPDATE_REQUESTED_SHARING_BY_OWNER =
  "UPDATE_REQUESTED_SHARING_BY_OWNER";
export const TOGGLE_REQUESTED_SHARING_BY_OWNER =
  "TOGGLE_REQUESTED_SHARING_BY_OWNER";
export const UPDATE_FORM_DATA_IS_SHARED = "UPDATE_FORM_DATA_IS_SHARED";

export const SET_APP_BAR_TITLE = "SET_APP_BAR_TITLE";
export const FETCH_ORGANIZATION_TERMS = "FETCH_ORGANIZATION_TERMS";
export const FETCH_ORGANIZATION_CURRENT_TERMS =
  "FETCH_ORGANIZATION_CURRENT_TERMS";
export const FETCH_ORGANIZATION_FUTURE_TERMS =
  "FETCH_ORGANIZATION_FUTURE_TERMS";
export const FETCH_ORDER_DETAILS_BY_ORDER_ID =
  "FETCH_ORDER_DETAILS_BY_ORDER_ID";
export const FETCH_FORM_DATA = "FETCH_FORM_DATA";
export const FETCH_UPLOADABLE_FILE_TYPES = "FETCH_UPLOADABLE_FILE_TYPES";
export const FETCH_FORMS_SCHEDULED_FOR_FUTURE =
  "FETCH_FORMS_SCHEDULED_FOR_FUTURE";
export const RESET_GIVEN_USER_PASSWORD = "RESET_GIVEN_USER_PASSWORD";

export const UPLOAD_FORM_DATA_FILE = "UPLOAD_FORM_DATA_FILE";
export const FETCH_API_KEYS = "FETCH_API_KEYS";
export const ADD_API_KEY = "ADD_API_KEY";
export const DELEETE_API_KEY = "DELEETE_API_KEY";

export const FETCH_SCHEDULED_FORM = "FETCH_SCHEDULED_FORM";
export const FETCH_HAS_SINGLE_SCHEDULED_FORM =
  "FETCH_HAS_SINGLE_SCHEDULED_FORM";
export const TOGGLE_GLOBAL_THEME = "TOGGLE_GLOBAL_THEME";
export const READ_GLOBAL_THEME = "READ_GLOBAL_THEME";
export const SMALL_FONT_SIZE = "SMALL_FONT_SIZE";
export const MEDIUM_FONT_SIZE = "MEDIUM_FONT_SIZE";
export const LARGE_FONT_SIZE = "LARGE_FONT_SIZE";
export const FETCH_CONFIG = "FETCH_CONFIG";
export const CLEAR_ALL_FORM = "CLEAR_ALL_FORM";
export const FETCH_API_VERSION = "FETCH_API_VERSION";

export const UNSUPPORTED_BROWSER = "UNSUPPORTED_BROWSER";

export const FETCH_AVAILABLE_EDITORS = "FETCH_AVAILABLE_EDITORS";
export const FETCH_AVAILABLE_EDITORS_WITH_VISIT =
  "FETCH_AVAILABLE_EDITORS_WITH_VISIT";
export const FETCH_AVAILABLE_VISITS = "FETCH_AVAILABLE_VISITS";
export const FETCH_CALENDAR_EVENTS = "FETCH_CALENDAR_EVENTS";

export const SWITCH_ORGANIZATION_CONTEXT = "SWITCH_ORGANIZATION_CONTEXT";
