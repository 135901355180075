import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";

import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import format from "date-fns/format";

import Loader from "../../components/loader";
import { UsersDataTable } from "../../components/users-data-table";
import { roleToDisplay } from "../../components/users-data-table";
import { getAxiosInstance } from "../../redux/common";
import { fetchAllowedUsersToForm } from "../../redux/actions";

const useStyles = makeStyles(() => ({
  tableCellPadding: {
    padding: "6px 8px",
  },
  loading: {
    position: "relative",
    height: 0,
    "& > :first-child": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      border: 0,
    },
  },
  dot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    marginRight: "4px",
    boxSizing: "border-box",
  },
  bold: {
    fontWeight: "bold",
  },
  boxSpan: {
    justifyItems: "center",
    display: "flex",
    alignItems: "center",
  },
  buttonLink: {
    "&.MuiButton-containedPrimary:hover": {
      color: "#ffffff",
    },
  },
}));

const Dot = ({ color }) => {
  const styles = useStyles();
  let text = "";
  switch (color) {
    case "red":
      text = "Ten formularz jest przedawniony.";
      break;
    case "blue":
      text = "Ten formularz jest zaplanowany na przyszłość.";
      break;
    case "green":
      text = "Ten formularz ma aktualnie obowiązujący harmonogram.";
      break;

    default:
      break;
  }

  return (
    <Tooltip title={text}>
      <Box className={styles.dot} style={{ backgroundColor: color }} />
    </Tooltip>
  );
};

const checkFormSchedule = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const now = new Date();

  if (start < now && now < end) {
    return "green";
  } else if (start > now) {
    return "blue";
  } else if (end < now) {
    return "red";
  }
};

const UsersTable = ({
  forAdmin = false,
  fetchAllowedUsersToForm,
  orgForms,
  orgFormsGroups,
  displayForms,
  displayGroups,
  userDataColumnOptions,
  formsOptions,
  groupsOptions,
  ready,
  setReady,
  page,
  setPage,
  pageSize,
  setPageSize,
  displayOnlyFormsAllowedToUser,
  displayIndividualSchedule,
  totalCount,
  organizationsOptions,
}) => {
  const users = useSelector((s) => s.allUsers);
  const displayOnlyUsersData = !displayForms && !displayGroups;
  const [usersAllowedToGroups, setUsersAllowedToGroups] = useState([]);
  const [usersAllowedToForms, setUsersAllowedToForms] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [formsAndGroupsColumns, setFormsAndGroupsColumns] = useState();

  let initialColumn = [{ id: "col-id-user-data", label: "Dane użytkownika" }];
  const styles = useStyles();

  const isFieldSelected = (field, options) => {
    return options.find((option) => option.label === field).selected;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    localStorage.setItem("pageSize", parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    const fetchAllowedUsersToForms = async () => {
      if (formsOptions.length > 0) {
        setReady(false);
        let usersAllowedToFormsTmp = [...usersAllowedToForms];
        for (const form of formsOptions) {
          if (
            isFieldSelected(form.label, formsOptions) &&
            !usersAllowedToFormsTmp.find(({ formId }) => formId === form.label)
          ) {
            let usersAllowedToForm = await fetchAllowedUsersToForm(form.label);
            usersAllowedToFormsTmp.push({
              formId: form.label,
              allowedUsers: usersAllowedToForm,
            });
          }
        }
        setUsersAllowedToForms(usersAllowedToFormsTmp);
        setReady(true);
      }
    };
    displayForms && fetchAllowedUsersToForms();
  }, [formsOptions, displayForms]);

  useEffect(() => {
    const fetchAllowedUsersToGroups = async () => {
      if (groupsOptions.length > 0) {
        setReady(false);
        let usersAllowedToGroupsTmp = [...usersAllowedToGroups];
        for (const group of groupsOptions) {
          if (
            isFieldSelected(group.label, groupsOptions) &&
            !usersAllowedToGroupsTmp.find(
              ({ groupId }) => groupId === group.label
            )
          ) {
            const usersAllowedToGroup = (
              await getAxiosInstance().get(
                `/api/form_group/${group.label}/allowed_users`
              )
            ).data;

            usersAllowedToGroupsTmp.push({
              groupId: group.label,
              allowedUsers: usersAllowedToGroup,
            });
          }
        }
        setUsersAllowedToGroups(usersAllowedToGroupsTmp);
        setReady(true);
      }
    };
    displayGroups && fetchAllowedUsersToGroups();
  }, [groupsOptions, displayGroups]);

  useEffect(() => {
    isFieldSelected("results", userDataColumnOptions) &&
      initialColumn.push({ id: "col-id-results", label: "Wyniki" });
    isFieldSelected("terms", userDataColumnOptions) &&
      initialColumn.push({
        id: "col-id-terms",
        label: "Zaakceptowany regulamin?",
      });
    let additionalColumns = [];
    groupsOptions.forEach((option) => {
      if (option.selected && displayGroups) {
        additionalColumns.push({
          id: option.label,
          label: "Nazwa projektu",
        });
      }
      displayForms &&
        orgForms.find(
          ({ formGroupId, id }) =>
            formGroupId === option.label &&
            formsOptions.find(
              ({ label, selected }) => label === id && selected
            ) !== undefined
        ) &&
        additionalColumns.push({
          id: `${option.label}-forms`,
          label: `Formularze projektu ${option.text}`,
        });
    });
    setFormsAndGroupsColumns(additionalColumns);
    setAllColumns([...initialColumn, ...additionalColumns]);
  }, [
    displayGroups,
    displayForms,
    userDataColumnOptions,
    groupsOptions,
    formsOptions,
  ]);

  const allowed = () => (
    <Tooltip title="Użytkownik należy do tego projektu/formularza">
      <DoneIcon
        size="small"
        style={{
          color: "green",
        }}
      />
    </Tooltip>
  );
  const notAllowed = () => (
    <Tooltip title="Użytkownik NIE należy do tego projektu/formularza">
      <ClearIcon
        size="small"
        style={{
          color: "red",
        }}
      />
    </Tooltip>
  );

  const acceptedTerms = (accepted) => {
    if (accepted) {
      return (
        <DoneIcon
          size="small"
          style={{
            color: "green",
          }}
        />
      );
    } else if (accepted === null) {
      return <span>Brak</span>;
    } else {
      return (
        <ClearIcon
          size="small"
          style={{
            color: "red",
          }}
        />
      );
    }
  };

  const Row = ({ person }) => {
    const history = useHistory();
    const {
      id,
      firstName,
      surname,
      mail,
      phone,
      gender,
      address,
      yearOfBirth,
      user,
      currentTermsAccepted,
      organizationId,
    } = person;

    const displayRemovedOrData = () => {
      return !!user.removedAt ? (
        <div>{"<usunięty>"}</div>
      ) : (
        <div>
          {isFieldSelected("firstName", userDataColumnOptions) && firstName && (
            <span className={styles.bold}>{`${firstName} `}</span>
          )}
          {isFieldSelected("surname", userDataColumnOptions) && surname && (
            <span className={styles.bold}>{surname}</span>
          )}
          {isFieldSelected("mail", userDataColumnOptions) && mail && (
            <div>{mail}</div>
          )}
          {isFieldSelected("user.phone", userDataColumnOptions) &&
            phone &&
            phone.length > 3 && <div>{phone}</div>}
        </div>
      );
    };

    return (
      <TableRow key={id}>
        <TableCell className={styles.tableCellPadding}>
          <div>
            {displayRemovedOrData()}
            {isFieldSelected("user.authorities", userDataColumnOptions) && (
              <div>{roleToDisplay(user.authorities)}</div>
            )}
            {isFieldSelected("orgId", userDataColumnOptions) && (
              <div>
                {`Organizacja: ${
                  organizationsOptions.find(
                    ({ label }) => label === organizationId
                  )?.text || ""
                }`}
              </div>
            )}
            {isFieldSelected("gender", userDataColumnOptions) && gender && (
              <div>{gender === "Female" ? "Kobieta" : "Mężczyzna"}</div>
            )}
            {isFieldSelected("yearOfBirth", userDataColumnOptions) &&
              yearOfBirth && <div>{`ur. ${yearOfBirth}`}</div>}
            {isFieldSelected("city", userDataColumnOptions) &&
              address &&
              address.city && <div>{address.city}</div>}
            {isFieldSelected("street", userDataColumnOptions) &&
              address &&
              address.street && <div>{address.street}</div>}
            {isFieldSelected("zipCode", userDataColumnOptions) &&
              address &&
              address.zipCode && <div>{address.zipCode}</div>}
          </div>
        </TableCell>
        {isFieldSelected("results", userDataColumnOptions) && (
          <TableCell className={styles.tableCellPadding}>
            {user.authorities === "ROLE_USER" && (
              <NavLink
                to={{
                  pathname: `${
                    forAdmin ? "/admin-panel" : ""
                  }/filled-forms/all/user/${person.id}`,
                  state: { orgId: person.organizationId },
                }}
              >
                <Button
                  variant={"contained"}
                  size="small"
                  color="primary"
                  className={styles.buttonLink}
                >
                  Pokaż wyniki
                </Button>
              </NavLink>
            )}
          </TableCell>
        )}
        {isFieldSelected("terms", userDataColumnOptions) && (
          <TableCell>
            {user.authorities === "ROLE_USER"
              ? acceptedTerms(currentTermsAccepted)
              : ""}
          </TableCell>
        )}
        {formsAndGroupsColumns.map((col) => {
          let dataToRender = null;
          if (col.id.endsWith("-forms") && displayForms) {
            const groupId = col.id.slice(0, col.id.length - 6);
            const forms = orgForms.filter(({ formGroupId }) => {
              return formGroupId === groupId;
            });
            dataToRender = forms
              .filter(({ id }) => isFieldSelected(id, formsOptions))
              .map((form, index) => {
                const userAllowedToForm = usersAllowedToForms
                  .find(({ formId }) => formId === form.id)
                  ?.allowedUsers.find(
                    ({ userId, selected }) => userId === id && selected === true
                  );
                const color =
                  form.scheduleStartDate && form.scheduleEndDate
                    ? checkFormSchedule(
                        form.scheduleStartDate,
                        form.scheduleEndDate
                      )
                    : null;
                return (displayOnlyFormsAllowedToUser && userAllowedToForm) ||
                  user.authorities.includes("ROLE_OWNER") ||
                  !displayOnlyFormsAllowedToUser ? (
                  <React.Fragment key={index}>
                    {index !== 0 && <Divider />}
                    <Grid
                      container
                      direction="row"
                      justify="start"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>{color && <Dot color={color} />}</Grid>
                      <Grid item>
                        <span>{form.name}</span>
                      </Grid>
                      <Grid item>
                        {!(user.authorities.indexOf("ROLE_OWNER") !== -1)
                          ? userAllowedToForm
                            ? allowed()
                            : notAllowed()
                          : null}
                      </Grid>
                      <Grid item>
                        {displayIndividualSchedule &&
                          userAllowedToForm?.validSince &&
                          `od ${format(
                            new Date(userAllowedToForm.validSince),
                            "yyyy-MM-dd"
                          )} `}
                        {displayIndividualSchedule &&
                          userAllowedToForm?.validTo &&
                          `do ${format(
                            new Date(userAllowedToForm.validTo),
                            "yyyy-MM-dd"
                          )}`}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null;
              });
          } else {
            const foundedGroup = orgFormsGroups.find(
              (group) => group.id === col.id
            );
            if (
              foundedGroup &&
              isFieldSelected(foundedGroup.id, groupsOptions)
            ) {
              const isUserAllowedToGroup = usersAllowedToGroups
                .find(({ groupId }) => groupId === col.id)
                ?.allowedUsers.find((userId) => userId === id);
              dataToRender = (
                <Box component="span" className={styles.boxSpan}>
                  {foundedGroup.name}
                  {!(user.authorities.indexOf("ROLE_OWNER") !== -1)
                    ? isUserAllowedToGroup
                      ? allowed()
                      : notAllowed()
                    : null}
                </Box>
              );
            }
          }
          if (dataToRender) {
            return (
              <TableCell key={id.concat(col.id)}>{dataToRender}</TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  return displayOnlyUsersData ? (
    <UsersDataTable
      users={users}
      userDataColumnOptions={userDataColumnOptions}
      ready={ready}
      totalCount={totalCount}
      pageSize={pageSize}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      forAdmin={forAdmin}
      organizationsOptions={organizationsOptions}
    />
  ) : (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead style={{ backgroundColor: "#dcdcdc" }}>
          <TableRow>
            {allColumns.map((col) => {
              return (
                <TableCell key={col.id} className={styles.tableCellPadding}>
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!ready && (
            <TableRow className={styles.loading}>
              <TableCell colSpan={allColumns.length}>
                <Loader loading={true} text="Wczytywanie danych" />
              </TableCell>
            </TableRow>
          )}
          {users?.map((person) => (
            <Row key={person.id} person={person} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={totalCount}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} z ${count}`
              }
              labelRowsPerPage="Liczba użytkowników na stronę:"
              rowsPerPage={pageSize}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllowedUsersToForm: (formId) =>
    dispatch(fetchAllowedUsersToForm(formId)),
});

export default connect(null, mapDispatchToProps)(UsersTable);
