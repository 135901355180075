import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { matchPath } from "react-router";

import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import {
  fetchForms,
  fetchAllFormsGroups,
  fetchOrgUsers,
  fetchArchivedForms,
  fetchAllowedUsersToForm,
  fetchOldFormEditions,
} from "../../redux/actions";
import { checkPermission } from "../../rbac";

const useStyles = makeStyles((theme) => ({
  grey: {
    color: "grey",
  },
  yellow: {
    color: "#fff200",
  },
  fontSize: {
    fontSize: `calc(${theme.typography.body2.fontSize}/5*4)`,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  breadcrumbs: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

//going to these pages starts the breadcrumbs all over again
const MAIN_PAGES = [
  "/persons",
  "/forms/active",
  "/profile",
  "/filled-forms",
  "/forms",
  "/groups",
  "/",
]; // + /results and /results:id
const MAX_CHARS = 25;

const breadcrumbsNameMap = {
  "/forms/active": "Twoje formularze",
  "/forms/add": "Dodawanie formularza",
  "/forms/edit/:formId": (params) => "Edycja formularza " + params.formName,
  "/forms/display/:formId": (params) => "Podgląd formularza " + params.formName,
  "/form_group/add": "Dodawanie projektu",
  "/form_group/edit/:groupId": (params) =>
    "Edycja projektu " + params.groupName,
  "/formdata/add/:formId": (params) =>
    "Wypełnienie formularza " + params.formName,
  "/filled-forms/all/user/:personId": (params) =>
    "Wszystkie formularze użytkownika " + params.personName,
  "/formdata/fill-scheduled-test/:formId/:scheduledFormId": (params) =>
    "Wypełnienie formularza " + params.formName,
  "/formdata/edit/:formId/:formdataId": (params) =>
    "Edycja wypełnienia formularza " + params.formName,
  "/person/add": "Dodawanie użytkownika",
  "/persons": "Członkowie organizacji",
  "/persons/:personId": (params) => "Edycja użytkownika " + params.personName,
  "/formdata/all/:formId": (params) => "Wyniki formularza " + params.formName,
  "/formdata/all/:formId/:personId": (params) =>
    "Wyniki formularza " +
    params.formName +
    " użytkownika " +
    params.personName,
  "/formdata/:formdataId": "Szczegóły wypełnienia",
  "/formdata/z/:formDataId/:invalidateViewAt": "Szczegóły wypełnienia",
  "/results/:formId": (params) =>
    "Wyniki dzienne formularza " + params.formName,
  "/results": "Wyniki dzienne",
  "/profile/api-keys": "Klucze API",
  "/profile": "Profil",
  "/forms": "Formularze",
  "/filled-forms": "Wypełnione formularze",
  "/groups": "Projekty",
  "/calendar/edit": "Edycja kalendarza",
  "/calendar/book-visit": "Rezerwacja wizyty",
  "/calendar": "Kalendarz",
  "/": "Start",
};

const isFunction = (value) => typeof value === "function";

function getRouteMatch(path) {
  return Object.keys(breadcrumbsNameMap)
    .map((key) => {
      const params = matchPath(path, { path: key });
      return {
        didMatch: params !== null && params.isExact === true,
        params,
        key,
      };
    })
    .filter((item) => item.didMatch)[0];
}

const Breadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const globalTheme = useSelector((s) => s.globalTheme);
  const my = useSelector((s) => s.my);
  const roles = useSelector((s) => s.roles);
  const orgForms = useSelector((s) => s.orgForms);
  const orgUsers = useSelector((s) => s.orgUsers);
  const orgFormsGroups = useSelector((s) => s.orgFormsGroups);
  const archivedForms = useSelector((s) => s.archivedForms || []);
  const allowedUsersToForm = useSelector((s) => s.allowedUsersToForm);
  const oldVersions = useSelector((s) => s.orgOldFromEditions);

  const fetchingForms = useSelector((s) => s.fetchingForms);
  const fetchingArchivedForms = useSelector((s) => s.fetchingArchivedForms);
  const orgUsersFetching = useSelector((s) => s.orgUsersFetching);
  const allowedUsersToFormFetching = useSelector(
    (s) => s.allowedUsersToFormFetching
  );

  const orgUsersFetched = useSelector((s) => s.orgUsersFetched);
  const allowedUsersToFormFetched = useSelector(
    (s) => s.allowedUsersToFormFetched
  );
  const formsFetched = useSelector((s) => s.formsFetched);
  const formsGroupsFetched = useSelector((s) => s.formsGroupsFetched);
  const archivedFormsFetched = useSelector((s) => s.archivedFormsFetched);

  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const canFetchOrgUsers = checkPermission("admin:fetchOrgUsers", roles);
  const canFetchOrgForms = checkPermission("forms:fetch", roles);
  const canFetchGroups = checkPermission("groups:fetch", roles);

  const [links, setLinks] = useState(
    localStorage.links ? localStorage.links.split(";") : []
  );
  const [pathsAndNames, setPathsAndNames] = useState([{ path: "", name: "" }]);

  const paramsIdsToNames = (params) => {
    let paramsNames = { formName: "", personName: "", groupName: "" };
    if (params.formId) {
      if (!formsFetched && canFetchOrgForms && !fetchingForms) {
        dispatch(
          fetchForms({
            page: 0,
            pageSize: 100,
          })
        );
      } else if (
        !canFetchOrgUsers &&
        !isUser &&
        !allowedUsersToFormFetching &&
        !allowedUsersToFormFetched
      ) {
        dispatch(fetchAllowedUsersToForm(params.formId));
      }
      if (
        !isUser &&
        (!oldVersions ||
          !oldVersions.find(({ id }) => id === parseInt(params.formId)))
      ) {
        dispatch(fetchOldFormEditions(params.formId));
      }

      if (!archivedFormsFetched && isUser && !fetchingArchivedForms) {
        dispatch(
          fetchArchivedForms({
            page: 0,
            pageSize: 10,
          })
        );
      }
      let form;
      if (!isUser) {
        form = orgForms.find(({ id }) => id === parseInt(params.formId));
        if (form === undefined) {
          const bagId = oldVersions.find(
            ({ id }) => id === parseInt(params.formId)
          )?.bag.id;
          form = oldVersions.find(({ bag }) => bag.id === bagId);
        }
      } else {
        const bagId = archivedForms.find(
          ({ id }) => id === parseInt(params.formId)
        )?.bag.id;
        form = archivedForms
          .filter(({ bag }) => bag.id === bagId)
          .sort((a, b) => b.bag.id - a.bag.id)[0];
      }

      const formName =
        form?.name.length > MAX_CHARS
          ? form?.name.substr(0, MAX_CHARS) + "..."
          : form?.name;
      paramsNames.formName = form ? '"' + formName + '"' : "";
    }
    if (params.personId) {
      if (!orgUsersFetched && canFetchOrgUsers && !orgUsersFetching) {
        dispatch(fetchOrgUsers());
      }
      let person;
      orgUsers.length !== 0
        ? (person = orgUsers.find(({ id }) => id === params.personId))
        : (person = allowedUsersToForm.find(
            ({ userId }) => userId === params.personId
          ));
      paramsNames.personName = person
        ? person.firstName || person.surname
          ? (person.firstName || "") + " " + (person.surname || "")
          : person.mail || ""
        : "";
    }
    if (params.groupId && orgFormsGroups) {
      if (!formsGroupsFetched && canFetchGroups) {
        dispatch(fetchAllFormsGroups());
      }
      const group = orgFormsGroups.find(({ id }) => id === params.groupId);
      paramsNames.groupName = group ? '"' + group.name + '"' : "";
    }
    return paramsNames;
  };

  const mapPathToName = (path) => {
    const routeMatch = getRouteMatch(path);
    const routeValue = breadcrumbsNameMap[routeMatch.key];
    const name = isFunction(routeValue)
      ? routeValue(paramsIdsToNames(routeMatch.params.params))
      : routeValue;
    return name;
  };

  const saveBreadcrumb = (link) => {
    if (
      localStorage.links &&
      !MAIN_PAGES.find((el) => el === link) &&
      !link.startsWith("/results")
    ) {
      let index = links.indexOf(location.pathname);
      if (index === -1) {
        localStorage.links = localStorage.links + ";" + link;
      } else {
        let array = localStorage.links.split(";");
        array.length = index + 1;
        localStorage.links = array.join(";");
      }
    } else {
      localStorage.links = link;
    }
    setLinks(localStorage.links.split(";"));
  };

  useEffect(() => {
    if (
      !fetchingForms &&
      !fetchingArchivedForms &&
      !orgUsersFetching &&
      !allowedUsersToFormFetching
    ) {
      setPathsAndNames(
        links.map((link) => {
          return { path: link, name: mapPathToName(link) };
        })
      );
    }
  }, [
    links,
    fetchingForms,
    fetchingArchivedForms,
    orgUsersFetching,
    allowedUsersToFormFetching,
    oldVersions,
  ]);

  useEffect(() => {
    if (
      !location.pathname.includes("login") &&
      !location.pathname.includes("register")
    ) {
      saveBreadcrumb(location.pathname);
    } else {
      saveBreadcrumb("/");
    }
  }, [location.pathname]);

  return (
    !location.pathname.includes("login") &&
    !location.pathname.includes("register") && (
      <MUIBreadcrumbs
        className={classes.breadcrumbs}
        separator={
          <NavigateNextIcon
            fontSize="small"
            style={{
              color: globalTheme === "high-contrast" ? "yellow" : "",
            }}
          />
        }
        aria-label="breadcrumb"
      >
        {pathsAndNames.map(({ path, name }, index) => {
          const isLast = index === links.length - 1;
          return isLast ? (
            <Typography
              key={path}
              className={`
                ${
                  !(globalTheme === "high-contrast")
                    ? classes.grey
                    : classes.yellow
                } ${classes.fontSize}`}
            >
              {name}
            </Typography>
          ) : (
            <Link
              key={path}
              className={`${classes.fontSize} ${classes.aalertHighContrast}`}
              onClick={() => history.push(path)}
              style={{
                display: "block",
                marginBlockStart: "0.5em",
                marginBlockEnd: "0.5em",
              }}
            >
              {name}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    )
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchForms: () => dispatch(fetchForms()),
  fetchAllFormsGroups: () => dispatch(fetchAllFormsGroups()),
  fetchOrgUsers: () => dispatch(fetchOrgUsers()),
});

export default connect(null, mapDispatchToProps)(Breadcrumbs);
