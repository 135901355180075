import React, { useEffect, useState } from "react";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  switchOrganizationContext,
  //   contextChange,
  //   fetchUsersByStudContext,
} from "../../redux/actions";
import { checkContext } from "../../redux/actions";
import { Box, Typography, makeStyles } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    "@media (max-width:959px)": {
      maxWidth: "220px",
      marginLeft: "100px",
    },
    "@media (max-width:800px)": {
      marginLeft: "50px",
      maxWidth: "100px",
    },
    "@media (max-width:599px)": {
      marginRight: "30px",
      maxWidth: "100%",
      marginLeft: "10px",
      marginLeft: "10px",
    },
  },
  trimTypo: {
    color: `${
      theme.palette.text.primary === "#fff200" &&
      theme.palette.background.default
    }`,
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "215px",
    },
    "@media (max-width:800px)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "175px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
    "@media (max-width:400px)": {
      width: "120px",
    },
    "@media (max-width:400px)": {
      width: "80px",
    },
    "@media (max-width:350px)": {
      width: "40px",
    },
  },
  fontSize: {
    fontSize: theme.typography.body1.fontSize,
    color: `${
      theme.palette.text.primary === "#fff200" && theme.palette.text.primary
    }`,
  },
  arrowStyle: {
    color: `${
      theme.palette.text.primary === "#fff200" &&
      theme.palette.background.default
    }`,
    fontSize: theme.typography.body1.fontSize,
  },
}));

const ContextSelect = () => {
  const allOrgsContext = useSelector((s) => s.allOrgsContext || []);
  const { orgName } = useSelector((s) => s.organization || "");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleContextChange = async function (context) {
    const result = await dispatch(switchOrganizationContext(context.id));
    setMenuAnchor(null);
    if (result) {
      dispatch(
        alertAdd({
          text: "Kontekst organizacji został pomyślnie zmieniony",
          isSuccess: true,
        })
      );
      window.location.reload();
    }
    if (!result) {
      dispatch(
        alertAdd({
          text: "Wystąpił błąd podczas zmiany kontekstu",
          isError: true,
        })
      );
    }
  };

  return (
    <Box
      className={classes.boxStyle}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{ color: "white" }}
      >
        <Typography className={classes.trimTypo}>{orgName || ""}</Typography>

        <ArrowDropDown className={classes.arrowStyle} />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListSubheader>
              <Typography className={classes.fontSize}>Kontekst</Typography>
            </ListSubheader>
            {allOrgsContext.map((context) => (
              <ListItem
                button
                key={context.id}
                onClick={() => handleContextChange(context)}
                // onClick={() => submit(context.id)}
              >
                {context.orgName}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </Box>
  );
};

export default ContextSelect;
