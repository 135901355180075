import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { alertAdd, resetGivenUserPassword } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

export const PersonForm = ({
  data,
  profileDataChangeHandler,
  addressDataChangeHandler,
  handleChecbox,
  edit,
  dataIsUsed,
  isPhoneInvalid = false,
  isMailInvalid = false,
  isHospitalIdentifierInvalid = false,
  edition,
  role,
  preventDataEdition,
}) => {
  const isOwner = edit
    ? !!data.user?.authorities.includes("ROLE_OWNER")
    : role === "ROLE_OWNER";

  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);

  const [isStreetFieldFocus, setIsStreetFieldFocus] = useState(false);
  const [dialCode, setDialCode] = useState("");

  const globalTheme = useSelector((s) => s.globalTheme);

  const dispatch = useDispatch();

  const classes = useStyles();

  const my = useSelector((s) => s.my);
  const canSeeTerms = !!my?.user.authorities.includes("ROLE_OWNER");
  const isNameAutoGenerated = () => {
    return (
      data.address.name === data.firstName ||
      data.address.name === data.surname ||
      data.address.name === "".concat(data.firstName, " ", data.surname)
    );
  };

  const handleChangeNameInAddress = (firstName, lastName) => {
    if (!data.address?.name || isNameAutoGenerated()) {
      var tmpName =
        firstName && lastName
          ? "".concat(firstName, " ", lastName)
          : firstName
          ? firstName
          : lastName;
      addressDataChangeHandler(tmpName, data, "name");
    }
  };

  return (
    <Grid>
      <Box p={1} display="flex">
        <MuiPhoneNumber
          error={isPhoneInvalid}
          name="phone"
          label="Numer telefonu"
          data-cy="user-phone"
          defaultCountry={"pl"}
          fullWidth
          size="small"
          required={
            !edit
              ? role === "ROLE_USER" && !data.mail
              : data.user?.authorities === "ROLE_USER" && !data.mail
          }
          disabled={
            (role === "ROLE_USER" && data.mail && !edit) || preventDataEdition
          }
          variant="outlined"
          value={data.phone || ""}
          onChange={(e, { dialCode }) => {
            if (e) {
              profileDataChangeHandler(e, data, "phone");
              setDialCode("+" + dialCode);
            }
            // if (e) {
            //   profileDataChangeHandler(
            //     e !== "+" + dialCode ? e : null,
            //     data,
            //     "phone"
            //   );
            // }
          }}
          onBlur={(e) => {
            data.phone === dialCode &&
              profileDataChangeHandler(null, data, "phone");

            data.phone?.substring(0, dialCode.length) !== dialCode &&
              profileDataChangeHandler(
                dialCode +
                  data.phone?.substring(dialCode.length, data.phone.length),
                data,
                "phone"
              );

            dataIsUsed &&
              dataIsUsed(e.target.value.replace(/[^+\d]+/g, ""), "phone");
          }}
          helperText={isPhoneInvalid && "Zmień wprowadzone dane"}
        />
        {/* <MuiPhoneNumber
          error={
            (data.phone?.length > 3 && data.phone?.length < 12) ||
            data.phone?.length > 12 ||
            isPhoneInvalid
          }
          name="phone"
          label="Telefon"
          variant="outlined"
          data-cy="user-phone"
          defaultCountry={"pl"}
          preferredCountries={["pl"]}
          regions={"europe"}
          autoFormat={false}
          countryCodeEditable={false}
          size="small"
          fullWidth
          required={
            !edit
              ? role === "ROLE_USER" && !data.mail
              : data.user?.authorities === "ROLE_USER" && !data.mail
          }
          disabled={role === "ROLE_USER" && data.mail && !edit}
          value={data.phone || ""}
          onChange={(e) => {
            profileDataChangeHandler(e, data, "phone");
          }}
          onBlur={(e) => dataIsUsed && dataIsUsed(e.target.value, "phone")}
          onSelect={(e) => {
            if (e.target.selectionStart < 3) {
              e.target.selectionStart = 3;
              e.target.selectionEnd = 3;
            }
          }}
          helperText={isPhoneInvalid && "Zmień wprowadzone dane"}
        /> */}
        <TextField
          error={isMailInvalid}
          label="Email"
          variant="outlined"
          type="text"
          required={
            !(!edit
              ? role === "ROLE_USER" && data.phone && data.phone.length > 4
              : data.user?.authorities === "ROLE_USER" &&
                data.phone &&
                data.phone.length > 4)
          }
          disabled={
            (role === "ROLE_USER" && data.phone && data.phone.length > 3) ||
            (edition &&
              (role === "ROLE_EDITOR" || role?.includes("ROLE_OWNER"))) ||
            preventDataEdition
          }
          value={data.mail || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) =>
            profileDataChangeHandler(value, data, "mail")
          }
          onBlur={(e) => {
            dataIsUsed && dataIsUsed(e.target.value, "mail");
          }}
          // onBlur={(e) => dataIsUsed && dataIsUsed(e.target.value, "mail")}
          helperText={isMailInvalid && "Zmień wprowadzone dane"}
        />
      </Box>
      <Box p={1} display="flex">
        <TextField
          error={isHospitalIdentifierInvalid}
          disabled={preventDataEdition}
          label="Identyfikator"
          variant="outlined"
          type="text"
          required={false}
          value={data.hospitalIdentifier || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) =>
            profileDataChangeHandler(value, data, "hospitalIdentifier")
          }
          onBlur={(e) =>
            dataIsUsed && dataIsUsed(e.target.value, "hospitalIdentifier")
          }
          helperText={isHospitalIdentifierInvalid && "Zmień wprowadzone dane"}
        />
      </Box>
      <Box m={1} display="flex">
        <TextField
          required={
            edit ? data.user?.authorities !== "ROLE_USER" : role !== "ROLE_USER"
          }
          disabled={preventDataEdition}
          label="Imię"
          variant="outlined"
          type="text"
          value={data.firstName || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) => {
            profileDataChangeHandler(value, data, "firstName");
            handleChangeNameInAddress(value, data.surname);
          }}
        />
        <TextField
          required={
            edit ? data.user?.authorities !== "ROLE_USER" : role !== "ROLE_USER"
          }
          disabled={preventDataEdition}
          label="Nazwisko"
          variant="outlined"
          type="text"
          value={data.surname || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) => {
            profileDataChangeHandler(value, data, "surname");
            handleChangeNameInAddress(data.firstName, value);
          }}
        />
      </Box>

      <Box p={1}>
        <TextField
          label="Tytuł"
          disabled={preventDataEdition}
          variant="outlined"
          type="text"
          value={data.title || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) =>
            profileDataChangeHandler(value, data, "title")
          }
        />
      </Box>
      <Box p={1}>
        <TextField
          label="Opis"
          disabled={preventDataEdition}
          variant="outlined"
          type="text"
          value={data.description || ""}
          size="small"
          fullWidth
          onChange={({ target: { value } }) =>
            profileDataChangeHandler(value, data, "description")
          }
          inputProps={{ maxLength: 100 }}
          multiline
          rows={3}
          rowsMax={3}
        />
      </Box>
      <Box p={1}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="open-gender-select-label">Płeć</InputLabel>
          <Select
            disabled={preventDataEdition}
            labelId="open-gender-select-label"
            id="open-gender-select"
            value={data.gender || ""}
            onChange={({ target: { value } }) => {
              profileDataChangeHandler(value, data, "gender");
            }}
            label="Płeć"
            autoWidth={true}
          >
            <MenuItem value="Female">Kobieta</MenuItem>
            <MenuItem value="Male">Mężczyzna</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography variant="h6">Dane adresowe:</Typography>
        <Box m={1}>
          <TextField
            label="Imię i nazwisko / nazwa"
            variant="outlined"
            type="text"
            value={data.address?.name ? data.address.name : ""}
            size="small"
            fullWidth
            onChange={({ target: { value } }) =>
              addressDataChangeHandler(value, data, "name")
            }
            disabled={preventDataEdition}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Ulica"
            variant="outlined"
            type="text"
            value={data.address ? data.address.street : ""}
            size="small"
            fullWidth
            onChange={({ target: { value } }) =>
              addressDataChangeHandler(value, data, "street")
            }
            onFocus={() => setIsStreetFieldFocus(true)}
            onBlur={() => setIsStreetFieldFocus(false)}
            helperText={isStreetFieldFocus ? "Przykład: Jasna 1A/3" : ""}
            disabled={preventDataEdition}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Miasto"
            variant="outlined"
            type="text"
            value={data.address ? data.address.city : ""}
            size="small"
            fullWidth
            onChange={({ target: { value } }) =>
              addressDataChangeHandler(value, data, "city")
            }
            disabled={preventDataEdition}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Kod pocztowy"
            variant="outlined"
            type="text"
            value={data.address ? data.address.zipCode : ""}
            size="small"
            fullWidth
            onChange={({ target: { value } }) => {
              addressDataChangeHandler(value, data, "zipCode");
              let postalCode = "";
              value.length > 6
                ? (postalCode = value.substring(0, 6))
                : (postalCode = value);
              let isOk;
              if (
                (postalCode && /^\d{2}-\d{3}$/.test(postalCode)) ||
                postalCode === "" ||
                postalCode === undefined
              ) {
                isOk = true;
              } else {
                isOk = false;
              }

              setIsPostalCodeValid(isOk);
            }}
            error={!isPostalCodeValid}
            helperText={!isPostalCodeValid && "Nieprawidłowy format."}
          />
        </Box>
        <Box m={1}>
          <TextField
            label="Poczta"
            variant="outlined"
            type="text"
            value={data.address ? data.address.post : ""}
            size="small"
            fullWidth
            onChange={({ target: { value } }) =>
              addressDataChangeHandler(value, data, "post")
            }
            disabled={preventDataEdition}
          />
        </Box>
        <Box m={1}>
          <MuiPhoneNumber
            name="phone"
            label="Numer telefonu"
            data-cy="user-phone"
            defaultCountry={"pl"}
            disabled={!!data.mail || preventDataEdition}
            size="small"
            required={
              !edit
                ? role === "ROLE_USER" && !data.mail
                : data.user?.authorities === "ROLE_USER" && !data.mail
            }
            fullWidth
            variant="outlined"
            value={data.phone || ""}
            onChange={(e, { dialCode }) => {
              if (e) {
                profileDataChangeHandler(e, data, "phone");
                setDialCode("+" + dialCode);
              }
              // if (e) {
              //   profileDataChangeHandler(
              //     e !== "+" + dialCode ? e : null,
              //     data,
              //     "phone"
              //   );
              // }
            }}
            onBlur={(e) => {
              data.phone === dialCode &&
                profileDataChangeHandler(null, data, "phone");

              dataIsUsed &&
                dataIsUsed(e.target.value.replace(/[^+\d]+/g, ""), "phone");
            }}
          />
          {/* <MuiPhoneNumber
            name="phone"
            label="Telefon"
            variant="outlined"
            data-cy="user-phone"
            defaultCountry={"pl"}
            regions={"europe"}
            autoFormat={false}
            countryCodeEditable={false}
            size="small"
            fullWidth
            required={!data.mail}
            disabled={data.mail}
            value={data.phone || ""}
            onChange={(e) => {
              profileDataChangeHandler(e, data, "phone");
            }}
            inputProps={{
              maxLength: 12,
            }}
          /> */}
        </Box>
      </Box>
      <Box>
        {/* <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.enableAllForms || false}
                name="enableAllForms"
                color="primary"
                onChange={(e) => handleChecbox(e, data)}
              />
            }
            label="Dostęp do wszystkich formularzy"
          />
        </Box> */}

        {edit && data.user?.authorities === "ROLE_USER" && (
          <Box>
            <a
              href="#"
              onClick={async () => {
                const response = await dispatch(
                  resetGivenUserPassword(data.id)
                );
                if (response.status === 204) {
                  dispatch(
                    alertAdd({
                      text: "Hasło zostało zresetowane",
                      isSuccess: true,
                    })
                  );
                } else {
                  dispatch(
                    alertAdd({
                      text: "Błąd resetu hasła",
                      isError: true,
                    })
                  );
                }
              }}
            >
              Wygeneruj nowe hasło i prześlij ponownie wiadomość powitalną do
              użytkownika
            </a>
          </Box>
        )}

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isOwner || data.enableProfileDataEdition}
                name="enableProfileDataEdition"
                color="primary"
                onChange={(e) => handleChecbox(e, data)}
                disabled={isOwner || preventDataEdition}
              />
            }
            label="Możliwość edycji danych personalnych przez użytkownika"
          />
        </Box>
        {(role === "ROLE_USER" || data.user?.authorities === "ROLE_USER") &&
          canSeeTerms &&
          edit &&
          data.acceptedTerms && (
            <Box>
              <Typography
                variant="body1"
                display="inline"
                style={{ fontWeight: "bold" }}
              >
                Zaakceptowane regulaminy:{" "}
              </Typography>
              {data.acceptedTerms
                .filter(({ revokedAt }) => revokedAt === null)
                .map((term, index) => (
                  <span key={term.terms.id}>
                    <a
                      href={`/org-media/files${term.terms.link}`}
                      target="_blank"
                      className={`${
                        globalTheme === "high-contrast" &&
                        classes.aalertHighContrast
                      } ${classes.fontSize}`}
                    >
                      {" "}
                      {term.terms.name}
                      {index !== data.acceptedTerms.length - 1 && ", "}
                    </a>{" "}
                  </span>
                ))}
            </Box>
          )}
      </Box>
    </Grid>
  );
};
