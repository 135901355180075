import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchForm,
  fetchFormData,
  fetchFormTemplate,
} from "../../redux/actions";
import FormDetails from "../../components/form-details";
import Can from "../../components/can";
import format from "date-fns/format";
import { ColorBox } from "../../components/color-box";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  alertHighContrast: {
    "& .MuiAlert-icon": {
      // fontSize: 40,
      color: theme.palette.text.primary,
    },
    backgroundColor: "#000",
    border: "1px solid #fff200",
    color: theme.palette.text.primary,
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
}));

const FilledFormDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const globalTheme = useSelector((s) => s.globalTheme);
  const [form, setForm] = useState();
  const [rows, setRows] = useState([]);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");

  const location = useLocation();

  const [completedAt, setCompletedAt] = useState("");
  const [filledBy, setFilledBy] = useState("");
  const [totalWeight, setTotalWeight] = useState("");

  const [locationValues, setLocationValues] = useState({});
  const [filledValuesDetails, setFilledValuesDetails] = useState([]);
  const formDataId = match.params.formDataId;
  const invalidateViewAt = match.params.invalidateViewAt;

  const filledFormsExtended = useSelector(
    (s) => s.filledFormsExtended[formDataId]
  );
  const forms = useSelector((s) => s.orgForms);

  const personId = useSelector((s) => s.my.id);

  const [formTemplate, setFormTemplate] = useState();

  const [textColorFilling, setTextColorFilling] = useState(true);
  const [alertDisplaying, setAlertDisplaying] = useState(true);
  const [showNotification, setShowNotification] = useState(true);

  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const isAdmin = useSelector((s) =>
    s.my.user.authorities.includes("ROLE_SUPER_OPERATOR")
  );
  const hasSingleScheduledForm = useSelector((s) => s.hasSingleScheduledForm);

  const [redirectToMain, setRedirectToMain] = useState(false);

  useEffect(() => {
    let intervalId = -1;

    if (invalidateViewAt) {
      const date = new Date(parseInt(invalidateViewAt, 10));

      intervalId = setInterval(() => {
        setRedirectToMain(date.getTime() < new Date().getTime());
      }, 10000);
    }

    return () => {
      if (intervalId !== -1) {
        clearInterval(intervalId);
      }
    };
  }, [invalidateViewAt]);

  const fetchAll = async () => {
    const data = await dispatch(fetchFormData(formDataId));

    if (!data.form && data.data.code !== "200") {
      history.push(`/forms`);
    } else {
      const form = await dispatch(fetchForm(data.form.id));
      const template = await dispatch(fetchFormTemplate(form.layoutId));

      setForm(form);
      setFormTemplate(template);

      setFilledValuesDetails(data.items);

      setTotalWeight(data.totalWeight);
      setCompletedAt(data.completedAt);
      setFilledBy(data.filledBy.name);

      setColor(data.color);
      setText(data.text);

      setLocationValues({
        ...data.items,
        ...data.arrayData,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => setShowNotification(false), 7000);
  }, []);

  useEffect(() => {
    if (formDataId) {
      fetchAll();
    }
  }, [formDataId]);

  useEffect(() => {
    const loToRows = () => {
      return formTemplate.layoutElementObject.children.map((c) => {
        return c.children.map((c) => {
          return c;
        });
      });
    };

    if (formTemplate) {
      setRows(loToRows());
    }
  }, [formTemplate]);

  const data = (filledFormsExtended || []).map(({ items, ...row }) => {
    const baseValues = {
      createdBy: {
        id: "createdBy",
        label: "createdBy",
        value: row.createdByFullName || row.createdBy,
      },
      createdAt: {
        id: "createdAt",
        label: "createdAt",
        value: format(new Date(row.createdAt), "yyyy-MM-dd HH:mm"),
      },
    };

    return {
      ...row,
      values: {
        ...baseValues,
        ...items.reduce((obj, current) => {
          return {
            ...obj,
            [current.id]: current,
          };
        }, {}),
      },
    };
  });

  const page = () => (
    <div
      className="container is-fluid"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {" "}
      <div>
        <Alert
          severity="success"
          className={
            globalTheme === "high-contrast"
              ? classes.alertHighContrast
              : undefined
          }
        >
          {location?.state?.draft
            ? "Wersja robocza została zapisana poprawnie. Twój formularz znajduje się w Zapisanych formularzach."
            : "Dane zostały wysłane, informacja zwrotna dostepna jest na liście wyników wypełnionych formularzy"}
        </Alert>
      </div>{" "}
      <br />
      <div
        className="title"
        style={{
          display: "flex",
          flexDirection: "row",
          color: globalTheme === "high-contrast" && "#fff200",
        }}
      >
        <div>Formularz "{form ? form.name : "..."}" </div>
      </div>
      <div>
        {completedAt && (
          <span>
            Data wypełnienia:
            <span>
              {" "}
              <strong
                style={{ color: globalTheme === "high-contrast" && "#fff200" }}
              >
                {format(new Date(completedAt), "yyyy-MM-dd HH:mm")}
              </strong>
            </span>
          </span>
        )}
        <br />
        <span>
          Wypełniający:
          <span>
            {" "}
            <strong
              style={{ color: globalTheme === "high-contrast" && "#fff200" }}
            >
              {filledBy}
            </strong>
          </span>
        </span>
      </div>{" "}
      {color && (
        <div>
          <br />
          <ColorBox
            color={color}
            title={text}
            maxChars={Number.MAX_SAFE_INTEGER}
          ></ColorBox>

          {totalWeight
            ? !isJustUser && (
                <span>
                  {" - "}
                  Całkowita liczba punktów:{" "}
                  <strong style={{ color: color || "" }}>
                    {totalWeight}
                  </strong>{" "}
                  pkt
                </span>
              )
            : ""}
        </div>
      )}{" "}
      {form && form.description && (
        <>
          <b>Opis</b>
          <br />
          <small>{form.description}</small>
        </>
      )}
      <hr />
      {rows.length && (
        <FormDetails
          rows={rows}
          values={locationValues}
          filledValuesDetails={filledValuesDetails}
          alertDisplaying={alertDisplaying}
          textColorFilling={textColorFilling}
          match={match}
          form={form}
        />
      )}
      <br />
      {location.state &&
        location.state.scheduleDetails &&
        location.state.scheduleDetails.filledBy.id === personId &&
        location.state.scheduleDetails.id &&
        new Date(location.state.scheduleDetails.editableUntil) >=
          new Date() && (
          <button
            style={{
              position: " absolute",
              bottom: "0px",
            }}
            className="button"
            onClick={() =>
              history.push({
                pathname: `/formdata/edit/${location.state.scheduleDetails.form.id}/${location.state.scheduleDetails.id}`,
                state: {
                  endDate: location.state.scheduleDetails.editableUntil,
                  edit: true,
                },
              })
            }
          >
            Edytuj odpowiedzi
          </button>
        )}{" "}
      <br />
      {!isAdmin && (
        <Can
          permissions={["filled-forms-page:view", "results-page:view"]}
          ok={() => (
            <div>
              <Button
                className={classes.fontSize}
                style={{
                  // position: " absolute",
                  right: "0px",
                  bottom: "0px",
                  backgroundColor:
                    globalTheme === "high-contrast" ? "yellow" : "blue",
                  color: globalTheme === "high-contrast" ? "black" : "white",
                  border: globalTheme === "high-contrast" && "1px solid yellow",
                }}
                onClick={() => {
                  hasSingleScheduledForm
                    ? history.push(`/results/${forms[0].id}`)
                    : history.push("/filled-forms");
                }}
              >
                Przejdź do listy formularzy
              </Button>
            </div>
          )}
          not={() => (
            <div>
              <Button
                variant={"contained"}
                className={classes.fontSize}
                style={{
                  // position: " absolute",
                  right: "0px",
                  bottom: "0px",
                  backgroundColor:
                    globalTheme === "high-contrast" ? "yellow" : "blue",
                  color: globalTheme === "high-contrast" ? "black" : "white",
                  border: globalTheme === "high-contrast" && "1px solid yellow",
                }}
                onClick={() => {
                  history.push("/forms/active");
                }}
              >
                Przejdź do listy formularzy
              </Button>
            </div>
          )}
        />
      )}
    </div>
  );

  const pageOrReload = redirectToMain
    ? () => <Redirect to="/forms/active" />
    : page;

  const redirect = () => <Redirect to="/login" />;

  return (
    <Can permission="form-fill-details:page" ok={pageOrReload} not={redirect} />
  );
};

export default FilledFormDetails;
