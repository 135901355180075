import React from "react";
import { NavLink } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "../loader";

export const roleToDisplay = (authorities) => {
  return authorities
    .split(",")
    .map((r) => {
      switch (r) {
        case "ROLE_OWNER":
          return "właściciel organizacji";
        case "ROLE_EDITOR":
          return process.env.REACT_APP_USE_PATIENT === "true"
            ? "lekarz"
            : "redaktor";
        case "ROLE_USER":
          return process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjent"
            : "użytkownik";
        default:
          return "";
      }
    })
    .filter((name) => !!name)
    .join(", ");
};

const useStyles = makeStyles(() => ({
  loading: {
    position: "relative",
    height: 0,
    "& > :first-child": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      border: 0,
    },
  },
  buttonLink: {
    "&.MuiButton-containedPrimary:hover": {
      color: "#ffffff",
    },
  },
}));

const acceptedTerms = (accepted) => {
  if (accepted) {
    return (
      <DoneIcon
        size="small"
        style={{
          color: "green",
        }}
      />
    );
  } else if (accepted === null) {
    return <span>Brak</span>;
  } else {
    return (
      <ClearIcon
        size="small"
        style={{
          color: "red",
        }}
      />
    );
  }
};

export const UsersDataTable = ({
  users,
  userDataColumnOptions,
  ready,
  totalCount,
  pageSize,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  forAdmin = false,
  organizationsOptions,
}) => {
  const styles = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead style={{ backgroundColor: "#dcdcdc" }}>
          <TableRow>
            {userDataColumnOptions
              .filter(({ selected }) => selected === true)
              .map((col) => {
                return (
                  <TableCell key={col.label} style={{ textAlign: "center" }}>
                    {col.text}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!ready && (
            <TableRow className={styles.loading}>
              <TableCell>
                <Loader loading={true} text="Wczytywanie danych" />
              </TableCell>
            </TableRow>
          )}
          {users?.map((person) => (
            <TableRow key={person.id}>
              {userDataColumnOptions
                .filter(({ selected }) => selected === true)
                .map(({ label }) => {
                  let dataToRender = "";
                  switch (label) {
                    case "user.authorities":
                      dataToRender = roleToDisplay(person.user.authorities);
                      break;
                    case "gender":
                      dataToRender = person.gender
                        ? person.gender === "Female"
                          ? "Kobieta"
                          : "Mężczyzna"
                        : "";
                      break;
                    case "city":
                    case "street":
                    case "zipCode":
                      if (person.address)
                        dataToRender = person.address[label] || "";
                      break;
                    case "results":
                      person.user.authorities === "ROLE_USER" &&
                        (dataToRender = (
                          <NavLink
                            to={{
                              pathname: `${
                                forAdmin ? "/admin-panel" : ""
                              }/filled-forms/all/user/${person.id}`,
                              state: { orgId: person.organizationId },
                            }}
                          >
                            <Button
                              variant={"contained"}
                              className={styles.buttonLink}
                              size="small"
                              color="primary"
                            >
                              Pokaż wyniki
                            </Button>
                          </NavLink>
                        ));
                      break;
                    case "terms":
                      dataToRender =
                        person.user.authorities === "ROLE_USER"
                          ? acceptedTerms(person.currentTermsAccepted)
                          : "";
                      break;
                    case "orgId":
                      dataToRender =
                        organizationsOptions.find(
                          ({ label }) => label === person.organizationId
                        )?.text || "";
                      break;

                    case "mail":
                      dataToRender = !!person.user.removedAt
                        ? "<usunięty>"
                        : person[label] || "";
                      break;

                    case "user.phone":
                      dataToRender = !!person.user.removedAt
                        ? "<usunięty>"
                        : person.phone || "";
                      break;

                    default:
                      dataToRender = person[label] || "";
                      break;
                  }
                  return (
                    <TableCell
                      key={`${person.id}-${label}`}
                      style={{ textAlign: "center" }}
                    >
                      {dataToRender}
                    </TableCell>
                  );
                })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={totalCount}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} z ${count}`
              }
              labelRowsPerPage="Liczba użytkowników na stronę:"
              rowsPerPage={pageSize}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
