import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

const ContrastCheckbox = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
  containedButtonContrast: {
    backgroundColor: "#000",
    color: "#fff200",
    border: "1px solid #fff200",
  },
  containedButtonPrimaryContrast: {
    backgroundColor: "yellow",
    color: "fff200",
    border: "1px solid yellow",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  label: {
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fill: "yellow",
  },
  iconroot: {
    color: "yellow",
  },
}));

const days = [
  { key: "EVERYDAY", value: "Codziennie", number: -1 },
  { key: "MONDAY", value: "Poniedziałek", number: 1 },
  { key: "TUESDAY", value: "Wtorek", number: 2 },
  { key: "WEDNESDAY", value: "Środa", number: 3 },
  { key: "THURSDAY", value: "Czwartek", number: 4 },
  { key: "FRIDAY", value: "Piątek", number: 5 },
  { key: "SATURDAY", value: "Sobota", number: 6 },
  { key: "SUNDAY", value: "Niedziela", number: 0 },
];

const sorter = {
  everyday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const calendarEventTypes = [
  {
    key: "TELECONSULTATION",
    value: "Telekonsultacja",
    isAppointment: true,
    canOverlap: false,
    color: "#00346A",
  },
  {
    key: "CONSULTATION",
    value: "Konsultacja",
    isAppointment: true,
    canOverlap: false,
    color: "#6A0100",
  },
  {
    key: "WORK",
    value: "Praca",
    isAppointment: false,
    canOverlap: true,
    color: "#506377",
  },
  {
    key: "SELF_VISIT",
    value: "Czas zarezerwowany",
    isAppointment: false,
    canOverlap: false,
    color: "#6A0100",
  },
];

const SchedulePicker = ({ id, onScheduleSave, onScheduleDelete }) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const styles = useStyles();
  const [typeSelectionOpen, setTypeSelectionOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [schedule, setSchedule] = useState({
    id,
    startDate: "",
    endDate: "",
    selectedDays: [],
    startHour: "",
    endHour: "",
    step: "",
    type: "",
    title: "",
    allDay: false,
    requiredAcceptance: false,
    zone: "Europe/Warsaw", //todo: wybieralna strefa, dla wizyt domyślna taka jak time zone organizacji, a dla wydarzen zwyklych aktualna strefa uzytkownika
    maxNumberOfParticipants: 1, //todo: wybierana maksymalna liczba uczestnikow
  });

  const isScheduleComplete = () => {
    return (
      schedule.type &&
      schedule.startDate &&
      schedule.endDate &&
      schedule.selectedDays.length > 0 &&
      schedule.startHour &&
      schedule.endHour &&
      schedule.zone &&
      schedule.maxNumberOfParticipants
    );
  };

  const handleScheduleChange = (field, value) => {
    setSchedule((prevState) => {
      let step = prevState.step;
      let startHour = prevState.startHour;
      let endHour = prevState.endHour;
      const title = prevState.title;
      let maxNumberOfParticipants = prevState.maxNumberOfParticipants;

      switch (field) {
        case "type":
          const calendarEventType = calendarEventTypes.find(
            ({ key }) => key === value
          );
          maxNumberOfParticipants = calendarEventType?.isAppointment ? 2 : 1;
          if (calendarEventType?.isAppointment === false) {
            step = "";
          }
          break;
        case "allDay":
          startHour = "00:00";
          endHour = "23:59";
          break;
        default:
          break;
      }

      return {
        ...prevState,
        step,
        startHour,
        endHour,
        title,
        maxNumberOfParticipants,
        [field]: value,
      };
    });
  };

  const handleDayChange = (event, currentDay) => {
    if (event.target.checked) {
      if (currentDay.key !== "EVERYDAY") {
        !schedule.selectedDays.some(
          (selectedDay) => selectedDay === currentDay.key
        ) &&
          handleScheduleChange(
            "selectedDays",
            [
              ...schedule.selectedDays.filter(
                (selectedDay) => selectedDay !== "EVERYDAY"
              ),
              currentDay.key,
            ].sort(function sortByDay(a, b) {
              let day1 = a.toLowerCase();
              let day2 = b.toLowerCase();
              return sorter[day1] - sorter[day2];
            })
          );
      } else {
        handleScheduleChange(
          "selectedDays",
          days.map(({ key }) => key)
        );
      }
    } else {
      handleScheduleChange(
        "selectedDays",
        schedule.selectedDays.filter(
          (selectedDay) =>
            selectedDay !== currentDay.key && selectedDay !== "EVERYDAY"
        )
      );
    }
  };

  const daysOptions = days.map((currentDay, index) => {
    return (
      <FormControlLabel
        key={index}
        control={
          <ContrastCheckbox
            checked={
              schedule.selectedDays?.find(
                (selectedDay) => selectedDay === currentDay.key
              ) !== undefined
            }
            onChange={(event) => {
              handleDayChange(event, currentDay);
            }}
            color="primary"
            name={currentDay.key}
          />
        }
        label={
          <span
            style={
              currentDay.key === "EVERYDAY"
                ? { fontWeight: "bold" }
                : { fontWeight: "normal" }
            }
          >
            {currentDay.value}
          </span>
        }
      />
    );
  });

  const handleOpenTypeSelect = () => {
    setTypeSelectionOpen(true);
  };

  const handleCloseTypeSelect = () => {
    setTypeSelectionOpen(false);
  };

  const handleChangeType = (event) => {
    handleScheduleChange("type", event.target.value);
  };

  if (open) {
    return (
      <Box>
        <Button
          variant="contained"
          className={
            globalTheme === "high-contrast"
              ? styles.containedButtonContrast
              : ""
          }
          onClick={() => setOpen(false)}
          endIcon={<ExpandLessIcon fontSize="large" />}
        >
          Zwiń harmonogram
        </Button>
        <Button
          variant="contained"
          className={
            globalTheme === "high-contrast"
              ? styles.containedButtonContrast
              : ""
          }
          onClick={() => onScheduleDelete(id)}
          style={{ float: "right" }}
        >
          Usuń harmonogram
        </Button>
        <Grid
          container
          spacing={2}
          justify={"center"}
          alignItems={"center"}
          className={styles.root}
        >
          <Grid item xs={12} md={3}>
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
            >
              <InputLabel
                id="open-event-type-label"
                label="Wybierz typ wydarzeń*"
              >
                Wybierz typ wydarzeń*
              </InputLabel>
              <Select
                labelId="open-event-type-label"
                id="open-event-type-filter"
                open={typeSelectionOpen}
                label={"Wybierz typ wydarzeń*"}
                onClose={handleCloseTypeSelect}
                onOpen={handleOpenTypeSelect}
                onChange={handleChangeType}
                value={schedule.type}
                inputProps={{
                  classes: {
                    icon: globalTheme === "high-contrast" ? styles.icon : "",
                    iconroot:
                      globalTheme === "high-contrast" ? styles.root : "",
                  },
                }}
              >
                {calendarEventTypes
                  .filter(({ key }) => key !== "SELF_VISIT")
                  .map((et) => (
                    <MenuItem key={et.key} value={et.key}>
                      {et.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Przedrostek tytułu"
              variant="outlined"
              value={
                calendarEventTypes.find(({ key }) => key === schedule.type)
                  ?.value || ""
              }
              disabled
              margin="normal"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Tytuł wydarzeń w harmonogramie"
              variant="outlined"
              value={schedule.title}
              margin="normal"
              size="small"
              fullWidth
              onChange={(e) => handleScheduleChange("title", e.target.value)}
            />
          </Grid>
        </Grid>
        <Typography>Wybierz daty obowiązywania harmonogramu</Typography>
        <TextField
          name="startDate"
          className={styles.label}
          label="Data rozpoczęcia*"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          type="date"
          value={schedule.startDate}
          variant="outlined"
          size="small"
          onChange={(e) => {
            handleScheduleChange("startDate", e.target.value);
          }}
          inputProps={{
            classes: {
              icon: globalTheme === "high-contrast" ? styles.icon : "",
              root: globalTheme === "high-contrast" ? styles.iconroot : "",
            },
          }}
        />
        <TextField
          name="endDate"
          className={styles.label}
          label="Data zakończenia*"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          type="date"
          value={schedule.endDate}
          variant="outlined"
          size="small"
          onChange={(e) => {
            handleScheduleChange("endDate", e.target.value);
          }}
        />

        <Typography>
          Wybierz dni w których ma obowiązywać ten harmonogram*
        </Typography>
        {daysOptions}
        <Typography>Wybierz czas rozpoczęcia i zakończenia</Typography>
        <Grid container alignItems="center">
          <Grid item>
            <TextField
              id="time"
              className={styles.label}
              type="time"
              label={"Od*"}
              variant={"outlined"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={schedule.startHour}
              disabled={schedule.allDay}
              onChange={(e) => {
                handleScheduleChange("startHour", e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="time"
              className={styles.label}
              type="time"
              label={"Do*"}
              InputLabelProps={{
                shrink: true,
              }}
              variant={"outlined"}
              size="small"
              value={schedule.endHour}
              disabled={schedule.allDay}
              onChange={(e) => {
                handleScheduleChange("endHour", e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <ContrastCheckbox
                  checked={schedule.allDay}
                  onChange={(e) =>
                    handleScheduleChange("allDay", e.target.checked)
                  }
                />
              }
              label="Cały dzień"
              style={{ marginTop: "8px", marginLeft: "4px" }}
            />
          </Grid>
        </Grid>
        {calendarEventTypes.find((et) => et.key === schedule.type)
          ?.isAppointment && (
          <Box>
            <Typography>Czas trwania pojedynczego wydarzenia</Typography>
            <TextField
              id="outlined-number"
              variant="outlined"
              label="Czas pojedynczego wydarzenia [min]"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              value={schedule.step}
              size="small"
              onChange={(e) => handleScheduleChange("step", e.target.value)}
              style={{
                whiteSpace: "noWrap",
              }}
            />
          </Box>
        )}
        <Button
          variant={globalTheme === "high-contrast" ? "" : "contained"}
          disabled={!isScheduleComplete()}
          className={
            globalTheme === "high-contrast"
              ? styles.containedButtonPrimaryContrast
              : ""
          }
          onClick={() => onScheduleSave(schedule)}
          style={{}}
        >
          Generuj wydarzenia
        </Button>
        <Divider style={{ marginBottom: 8, marginTop: 8 }} />
      </Box>
    );
  } else {
    return (
      <Box>
        <Button
          variant="contained"
          className={
            globalTheme === "high-contrast"
              ? styles.containedButtonContrast
              : ""
          }
          onClick={() => setOpen(true)}
          endIcon={<ExpandMoreIcon fontSize="large" />}
        >
          Rozwiń harmonogram
        </Button>
        <Divider style={{ marginBottom: 8 }} />
      </Box>
    );
  }
};

export default SchedulePicker;
