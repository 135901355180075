// noinspection ES6UnusedImports

import React, { useEffect, useState } from "react";
import Datepicker, { registerLocale } from "react-datepicker";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import s from "./index.module.css";

import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import * as classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import EventIcon from "@material-ui/icons/Event";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

registerLocale("pl", pl);
const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));
const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "solid lightgrey",
  },
}))(TextField);

const DateControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const [value, setValue] = useState(filledValue || "");
  const globalTheme = useSelector((s) => s.globalTheme);

  const classes = useStyles();

  useEffect(() => {
    setValue(filledValue);
  }, [filledValue]);

  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.DATE,
      dragMode,
      defaultLabel: "Data",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        dataType: ItemSubtypes.DATE,
        configuration,
      });
  };

  const updateValue = (value) => {
    setValue(value);

    const s = value ? moment(value).format("YYYY/MM/DD") : "";

    if (onChange) {
      onChange({
        id,
        value: s,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Data"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Data"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  if (filledFormMode) {
    return (
      <div>
        {label && (
          <label
            className={`label ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            {label}
          </label>
        )}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {disabled && !isJustUser && !filledValue ? (
          <div
            className={`${s.hintText} ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta "
              : "użytkownika "}
          </div>
        ) : (
          <>
            {/* <input
              style={{ color: disabled ? "black" : "" }}
              disabled={disabled}
              type="text"
              className={classnames("input")}
              name={name}
              id={id}
              value={value !== "Invalid date" ? value : ""}
            /> */}
            <CssTextField
              // style={{ color: disabled ? "black" : "" }}
              fullWidth
              variant={"outlined"}
              size="small"
              disabled={disabled}
              type="text"
              name={name}
              id={id}
              value={value !== "Invalid date" && value ? value : ""}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {configuration?.text && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          className={s.hintText}
        >
          {configuration.text}
        </label>
      )}
      {!label && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}
      <Grid container direction="row">
        <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={
              checkBlankedFields &&
              blankedRequiredFields.find((fieldId) => fieldId === id)
            }
            margin="normal"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            value={value ? value : null}
            inputVariant="outlined"
            onChange={(date) => {
              updateValue(date);
            }}
            keyboardIcon={
              <EventIcon
                style={{
                  color: globalTheme === "high-contrast" && "#fff200",
                }}
              />
            }
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    updateValue(null);
                  }}
                >
                  <ClearIcon
                    style={{
                      color: globalTheme === "high-contrast" && "#fff200",
                    }}
                  />
                </IconButton>
              ),
            }}
            InputAdornmentProps={{
              position: "start",
            }}
            size="small"
            inputProps={{ readOnly: true }}
            invalidDateMessage=""
            minDateMessage="Data wykracza poza zakres"
            maxDateMessage="Data wykracza poza zakres"
            cancelLabel="Anuluj"
            okLabel="Zatwierdź"
            style={{
              borderRadius: 3,
              color: disabled
                ? "black"
                : globalTheme === "high-contrast"
                ? "yellow"
                : "",
              border: globalTheme === "high-contrast" && "1px solid yellow",
              backgroundColor:
                globalTheme === "high-contrast" ? "black" : "white",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {checkBlankedFields &&
        blankedRequiredFields.find((fieldId) => fieldId === id) && (
          <p
            class={globalTheme !== "high-contrast" && "help is-danger"}
            style={{
              color: globalTheme === "high-contrast" && "#df9700",
            }}
            className={`${classes.fontSize} ${
              globalTheme !== "high-contrast" && "help is-danger"
            }`}
          >
            To pole musi zostać wypełnione
          </p>
        )}
    </div>
  );
};

export default DateControl;
