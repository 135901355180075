import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchUserAllForms } from "../../redux/actions";
import { Redirect, useLocation } from "react-router-dom";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const Page = ({ history, match, fetchUserAllForms, userAllForms }) => {
  const [groupedForms, setGroupedForms] = useState([]);
  const globalTheme = useSelector((s) => s.globalTheme);
  const location = useLocation();
  const forms = useSelector((s) => s.orgForms);
  const isAdmin = useSelector((s) =>
    s.my.user.authorities.includes("ROLE_SUPER_OPERATOR")
  );

  useEffect(() => {
    fetchUserAllForms(match.params.personId);
  }, []);

  useEffect(() => {
    if (userAllForms) {
      const userFormsFiltered = userAllForms.filter((el) => {
        return isAdmin
          ? true
          : forms.some((f) => {
              return f.id === el.id;
            });
      });

      const grouped = groupBy(userFormsFiltered, (f) => f.bag.id);

      setGroupedForms(
        Array.from(grouped).map(([key, value]) => ({
          key,
          value,
        }))
      );
    }
  }, [userAllForms, forms]);

  const personId = match.params.personId;

  // const useRowStyles = makeStyles({
  //   root: {
  //     "& > *": {
  //       borderBottom: "unset",
  //     },
  //   },
  // });

  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    fontSize: {
      "& .MuiAlert-icon": {
        fontSize: theme.typography.body1.iconSize,
      },
      fontSize: theme.typography.body1.fontSize,
    },
    button: {
      border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
      backgroundColor: theme.palette.primary.main === "#fff200" && "black",
      color: theme.palette.primary.main === "#fff200" && "#fff200",
    },
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    tableCell: {
      fontSize: theme.typography.body1.fontSize,
      paddingRight: 2,
      paddingLeft: 3,
      border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
    },
    collapseTableCell: {
      fontSize: theme.typography.body1.fontSize,
      paddingRight: 2,
      paddingLeft: 3,
      borderBottom:
        theme.palette.primary.main === "#fff200" && "1px solid #fff200",
    },
  }));
  const classes = useStyles();

  function Row(props) {
    const { forms } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
      <React.Fragment>
        <TableRow>
          {forms.length > 1 ? (
            <TableCell className={classes.tableCell}>
              <Tooltip title={"Historia wypełnień formularza"}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowUpIcon
                      style={{
                        fill: globalTheme === "high-contrast" && "#fff200",
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{
                        fill: globalTheme === "high-contrast" && "#fff200",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell className={classes.tableCell} />
          )}
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {forms[0].name}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Tooltip title="Wypełnione formularze. Kliknij by obejrzeć.">
              <Button
                variant="outlined"
                className={`${classes.button} ${classes.fontSize}`}
                onClick={() => {
                  history.push({
                    pathname: `${isAdmin ? "/admin-panel" : ""}/formdata/all/${
                      forms[0].id
                    }/${personId}`,
                    state: { orgId: location.state?.orgId },
                  });
                }}
                // className="button"
              >
                {forms[0].filledFormCount || 0}
              </Button>
            </Tooltip>
          </TableCell>
        </TableRow>

        {open &&
          forms.map(
            (form, index) =>
              index !== 0 && (
                <TableRow
                  style={{
                    background:
                      globalTheme === "high-contrast" ? "black" : "lightgrey",
                  }}
                  key={index}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.collapseTableCell}
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                  </TableCell>
                  <TableCell className={classes.collapseTableCell}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {`${form.name} (v.${form.edition})`}
                    </Collapse>
                  </TableCell>
                  <TableCell className={classes.collapseTableCell}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Tooltip title="Wypełnione formularze. Kliknij by obejrzeć.">
                        <Button
                          variant="outlined"
                          className={`${classes.button} ${classes.fontSize}`}
                          onClick={() => {
                            history.push({
                              pathname: `${
                                isAdmin ? "/admin-panel" : ""
                              }/formdata/all/${form.id}/${personId}`,
                              state: { orgId: location.state?.orgId },
                            });
                          }}
                          // className="button"
                        >
                          {form.filledFormCount || 0}
                        </Button>
                      </Tooltip>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )
          )}
      </React.Fragment>
    );
  }

  const page = () => (
    <Box>
      <Box p={1}>
        <PageTitle title="Wszystkie formularze użytkownika" />
      </Box>
      <Box p={1}>
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                Nazwa formularza
              </TableCell>
              <TableCell className={classes.tableCell}>
                Historia wypełnień
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedForms &&
              groupedForms.map((forms, index) => (
                <Row
                  key={index}
                  forms={forms.value.sort((f1, f2) =>
                    f1.edition < f2.edition ? 1 : -1
                  )}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );

  const redirect = () => <Redirect to="/login" />;

  return (
    <Can
      permission="user-filled-all-forms-page:view"
      ok={page}
      not={redirect}
    />
  );
};

const mapStateToProps = (state) => ({
  orgForms: state.orgForms,
  userAllForms: state.userAllForms,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserAllForms: (id) => dispatch(fetchUserAllForms(id)),
});

const UserAllFilledForms = connect(mapStateToProps, mapDispatchToProps)(Page);
export default UserAllFilledForms;
