import React, { useEffect, useState } from "react";
import { TextField, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react";
import luxon2Plugin from "@fullcalendar/luxon2";
import LocaleInput from "@fullcalendar/core/locales/pl";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { highContrastTheme, fontSize2Mixin, fontSize3Mixin } from "../../theme";
import "./index.css";

export function useDatesRange(startEnd) {
  const [datesRange, setDatesRange] = useState({});

  useEffect(() => {
    const handleChangeDatesRange = (startEnd) => {
      if (datesRange.start || datesRange.end) {
        if (
          JSON.stringify(datesRange.start) != JSON.stringify(startEnd.start) ||
          JSON.stringify(datesRange.end) != JSON.stringify(startEnd.end)
        ) {
          if (
            datesRange.start > startEnd.start ||
            datesRange.end < startEnd.end
          )
            setDatesRange(startEnd);
        }
      } else setDatesRange(startEnd);
    };
    startEnd && handleChangeDatesRange(startEnd);
  }, [startEnd]);
  return datesRange;
}

const hex = (h) => {
  return h
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};
export const hexToRGBAWithOpacity = (h, opacity) => {
  if (h) {
    const rgb = hex(h);
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  calendar: {
    marginTop: "8px",
    boxShadow: "2px 6px 23px -10px rgba(66, 68, 90, 1)",
    minWidth:
      fontSize3Mixin.typography.h6.fontSize === theme.typography.h6.fontSize
        ? "470px"
        : fontSize2Mixin.typography.h6.fontSize === theme.typography.h6.fontSize
        ? "400px"
        : "350px",
    "@media (max-width: 767.98px)": {
      "&.fc, .fc-toolbar.fc-header-toolbar": {
        display: "block",
        textAlign: "center",
      },
      "&.fc-header-toolbar, .fc-toolbar-chunk": {
        display: "block",
      },
    },
    "&.fc-theme-standard, .fc-scrollgrid": {
      border: `1px solid ${
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "#ddd"
      }`,
    },
    "&.fc-theme-standard, td, .fc-theme-standard th": {
      border: `1px solid ${
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "#ddd"
      }`,
    },

    "&.fc, .fc-button-primary:not(:disabled).fc-button-active": {
      backgroundColor:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "var(--fc-button-bg-color)",
      backgroundImage: "none",
      color:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "black"
          : "white",
    },
    "&.fc-button, .fc-button.fc-button-primary": {
      color:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "white",
      background:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "black"
          : theme.palette.primary.main,
      borderColor:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? "yellow"
          : "var(--fc-button-border-color)",
    },
    "&.fc-col-header-cell-cushion,  table, th": {
      color: "inherit",
      background:
        highContrastTheme.palette.primary.main === theme.palette.primary.main
          ? ""
          : hexToRGBAWithOpacity(theme.palette.primary.main, 0.1),
    },
    "&.fc, .fc-daygrid-day-top": {
      flexDirection: "row",
    },
  },
}));

const min = 1;
const max = 60;

const StyledCalendar = ({ editMode, ...props }) => {
  const { plugins, refreshEvents, ...otherProps } = props;
  const [slot, setSlot] = useState(30);
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);

  useEffect(() => {
    refreshEvents && refreshEvents(); //potrzebne aby tooltip poprawnie się wyświetlał
  }, [globalTheme]);

  const minutesToSlotDuration = (min) => {
    let hours = "00";
    let minutes = "30";
    if (!min || min <= 0) {
      minutes = 30;
    } else if (min < 10) {
      minutes = `0${min}`;
    } else if (min > 60) {
      hours = "01";
      minutes = "00";
    } else {
      minutes = min;
    }
    return `${hours}:${minutes}:00`;
  };

  const displayFullTitleOnHover = (info) => {
    const dateOptions = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    };
    const dateHourOptions = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const hourOptions = {
      hour: "numeric",
      minute: "numeric",
    };

    const datesString = info.event.allDay
      ? `${new Date(info.event.start).toLocaleDateString(
          "pl-PL",
          dateOptions
        )}, Cały dzień`
      : `${new Date(info.event.start).toLocaleString(
          "pl-PL",
          dateHourOptions
        )}-${new Date(info.event.end).toLocaleTimeString(
          "pl-PL",
          hourOptions
        )}`;
    const tooltip = tippy(info.el, {
      content: `${datesString} ${info.event.title}`,
      theme: globalTheme === "high-contrast" ? "myContrast" : "",
    });
  };

  return (
    <Box style={{ overflowX: "auto" }}>
      <Box
        className={classes.calendar}
        style={{
          padding: mediumScreen ? "8px" : "24px",
          paddingTop: "8px",
        }}
      >
        <TextField
          id="outlined-number"
          className={classes.root}
          variant="outlined"
          label="Wyświetlany interwał [min]"
          type="number"
          InputLabelProps={{
            shrink: true,
            width: 100,
          }}
          margin="normal"
          value={slot}
          style={{
            marginTop: 0,
            whiteSpace: "noWrap",
            width: 100,
          }}
          size="small"
          inputProps={{ min, max }}
          onChange={(e) => {
            let value = e.target.value;
            if (value !== "") {
              if (value > max) value = max;
              if (value < min) value = min;
            }

            setSlot(value);
          }}
        />
        <FullCalendar
          locale={LocaleInput}
          timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          plugins={[luxon2Plugin, ...plugins]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,workDaysGrid,timeGridDay",
          }}
          buttonIcons={false}
          navLinks={true}
          views={{
            workDaysGrid: {
              type: "timeGridWeek",
              weekends: false,
              buttonText: "pn-pt",
            },
          }}
          eventDidMount={displayFullTitleOnHover}
          slotDuration={minutesToSlotDuration(slot)}
          {...otherProps}
        />
      </Box>
    </Box>
  );
};

export default StyledCalendar;
