import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchForm } from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { endOfDay, startOfDay } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "black",
    minWidth: "200px",
  },
}));

const sorter = {
  everyday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const days = [
  { key: "EVERYDAY", value: "Codziennie" },
  { key: "MONDAY", value: "Poniedziałek" },
  { key: "TUESDAY", value: "Wtorek" },
  { key: "WEDNESDAY", value: "Środa" },
  { key: "THURSDAY", value: "Czwartek" },
  { key: "FRIDAY", value: "Piątek" },
  { key: "SATURDAY", value: "Sobota" },
  { key: "SUNDAY", value: "Niedziela" },
];

const getTimezoneOffset = (date) => {
  return date.getTimezoneOffset() * 60_000;
};

const ScheduleFormPage = ({
  displayMode,
  match,
  orgForms,
  fetchForm,
  addMode,
  schedule,
  setSchedule,
  setShowAdvanced,
  formName,
}) => {
  const classes = useStyles();

  const [without, setWithout] = useState(
    schedule?.multi || schedule?.one ? false : true || true
  );

  useEffect(() => {
    if (
      schedule?.timePeriods.length > 0 &&
      schedule?.timePeriods.length % 7 === 0
    ) {
      let tmpTimePeriodsArray = [];
      for (var i = 0; i < schedule?.timePeriods.length / 7; i++) {
        tmpTimePeriodsArray.push({
          dayOfWeek: "EVERYDAY",
          startTime: schedule?.timePeriods[i].startTime,
          endTime: schedule?.timePeriods[i].endTime,
          allowEditMinutesAfterSave:
            schedule?.timePeriods[i].allowEditMinutesAfterSave,
        });
      }
      setSchedule((prevState) => ({
        ...prevState,
        timePeriods: tmpTimePeriodsArray,
      }));
    } else {
      setSchedule((prevState) => ({
        ...prevState,
        timePeriods:
          prevState.timePeriods !== [] &&
          prevState.timePeriods.map((timePeriod) => {
            if (timePeriod.allowEditMinutesAfterSave !== undefined) {
              return { ...timePeriod };
            } else {
              return { ...timePeriod, allowEditMinutesAfterSave: "0" };
            }
          }),
      }));
    }
  }, []);

  useEffect(() => {
    if (
      schedule &&
      schedule?.timePeriods.length > 0 &&
      schedule?.timePeriods.length % 7 === 0
    ) {
      setSchedule((prevState) => ({
        ...prevState,
        selectedDays: [{ dayOfWeek: "EVERYDAY" }],
      }));
    } else if (schedule) {
      setSchedule((prevState) => ({
        ...prevState,
        selectedDays: schedule.timePeriods
          .map((timePeriod) => ({
            dayOfWeek: timePeriod.dayOfWeek,
          }))
          .sort(function sortByDay(a, b) {
            let day1 = a.dayOfWeek.toLowerCase();
            let day2 = b.dayOfWeek.toLowerCase();
            return sorter[day1] - sorter[day2];
          }),
      }));
    }
  }, [addMode]);

  const handleStartDateChange = (newStartDate) => {
    let localISOTime = new Date(
      startOfDay(newStartDate) - getTimezoneOffset(newStartDate)
    )
      .toISOString()
      .slice(0, -1);
    setSchedule((prevState) => ({ ...prevState, startDate: localISOTime }));

    let endDateBeforeStartDate = schedule.endDate < localISOTime;

    if (endDateBeforeStartDate) {
      localISOTime = new Date(
        endOfDay(newStartDate) - getTimezoneOffset(newStartDate)
      )
        .toISOString()
        .slice(0, -1);
      setSchedule((prevState) => ({ ...prevState, endDate: localISOTime }));
    }
  };

  const handleEndDateChange = (newEndDate) => {
    let localISOTime = new Date(
      endOfDay(newEndDate) - getTimezoneOffset(newEndDate)
    )
      .toISOString()
      .slice(0, -1);

    let endDateBeforeStartDate = localISOTime < schedule.startDate;
    const startDateDate = new Date(schedule.startDate);
    let setDate = endDateBeforeStartDate
      ? new Date(endOfDay(startDateDate)) // no offset here as it isn't accounted for in creation of the object
          .toISOString()
          .slice(0, -1)
      : localISOTime;

    setSchedule((prevState) => ({ ...prevState, endDate: setDate }));
  };

  const handleRadioButtonChange = (event) => {
    if (event.currentTarget.value === "one") {
      setSchedule((prevState) => ({ ...prevState, one: true, multi: false }));
      setWithout(false);
    } else if (event.currentTarget.value === "multi") {
      setSchedule((prevState) => ({ ...prevState, one: false, multi: true }));
      setWithout(false);
    } else {
      setSchedule((prevState) => ({ ...prevState, one: false, multi: false }));
      setWithout(true);
    }
  };

  const daysOptions = days.map((currentDay, index) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={
              schedule?.selectedDays?.find(
                (selectedDay) => selectedDay.dayOfWeek === currentDay.key
              ) !== undefined
            }
            onChange={(event) => {
              handleDayChange(event, currentDay);
            }}
            color="primary"
            name={currentDay.key}
          />
        }
        key={index}
        label={
          <span
            style={
              currentDay.key === "EVERYDAY"
                ? { fontWeight: "bold" }
                : { fontWeight: "normal" }
            }
          >
            {currentDay.value}
          </span>
        }
      />
    );
  });

  let handleDayChange = (event, currentDay) => {
    if (event.target.checked) {
      if (currentDay.key !== "EVERYDAY") {
        !schedule.selectedDays.some(
          (selectedDay) => selectedDay.dayOfWeek === currentDay.key
        ) &&
          setSchedule((prevState) => ({
            ...prevState,
            selectedDays: [
              ...schedule.selectedDays.filter(
                (selectedDay) => selectedDay.dayOfWeek !== "EVERYDAY"
              ),
              { dayOfWeek: currentDay.key },
            ].sort(function sortByDay(a, b) {
              let day1 = a.dayOfWeek.toLowerCase();
              let day2 = b.dayOfWeek.toLowerCase();
              return sorter[day1] - sorter[day2];
            }),
          }));

        !schedule.timePeriods.some(
          (timePeriod) => timePeriod.dayOfWeek === currentDay.key
        ) &&
          setSchedule((prevState) => ({
            ...prevState,
            timePeriods: [
              ...prevState.timePeriods.filter(
                (timePeriod) => timePeriod.dayOfWeek !== "EVERYDAY"
              ),
              {
                dayOfWeek: currentDay.key,
                startTime: "00:00",
                endTime: "23:59",
                allowEditMinutesAfterSave: "0",
              },
            ].sort(function sortByDay(a, b) {
              let day1 = a.dayOfWeek.toLowerCase();
              let day2 = b.dayOfWeek.toLowerCase();
              return sorter[day1] - sorter[day2];
            }),
          }));
      } else {
        setSchedule((prevState) => ({
          ...prevState,
          timePeriods: [
            {
              dayOfWeek: currentDay.key,
              startTime: "00:00",
              endTime: "23:59",
              allowEditMinutesAfterSave: "0",
            },
          ],
          selectedDays: [{ dayOfWeek: currentDay.key }],
        }));
      }
    } else {
      setSchedule((prevState) => ({
        ...prevState,
        timePeriods: prevState.timePeriods.filter(
          (timePeriod) => timePeriod.dayOfWeek !== currentDay.key
        ),
        selectedDays: schedule.selectedDays.filter(
          (selectedDay) => selectedDay.dayOfWeek !== currentDay.key
        ),
      }));
    }
  };

  const fillingInSelectedDay = (selectedDay, index) => {
    return (
      <Grid item xs={8} key={index}>
        <Paper className={classes.paper}>
          <TextField
            disabled={displayMode}
            label={
              days.find(
                (tmpSelectedDay) => tmpSelectedDay.key === selectedDay.dayOfWeek
              ).value || ""
            }
            variant="outlined"
            value={
              schedule.timePeriods.filter(
                (tmpSelectedTimePeriod) =>
                  tmpSelectedTimePeriod.dayOfWeek === selectedDay.dayOfWeek
              ).length
            }
            onFocus={(event) => event.target.select()}
            onChange={(event) => {
              if (event.target.value === "") {
                timesInADayHandler(0, selectedDay);
              } else if (event.target.value < 11) {
                timesInADayHandler(event.target.value, selectedDay);
              } else {
                timesInADayHandler(10, selectedDay);
              }
            }}
            fullWidth={true}
          />

          {schedule.timePeriods.map((timePeriod, index) => {
            if (timePeriod.dayOfWeek === selectedDay.dayOfWeek) {
              return (
                <FormGroup id={index} key={index} style={{ display: "flex" }}>
                  <Box mt={1}>
                    <TextField
                      disabled={displayMode}
                      id="time"
                      type="time"
                      label={"Od"}
                      variant={"outlined"}
                      value={timePeriod.startTime || ""}
                      style={{ width: "140px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onBlur={(e) => {
                        if (index !== 0) {
                          let tmpArrayDayOfWeek = schedule.timePeriods.filter(
                            (t) =>
                              schedule.timePeriods[index].dayOfWeek ===
                              t.dayOfWeek
                          );

                          if (tmpArrayDayOfWeek.length > 1 && index !== 0) {
                            let tmpTimePeriods = [...schedule.timePeriods];

                            if (
                              schedule.timePeriods[index - 1].dayOfWeek ===
                              timePeriod.dayOfWeek
                            ) {
                              tmpTimePeriods[index] = {
                                ...tmpTimePeriods[index],
                                startTime:
                                  schedule.timePeriods[index - 1].endTime <
                                  timePeriod.startTime
                                    ? timePeriod.startTime
                                    : schedule.timePeriods[index - 1].endTime,
                              };
                            } else {
                              tmpTimePeriods[index] = {
                                ...tmpTimePeriods[index],
                                startTime: timePeriod.startTime,
                              };
                            }
                            setSchedule((prevState) => ({
                              ...prevState,
                              timePeriods: tmpTimePeriods,
                            }));
                          }
                        }
                      }}
                      // }
                      onChange={(event) => {
                        let tmpTimePeriods = [...schedule.timePeriods];
                        tmpTimePeriods[index] = {
                          ...tmpTimePeriods[index],
                          startTime: event.target.value,
                        };
                        setSchedule((prevState) => ({
                          ...prevState,
                          timePeriods: tmpTimePeriods,
                        }));
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <TextField
                      disabled={displayMode}
                      id="time"
                      type="time"
                      label={"Do"}
                      value={timePeriod.endTime || ""}
                      style={{ width: "140px" }}
                      variant={"outlined"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onBlur={(e) => {
                        if (timePeriod.endTime < timePeriod.startTime) {
                          let tmpTimePeriods = [...schedule.timePeriods];
                          tmpTimePeriods[index] = {
                            ...tmpTimePeriods[index],
                            endTime: timePeriod.startTime,
                          };
                          setSchedule((prevState) => ({
                            ...prevState,
                            timePeriods: tmpTimePeriods,
                          }));
                        }
                      }}
                      onChange={(event) => {
                        let tmpTimePeriods = [...schedule.timePeriods];
                        tmpTimePeriods[index] = {
                          ...tmpTimePeriods[index],
                          endTime: event.target.value,
                        };
                        setSchedule((prevState) => ({
                          ...prevState,
                          timePeriods: tmpTimePeriods,
                        }));
                      }}
                    />
                  </Box>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <span>Edycja po wysłaniu formularza</span>
                    <Tooltip
                      title={`Czas w jakim ${
                        process.env.REACT_APP_USE_PATIENT === "true"
                          ? `pacjent`
                          : `użytkownik`
                      } może edytować wysłany przez siebie formularz. 0 minut - brak możliwości edycji, 30 minut - możliwość edycji przez pół godziny po wysłaniu formularza`}
                    >
                      <HelpIcon
                        style={{
                          color: "lightgrey",
                          width: 20,
                          height: 20,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <br />
                  <div>
                    <TextField
                      disabled={displayMode}
                      label={"Czas w minutach"}
                      type="number"
                      variant="outlined"
                      value={timePeriod.allowEditMinutesAfterSave}
                      onChange={(event) => {
                        let tmpTimePeriods = [...schedule.timePeriods];
                        let allowEditMinutesAfterSave = event.target.value;
                        if (allowEditMinutesAfterSave != "") {
                          const val = parseInt(allowEditMinutesAfterSave);
                          allowEditMinutesAfterSave = (val > 0
                            ? val
                            : 0
                          ).toString();
                        }
                        tmpTimePeriods[index] = {
                          ...tmpTimePeriods[index],
                          allowEditMinutesAfterSave: allowEditMinutesAfterSave,
                        };
                        setSchedule((prevState) => ({
                          ...prevState,
                          timePeriods: tmpTimePeriods,
                        }));
                      }}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          let tmpTimePeriods = [...schedule.timePeriods];
                          tmpTimePeriods[index] = {
                            ...tmpTimePeriods[index],
                            allowEditMinutesAfterSave: "0",
                          };
                          setSchedule((prevState) => ({
                            ...prevState,
                            timePeriods: tmpTimePeriods,
                          }));
                        }
                      }}
                    />
                  </div>
                  <hr />
                </FormGroup>
              );
            }
          })}
        </Paper>
      </Grid>
    );
  };

  let timesInADayHandler = (value, selectedDay) => {
    let difference =
      schedule.timePeriods.filter(
        (timePeriod) => timePeriod.dayOfWeek === selectedDay.dayOfWeek
      ).length - value;

    if (difference < 0) {
      let newTimePeriods = [];
      for (let i = 0; i < Math.abs(difference); i++) {
        newTimePeriods.push({
          dayOfWeek: selectedDay.dayOfWeek,
          startTime: schedule.timePeriods[schedule.timePeriods.length - 1]
            ?.endTime
            ? schedule.timePeriods[schedule.timePeriods.length - 1].endTime
            : i === 0
            ? "00:00"
            : "23:59",
          endTime: schedule.timePeriods[schedule.timePeriods.length - 1]
            ?.endTime
            ? schedule.timePeriods[schedule.timePeriods.length - 1]?.endTime
            : "23:59",
        });
      }
      setSchedule((prevState) => ({
        ...prevState,
        timePeriods: prevState.timePeriods
          .concat(newTimePeriods)
          .sort(function sortByDay(a, b) {
            let day1 = a.dayOfWeek.toLowerCase();
            let day2 = b.dayOfWeek.toLowerCase();
            return sorter[day1] - sorter[day2];
          })
          .map((timePeriod, index) => {
            if (timePeriod.allowEditMinutesAfterSave !== undefined) {
              return {
                ...timePeriod,
              };
            } else {
              return {
                ...timePeriod,
                allowEditMinutesAfterSave: "0",
              };
            }
          }),
      }));
    }
    if (difference > 0) {
      let tmpArrayWithSelectedDay = schedule.timePeriods.filter(
        (timePeriod) => timePeriod.dayOfWeek === selectedDay.dayOfWeek
      );
      tmpArrayWithSelectedDay = tmpArrayWithSelectedDay.slice(0, value);
      setSchedule((prevState) => ({
        ...prevState,
        timePeriods: tmpArrayWithSelectedDay.concat(
          prevState.timePeriods.filter(
            (timePeriod) => timePeriod.dayOfWeek !== selectedDay.dayOfWeek
          )
        ),
      }));
    }
  };

  return (
    <Grid>
      <Typography variant="h6" gutterBottom>
        {addMode
          ? `Harmonogram wypełnienia formularza`
          : `Harmonogram wypełnienia formularza: ${formName || ""}`}
      </Typography>
      <Grid container justify="center">
        <Grid item xs={12}>
          <FormControl style={{ marginTop: 15 }} disabled={displayMode}>
            <Box m={1}>
              <FormLabel style={{ color: "black" }}>
                Wypełnienie formularza:
              </FormLabel>
              <RadioGroup
                row
                aria-label="multi"
                name="fill_form"
                value={
                  schedule?.one || schedule?.multi || without
                    ? schedule?.one
                      ? "one"
                      : schedule?.multi
                      ? "multi"
                      : "without"
                    : null
                }
                onChange={handleRadioButtonChange}
              >
                <FormControlLabel
                  value="without"
                  control={<Radio color="primary" />}
                  label="Dowolnie"
                />
                <FormControlLabel
                  value="one"
                  control={<Radio color="primary" />}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <span>Jednokrotnie</span>
                      <Tooltip
                        title={`Możliwość jednokrotnego wypełnienia formularza w zdefiniowanym oknie czasowym`}
                      >
                        <HelpIcon
                          style={{
                            color: "lightgrey",
                            height: 15,
                          }}
                        ></HelpIcon>
                      </Tooltip>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="multi"
                  control={<Radio color="primary" />}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <span>Wielokrotnie</span>
                      <Tooltip
                        title={`Możliwość wielokrotnego wypełnienia formularza w zdefiniowanym oknie czasowym`}
                      >
                        <HelpIcon
                          style={{
                            color: "lightgrey",
                            height: 15,
                          }}
                        ></HelpIcon>
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
            {!without && (
              <>
                <Box m={1}>
                  <MuiPickersUtilsProvider
                    locale={plLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      disabled={displayMode}
                      margin="normal"
                      id="start-date-picker-dialog"
                      label="Początek dostępności formularza"
                      format="dd/MM/yyyy"
                      value={schedule?.startDate}
                      inputVariant="outlined"
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      cancelLabel={"Anuluj"}
                      okLabel={"Zatwierdź"}
                      fullWidth
                      inputProps={{ readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>{" "}
                <Box m={1}>
                  <MuiPickersUtilsProvider
                    locale={plLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      disabled={displayMode}
                      margin="normal"
                      id="end-date-picker-dialog"
                      label="Koniec dostępności formularza"
                      format="dd/MM/yyyy"
                      value={schedule?.endDate}
                      inputVariant="outlined"
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      cancelLabel={"Anuluj"}
                      okLabel={"Zatwierdź"}
                      fullWidth
                      inputProps={{ readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <Box m={1}>
                  <FormLabel style={{ color: "black" }}>
                    Dni wypełnienia:
                  </FormLabel>
                  <FormGroup row>{daysOptions}</FormGroup>
                </Box>
                <Box m={1}>
                  {schedule?.selectedDays?.length > 0 && (
                    <FormLabel style={{ color: "black" }}>
                      Ilość okien czasowych wypełnień w ciągu dnia:
                    </FormLabel>
                  )}
                  <Grid container spacing={3} justify="center">
                    {[
                      ...new Map(
                        schedule?.selectedDays?.map((item) => [
                          item["dayOfWeek"],
                          item,
                        ])
                      ).values(),
                    ].map((selectedDay, index) =>
                      fillingInSelectedDay(selectedDay, index)
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  orgForms: state.orgForms,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForm: (id) => dispatch(fetchForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleFormPage);
