import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import "rc-time-picker/assets/index.css";
import TimeField from "react-simple-timefield";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, TextField } from "@material-ui/core";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "solid lightgrey",
  },
}))(TextField);
const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
  contrastBorder: {
    "& .MuiOutlinedInput-notchedOutline .Mui-error": {
      borderColor: "#fff200",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#fff200",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff200",
      },
      "&:hover fieldset": {
        borderColor: "#fff200",
      },
    },
  },
}));

// dragaMode = either 'clone' or 'move'

const TimeControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.TIME,
      dragMode,
      defaultLabel: "Czas",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (filledValue && !filledFormMode) {
      if (filledValue?.length > 1) {
        const date = new Date();
        date.setHours(filledValue.slice(0, 2));
        date.setMinutes(filledValue.slice(-2));

        setValue(date);
      }
    } else if (filledValue?.length > 1) {
      setValue(filledValue.includes("NaN") ? null : filledValue);
    }
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.TIME,
      configuration,
    });

  const onTimeChange = (date) => {
    if (!date || isNaN(date.getTime())) {
      setValue(date);
      if (onChange) {
        onChange({
          id,
          value: null,
        });
      }
    } else {
      const h = `${date.getHours()}`;
      const m = `${date.getMinutes()}`;
      const time = `${h.padStart(2, "0")}:${m.padStart(2, "0")}`;
      setValue(date);
      if (onChange) {
        onChange({
          id,
          value: time,
        });
      }
    }
  };

  const updateValue = (value) => {
    setValue(value);

    if (onChange) {
      onChange({
        id,
        value: value,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Czas"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Czas"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }
  if (filledFormMode) {
    return (
      <div>
        {label && (
          <label
            className={`label ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            {label}
          </label>
        )}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {!isJustUser && !filledValue ? (
          <div
            className={`${s.hintText} ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta"
              : "użytkownika"}
          </div>
        ) : (
          // <input
          //   style={{ color: disabled ? "black" : "" }}
          //   disabled={disabled}
          //   type="text"
          //   className={classnames("input")}
          //   name={name}
          //   id={id}
          //   value={value}
          // />
          <CssTextField
            // style={{ color: disabled ? "black" : "" }}
            fullWidth
            variant={"outlined"}
            size="small"
            disabled={disabled}
            type="text"
            name={name}
            id={id}
            value={value}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}
      {configuration?.text && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          className={s.hintText}
        >
          {configuration.text}
        </label>
      )}

      <>
        <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
          <KeyboardTimePicker
            className={
              globalTheme === "high-contrast" && classes.contrastBorder
            }
            size="small"
            id="time-picker"
            value={value ? value : null}
            onChange={onTimeChange}
            ampm={false}
            variant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            cancelLabel="Anuluj"
            okLabel="Zatwierdź"
            invalidDateMessage="Nieprawidłowy format"
            inputVariant="outlined"
            keyboardIcon={
              <QueryBuilderIcon
                style={{ color: globalTheme === "high-contrast" && "yellow" }}
              />
            }
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    updateValue(null);
                  }}
                >
                  <ClearIcon
                    style={{
                      color: globalTheme === "high-contrast" && "#fff200",
                    }}
                  />
                </IconButton>
              ),
            }}
            InputAdornmentProps={{
              position: "start",
            }}
            inputProps={{
              readOnly: true,
            }}
          />
        </MuiPickersUtilsProvider>

        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color: globalTheme === "high-contrast" && "#df9700",
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              To pole musi zostać wypełnione
            </p>
          )}
      </>
    </div>
  );
};

export default TimeControl;
