import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { alertAdd, editCalendarEvent } from "../../redux/actions";
import { calendarEventTypes } from "../schedule-picker";
import {
  mapFEEventToBEEvent,
  mapBEEventToFEEvent,
} from "../../pages/calendar-page/events-functions";
import { DateTime } from "luxon";
import LoaderButton from "../loader-button";

const ContrastCheckbox = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
  containedButtonContrast: {
    backgroundColor: "#000",
    color: "#fff200",
    border: "1px solid #fff200",
  },
  containedButtonPrimaryContrast: {
    backgroundColor: "yellow",
    color: "fff200",
    border: "1px solid yellow",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  label: {
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fill: "yellow",
  },
  iconroot: {
    color: "yellow",
  },
}));

const convertEventToEdit = (eventToEdit) => {
  const convertedToBEEvent = mapFEEventToBEEvent(eventToEdit);
  let { startDateTime, endDateTime, ...rest } = {
    ...convertedToBEEvent,
    startDate: convertedToBEEvent.startDateTime.slice(0, 10),
    endDate: convertedToBEEvent.endDateTime.slice(0, 10),
    startHour: convertedToBEEvent.startDateTime.slice(11, 16),
    endHour: convertedToBEEvent.endDateTime.slice(11, 16),
  };
  return rest;
};

const convertEventToSave = (eventToSave) => {
  let { startDate, endDate, startHour, endHour, ...rest } = {
    ...eventToSave,
    startDateTime: eventToSave.startDate + "T" + eventToSave.startHour,
    endDateTime: eventToSave.endDate + "T" + eventToSave.endHour,
  };

  return rest;
};

const EventEditDialog = ({
  open,
  editedEvent,
  handleClosePanel,
  passSavedEvent,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const globalTheme = useSelector((s) => s.globalTheme);
  const [typeSelectionOpen, setTypeSelectionOpen] = useState(false);
  const [newEvent, setNewEvent] = useState(convertEventToEdit(editedEvent));
  const editedEventType = editedEvent.type || editedEvent.possibleTypes[0];
  const handleOpenTypeSelect = () => {
    setTypeSelectionOpen(true);
  };

  const handleCloseTypeSelect = () => {
    setTypeSelectionOpen(false);
  };

  const handleEventChange = (field, value) => {
    setNewEvent((prevState) => {
      const startDate = prevState.startDate;
      let endDate = prevState.endDate;
      let startHour = prevState.startHour;
      let endHour = prevState.endHour;
      if (field === "allDay") {
        if (value === true) {
          endDate = DateTime.fromISO(startDate).plus({ days: 1 }).toISODate();
          startHour = "00:00";
          endHour = "00:00";
        }
      } else if (field === "startDate" && prevState.allDay === true) {
        endDate = DateTime.fromISO(value).plus({ days: 1 }).toISODate();
      }
      return {
        ...prevState,
        startDate,
        endDate,
        startHour,
        endHour,
        [field]: value,
      };
    });
  };

  const handleChangeType = (event) => {
    const type = event.target.value;
    const typeToSave = editedEvent.isAvailabilityEvent ? [type] : type;
    handleEventChange(
      editedEvent.isAvailabilityEvent ? "possibleTypes" : "type",
      typeToSave
    );
  };

  const save = async () => {
    await editCalendarEvent(
      editedEvent.idBE,
      convertEventToSave(newEvent),
      editedEvent.isAvailabilityEvent
    )
      .then((resp) => {
        dispatch(
          alertAdd({
            text: "Edytowano wydarzenie.",
            isSuccess: true,
          })
        );
        passSavedEvent &&
          passSavedEvent(
            mapBEEventToFEEvent(
              resp.data,
              editedEvent.isAvailabilityEvent,
              true
            )
          );
      })
      .catch((error) => {
        let errTxt = "Nie udało się zapisać wydarzenia.";
        const msg = error.response.data.message;
        if (msg === "end-date-cannot-be-before-start-date") {
          errTxt =
            "Nie można zapisać wydarzenia z datą zakończenia wcześniejszą niż data rozpoczęcia.";
        } else if (msg === "cannot-edit-past-event") {
          errTxt = "Nie można edytować przeszłej wizyty.";
        } else if (msg === "event-overlaps-existing-event") {
          errTxt = errTxt + " Wydarzenie nachodzi na inne wydarzenie.";
        }
        dispatch(
          alertAdd({
            text: errTxt,
            isError: true,
          })
        );
      });
  };

  return (
    <Dialog
      open={open}
      hideBackdrop={true}
      onClose={(reason) => {
        if (!reason === "backdropClick") {
          handleClosePanel();
        }
      }}
    >
      <DialogTitle>Edycja wydarzenia</DialogTitle>
      <Box style={{ marginBottom: 16, marginRight: 16, marginLeft: 16 }}>
        <Grid container direction="column" spacing={2} className={styles.root}>
          {editedEvent.type !== "SELF_VISIT" && (
            <Grid item>
              <FormControl
                variant="outlined"
                margin="normal"
                size="small"
                style={{ minWidth: "150px" }}
              >
                <InputLabel id="open-event-type-label" label="Typ wydarzenia *">
                  Typ wydarzenia
                </InputLabel>
                <Select
                  labelId="open-event-type-label"
                  id="open-event-type-filter"
                  open={typeSelectionOpen}
                  label={"Typ wydarzenia *"}
                  onClose={handleCloseTypeSelect}
                  onOpen={handleOpenTypeSelect}
                  onChange={handleChangeType}
                  value={
                    editedEvent.isAvailabilityEvent
                      ? newEvent.possibleTypes[0]
                      : newEvent.type
                  }
                  inputProps={{
                    classes: {
                      icon: globalTheme === "high-contrast" ? styles.icon : "",
                      iconroot:
                        globalTheme === "high-contrast" ? styles.root : "",
                    },
                  }}
                >
                  {calendarEventTypes
                    .filter(
                      ({ key, isAppointment }) =>
                        key !== "SELF_VISIT" &&
                        isAppointment ===
                          calendarEventTypes.find(
                            ({ key }) => key === editedEventType
                          )?.isAppointment
                    )
                    .map((et) => (
                      <MenuItem key={et.key} value={et.key}>
                        {et.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <TextField
              label="Tytuł wydarzenia"
              variant="outlined"
              value={newEvent.title}
              margin="normal"
              size="small"
              onChange={(e) => handleEventChange("title", e.target.value)}
            />
          </Grid>
          <Grid item>
            <Typography>Data wydarzenia</Typography>
            <TextField
              name="startDate"
              className={styles.label}
              label="Data rozpoczęcia"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={newEvent.startDate}
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleEventChange("startDate", e.target.value);
              }}
              inputProps={{
                classes: {
                  icon: globalTheme === "high-contrast" ? styles.icon : "",
                  root: globalTheme === "high-contrast" ? styles.iconroot : "",
                },
              }}
            />
            <TextField
              name="endDate"
              className={styles.label}
              label="Data zakończenia"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={newEvent.endDate}
              disabled={newEvent.allDay}
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleEventChange("endDate", e.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <Typography>Czas rozpoczęcia i zakończenia</Typography>
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  id="time"
                  className={styles.label}
                  type="time"
                  label={"Od"}
                  variant={"outlined"}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newEvent.startHour}
                  disabled={newEvent.allDay}
                  onChange={(e) => {
                    handleEventChange("startHour", e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="time"
                  className={styles.label}
                  type="time"
                  label={"Do"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant={"outlined"}
                  size="small"
                  value={newEvent.endHour}
                  disabled={newEvent.allDay}
                  onChange={(e) => {
                    handleEventChange("endHour", e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <ContrastCheckbox
                      checked={newEvent.allDay}
                      onChange={(e) =>
                        handleEventChange("allDay", e.target.checked)
                      }
                    />
                  }
                  label="Cały dzień"
                  style={{ marginTop: "8px", marginLeft: "4px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <Button
          type="cancel"
          variant="contained"
          onClick={() => {
            handleClosePanel();
          }}
        >
          Anuluj
        </Button>
        <LoaderButton
          onClick={save}
          text={"Zapisz"}
          loadingText="Zapisuję..."
        />
      </DialogActions>
    </Dialog>
  );
};

export default EventEditDialog;
