import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";

const LoaderButton = ({ onClick, text = "", endIcon, loadingText = "" }) => {
  const isContrastTheme = useSelector((s) => s.globalTheme === "high-contrast");
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!isLoading) {
      setLoading(true);
      await onClick();
      setLoading(false);
    }
  };

  return (
    <Button
      variant={"contained"}
      color="primary"
      onClick={handleClick}
      endIcon={endIcon}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          style={{ color: isContrastTheme ? "black" : "white" }}
        />
      )}
      {isLoading ? loadingText : text}
    </Button>
  );
};

export default LoaderButton;
