import { DateTime } from "luxon";
import { addHours, addDays } from "date-fns";
import { hexToRGBAWithOpacity } from "../../components/calendar";
import { calendarEventTypes } from "../../components/schedule-picker";

export const mapBEEventToFEEvent = (
  beEvent,
  isAvailabilityEvent,
  withUnavailableColor = false
) => {
  const type = beEvent.possibleTypes ? beEvent.possibleTypes[0] : beEvent.type;
  let { id, ...rest } = {
    ...beEvent,
    idBE: beEvent.id,
    start: DateTime.fromISO(beEvent.startDateTime, {
      zone: beEvent.zone,
    }).toISO(),
    end: DateTime.fromISO(beEvent.endDateTime, { zone: beEvent.zone }).toISO(),
    color: hexToRGBAWithOpacity(
      calendarEventTypes.find(({ key }) => key === type)?.color,
      isAvailabilityEvent && withUnavailableColor ? 0.2 : 1
    ),
    textColor: isAvailabilityEvent && withUnavailableColor ? "black" : "white",
    title: `${calendarEventTypes.find(({ key }) => key === type)?.value}${
      beEvent.title ? `: ${beEvent.title}` : ""
    }`,
    allDay:
      beEvent.allDay &&
      beEvent.zone === Intl.DateTimeFormat().resolvedOptions().timeZone,
    isAvailabilityEvent,
  };
  return { ...rest };
};
export const mapBEEventsToFEEvents = (
  beEvents,
  isAvailabilityEvent,
  withUnavailableColor = false
) => {
  return beEvents.map((ev) =>
    mapBEEventToFEEvent(ev, isAvailabilityEvent, withUnavailableColor)
  );
};

export const mapFEEventToBEEvent = (feEvent) => {
  const ind = feEvent.title.indexOf(":");
  let title;
  if (ind === -1) {
    title = "";
  } else {
    title = feEvent.title.slice(ind + 2);
  }
  let { idBE, start, end, scheduleId, color, isAvailabilityEvent, ...rest } = {
    ...feEvent,
    id: feEvent.idBE,
    startDateTime: DateTime.fromISO(feEvent.start, {
      zone: feEvent.zone,
    })
      .toISO()
      .slice(0, -6),
    endDateTime: DateTime.fromISO(feEvent.end, { zone: feEvent.zone })
      .toISO()
      .slice(0, -6),
    allDay:
      feEvent.allDay &&
      feEvent.zone === Intl.DateTimeFormat().resolvedOptions().timeZone,
    title: title,
  };
  return { ...rest };
};

export const mapFEEventsToBEEvents = (feEvents) => {
  return feEvents.map((ev) => mapFEEventToBEEvent(ev));
};

export const mapBECalendarEventsDTOsToFEEvents = (
  data,
  withUnavailableColor = false
) => {
  return [
    ...mapBEEventsToFEEvents(
      data.availabilityEvents,
      true,
      withUnavailableColor
    ),
    ...mapBEEventsToFEEvents(data.calendarEvents, false, withUnavailableColor),
  ];
};

export const fromFCDateToZoneISODateTime = (date, zone) =>
  DateTime.fromISO(date.toISOString(), {
    zone,
  }).toISO();

export const fromOldEventToNewEvent = (oldEvent, newEventInfo) => {
  return {
    ...oldEvent,
    start: fromFCDateToZoneISODateTime(newEventInfo.event.start, oldEvent.zone),
    end: newEventInfo.event.allDay
      ? fromFCDateToZoneISODateTime(
          addDays(newEventInfo.event.start, 1),
          oldEvent.zone
        )
      : newEventInfo.oldEvent.allDay
      ? fromFCDateToZoneISODateTime(
          addHours(newEventInfo.event.start, 1),
          oldEvent.zone
        )
      : newEventInfo.event.end
      ? fromFCDateToZoneISODateTime(newEventInfo.event.end, oldEvent.zone)
      : oldEvent.end,
    allDay: newEventInfo.event.allDay,
  };
};
